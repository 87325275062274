/** @jsx jsx */
import { Button, Flex, jsx } from 'theme-ui';
import React from 'react';

import { useFinnoscoreBannerContent, useTranslate } from '@modules';
import { Paragraph, Link, SubTitle, SvgIcon } from '@core';
import { FinnoscoreBannerProps } from './FinnoscoreBannerProps';
import { styles } from './MobileFinnoscoreBanner.styles';

export const MobileFinnoscoreBanner = ({ onClick, ...props }: FinnoscoreBannerProps) => {
  /**
   * The button text is sourced from CMS useFinnoscoreBannerContent,
   * but it cannot be used as it is too lengthy for mobile displays.
   */
  const { title, paragraph, link, buttonText } = useFinnoscoreBannerContent('finnoscore-homepage-banner');
  const [isOpen, setIsOpen] = React.useState(false);
  const __ = useTranslate();

  if (!title || !paragraph || !link) {
    return null;
  }

  return (
    <Flex variant='flex.col' sx={styles.box} {...props}>
      <Button variant='buttons.pure' sx={styles.titleButton} onClick={() => setIsOpen(!isOpen)}>
        <Flex variant='flex.rowCenter' sx={styles.titleBox}>
          <SubTitle sx={styles.title}>
            {title} {paragraph}
          </SubTitle>
          {isOpen ? (
            <Button variant='buttons.pure' onClick={onClick}>
              <SvgIcon sx={styles.closeIcon} name='close' />
            </Button>
          ) : (
            <SvgIcon sx={styles.openArrow} name='tailedArrow' />
          )}
        </Flex>
      </Button>
      <Flex sx={styles.content(isOpen)}>
        <Paragraph sx={styles.paragraph}>{buttonText}</Paragraph>
        <Link sx={styles.link} to={link}>
          <Button variant='buttons.cta' onClick={onClick}>
            {__('finnoscore.banner.mobile.button')}
          </Button>
        </Link>
      </Flex>
    </Flex>
  );
};
