import { getBreakpoint, scrub, withAnimation } from '@core';
import { __el, __rect, __select, __selectAll, __tr } from '@modules';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/all';

const maxWidthBreakpoint = 2; // mobile & tablet & normal desktop

export interface AnimateFoundersProps {
  founderBox: () => React.RefObject<HTMLDivElement>;
  philosophyBox: () => React.RefObject<HTMLDivElement>;
}

type ScrollerArray = gsap.plugins.ScrollTriggerInstance[];

const getFounderProps = (founderBox: () => React.RefObject<HTMLDivElement>, current: Element) => {
  const founderBoxRect = __rect(__el(founderBox));

  const currentFounderX = __rect(current)?.x;
  const currentFounderY = current?.getBoundingClientRect()?.y;
  const currentFounderWidth = current?.getBoundingClientRect()?.width;

  const founderTargetX = window.innerWidth / 2 - currentFounderWidth / 2;
  const founderDistanceToTargetX = founderTargetX - currentFounderX;

  const founderTargetY = founderBoxRect ? founderBoxRect?.y + founderBoxRect?.height : 0;
  const founderDistanceToTargetY = founderTargetY - currentFounderY;

  return {
    toX: founderDistanceToTargetX,
    toY: founderDistanceToTargetY,
  };
};

interface PhilosophyProps {
  selector: Element;
  fromX: number;
  fromY: number;
  toX: number;
  toY: number;
}

const getPhilosophyProps = (philosophyBox: () => React.RefObject<HTMLDivElement>, philosophyELements: Element[]) => {
  const philRect = __el(philosophyBox)?.getBoundingClientRect();
  const firstContainerBoxRect = __select('.listItem', philosophyBox)?.getBoundingClientRect();
  const firstPhilIconRect = __select('.philosophyItem', philosophyBox)?.getBoundingClientRect();

  const philosophyStartY = philRect?.y || 0;
  const philosophyContainerY = firstContainerBoxRect?.y || 0;
  const philosophyDistanceFromStartY = philosophyStartY - philosophyContainerY;

  const philosophyStartX = window?.innerWidth / 2 - (firstPhilIconRect?.width || 0) / 2;

  const result = philosophyELements.reduce((accum, curr, index) => {
    const containerBoxRect = __selectAll('.listItem', philosophyBox)[index]?.getBoundingClientRect();

    return [
      ...accum,
      {
        selector: curr,
        fromX: philosophyStartX - containerBoxRect.x,
        fromY: philosophyDistanceFromStartY,
        toX: 0,
        toY: 0,
      },
    ];
  }, [] as PhilosophyProps[]);

  return result;
};

const createFounderScrollers = (founderBox: () => React.RefObject<HTMLDivElement>) => {
  const founderItems = Array.from(__selectAll('.listItem', founderBox));

  const scrollers = [] as ScrollerArray;
  if (!founderBox) return scrollers;

  founderItems.forEach((currentElement) => {
    const initScrollers = () => {
      const trigger = __tr(founderBox);
      if (!trigger) return;

      const currentFounder = __select('.listItemImage', currentElement);
      if (currentFounder) {
        const founderProps = getFounderProps(founderBox, currentFounder);
        const itemsToHide = __selectAll('.listItemText, .listItemButtonList', currentElement);

        const animation = gsap
          .timeline()
          .to(itemsToHide, {
            opacity: 0,
            duration: 0.1,
          })
          .to(
            currentFounder,
            {
              x: founderProps.toX,
              y: founderProps.toY,
            },
            '<'
          )
          .fromTo(
            __selectAll('.listItemImageOverlay', currentFounder),
            {
              opacity: 0,
            },
            {
              opacity: 1,
            },
            '<'
          )
          .fromTo(
            currentFounder,
            {
              opacity: 1,
            },
            {
              opacity: 0,
              duration: 0.1,
            },
            '>'
          )
          .fromTo(
            __selectAll('.listItemImageOverlay', currentFounder),
            {
              opacity: 1,
            },
            {
              opacity: 0,
              duration: 0.1,
            },
            '>'
          );

        scrollers.push(
          ScrollTrigger.create({
            trigger,
            // markers: process.env.NODE_ENV === 'development',
            ...scrub(),
            start: 'bottom 90%',
            invalidateOnRefresh: true,
            end: 'bottom 40%',
            animation,
          })
        );
      }
    };
    ScrollTrigger.matchMedia({
      [`(min-width: ${getBreakpoint(maxWidthBreakpoint)})`]: initScrollers,
    });
  });

  return scrollers;
};

const createPhilosphyScroller = (philosophyBox: () => React.RefObject<HTMLDivElement>) => {
  const philosophyELements = Array.from(__selectAll('.philosophyItem', philosophyBox));
  const philosophyProps = getPhilosophyProps(philosophyBox, philosophyELements);
  const itemsToShow = __selectAll('.philosophiesTitle, .philosophiesSubTitle', philosophyBox);

  const scrollers = [] as ScrollerArray;

  const initScrollersDesktop = () => {
    const animation = gsap.timeline();
    const trigger = __tr(philosophyBox);
    if (!trigger) return;

    philosophyProps.forEach((prop) => {
      animation.fromTo(
        prop.selector,
        {
          opacity: 0,
        },
        {
          opacity: 1,
          duration: 0.1,
        },
        '<'
      );
    });

    philosophyProps.forEach((prop, index) => {
      animation.fromTo(
        prop.selector,
        {
          x: prop.fromX,
          y: prop.fromY,
        },
        {
          x: prop.toX,
          y: prop.toY,
        },
        index === 0 ? '>' : '<'
      );
    });

    philosophyProps.forEach((prop) => {
      animation.fromTo(
        __selectAll('.philosophySubTitle', prop.selector),
        {
          opacity: 0,
        },
        {
          opacity: 1,
        },
        '<'
      );
    });

    animation.fromTo(
      itemsToShow,
      {
        opacity: 0,
      },
      {
        opacity: 1,
        duration: 0.5,
      },
      '>'
    );

    scrollers.push(
      ScrollTrigger.create({
        trigger,
        // markers: process.env.NODE_ENV === 'development',
        ...scrub(),
        invalidateOnRefresh: true,
        start: 'top 55%',
        end: 'bottom 100%',
        animation,
      })
    );
  };

  const initScrollersMobile = () => {
    const containerBox = __select('.listSection', philosophyBox);
    const trigger = __tr(philosophyBox);

    if (containerBox && trigger) {
      const maxWidth = Array.from(containerBox?.children)?.reduce((sum, item) => sum + item?.getBoundingClientRect()?.width, 0);
      const animation = gsap.timeline().fromTo(
        containerBox,
        {
          x: () => window?.innerWidth * 0.8,
        },
        {
          x: () => window?.innerWidth * 0.8 - maxWidth,
        }
      );

      scrollers.push(
        ScrollTrigger.create({
          trigger: __tr(philosophyBox),
          toggleActions: 'play complete reverse reset',
          // markers: process.env.NODE_ENV === 'development',
          // NOTE: pin destroys contact for animation on mobile. => needed to add spacing to the mobile CSS to have it working, which is done inside this function.
          // ...pin(),
          refreshPriority: 5,
          invalidateOnRefresh: true,
          ...scrub(),
          start: 'top-=150vh top',
          end: 'top+=50px top-=150vh', // give enough place for scrub scrolling so that the movement doesn't get too quick for the users
          animation,
        })
      );
    }
  };

  // TODO: saveStyles is good, however this looks ugly. We should resolve much cleaner than this save, but for this entire animation has to be reworked -> Later
  ScrollTrigger.saveStyles(
    [__el(philosophyBox), __select('.listSection', philosophyBox)]
      .concat(Array.from(__selectAll('.philosophyItem', philosophyBox)))
      .concat(Array.from(__selectAll('.philosophiesTitle, .philosophiesSubTitle', philosophyBox)))
      .concat(getPhilosophyProps(philosophyBox, philosophyELements).map((p) => p.selector))
      .concat(Array.from(getPhilosophyProps(philosophyBox, philosophyELements).map((p) => __select('.philosophySubTitle', p.selector))))
  );

  ScrollTrigger.matchMedia({
    [`(min-width: ${getBreakpoint(maxWidthBreakpoint)})`]: initScrollersDesktop,
    [`(max-width: ${getBreakpoint(maxWidthBreakpoint)})`]: initScrollersMobile,
  });

  return scrollers;
};

const createOperatorScroller = (philosophyBox: () => React.RefObject<HTMLDivElement>) => {
  const scrollers = [] as ScrollerArray;
  const initScrollers = () => {
    ScrollTrigger.create({
      trigger: __tr(philosophyBox),
      // markers: process.env.NODE_ENV === 'development',
      start: 'bottom 110%',
      end: 'bottom 100%',
      ...scrub(),
      invalidateOnRefresh: true,
      animation: gsap.timeline().fromTo(
        __selectAll('.philosophyOperator', philosophyBox),
        {
          opacity: 0,
        },
        {
          opacity: 1,
        },
        '>'
      ),
    });
  };
  ScrollTrigger.saveStyles(__selectAll('.philosophyOperator', philosophyBox));
  ScrollTrigger.matchMedia({
    [`(min-width: ${getBreakpoint(maxWidthBreakpoint)})`]: initScrollers,
  });
  return scrollers;
};

export const animateFounders = ({ founderBox, philosophyBox }: AnimateFoundersProps) => {
  gsap.registerPlugin(ScrollTrigger);

  createFounderScrollers(founderBox);
  createPhilosphyScroller(philosophyBox);
  createOperatorScroller(philosophyBox);
};

export const useAnimationFounders = (props: AnimateFoundersProps) => withAnimation<AnimateFoundersProps>(animateFounders, props);
