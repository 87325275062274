import { SxStyleProp } from 'theme-ui';

export const styles = {
  videoBox: {
    position: 'relative',
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateAreas: '"video"',
    placeItems: 'center',
    '&>*': {
      gridColumn: 1 / 3,
      gridRow: 1 / 3,
      width: '100%',
      height: '100%',
    },
    '&>video': {
      height: 'auto',
    },
  } as SxStyleProp,
  video: {
    position: 'relative',
    zIndex: 1,
    gridArea: 'video',
    // ...(isPlaying ? {
    //   transition: 'all 0.2s ease-out',
    //   position: 'absolute',
    //   top: 0,
    //   left: 0,
    //   width: 'auto',
    //   height: '100vh',
    // }: {}),
  } as SxStyleProp,
  videoOverlay: {
    position: 'relative',
    zIndex: 2,
    gridArea: 'video',
    cursor: 'pointer',
    backgroundColor: ['background', 'primary'],
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'opacity 0.2s ease-out',
  } as SxStyleProp,
  playButtonBox: {
    backgroundColor: 'background',
    borderRadius: '50%',
    width: 9,
    height: 9,
  },
  youtube: {
    position: 'relative',
    width: '100%',
    height: '100%',
    paddingBottom: '56.25%',
    iframe: {
      height: 'auto',
      minHeight: '50vh',
      width: '100%',
      border: 0,
    },
  } as SxStyleProp,
};
