import { SxStyleProp, themeConfig } from '@themed';

export const styles = {
  init: {
    section: {
      pt: 'headerHeight',
      minHeight: '100vh !important',
    },
    wrapper: {
      minHeight: 'unset !important',
      width: 'fullSize',
    },
    textAndFinni: {
      width: '100%',
      maxWidth: '100%',
      minHeight: 'auto !important',
      '&>.section-content': {
        minHeight: 'auto !important',
        '.wrapper': {
          [`@media screen and (min-width: ${themeConfig.sizes.tabletMaxWidth}) and (max-width: ${themeConfig.sizes.desktopMaxWidth})`]: {
            display: 'grid',
            gridTemplateColumns: '0.6fr 0.4fr',
            h2: {
              fontSize: '3rem',
              maxWidth: 'unset',
            },
          },
        },
      },
    },
  },
  howToDoIt: {
    zIndex: 112,
    position: 'relative',
    background: [
      'linear-gradient(90deg, #0093D3 0%, #0099DC 100%)',
      'linear-gradient(90deg, #0093D3 0%, #0099DC 100%)',
      'linear-gradient(90deg, #009FE2 0%, #0090D0 100%)',
    ],

    [`@media screen and (max-width: ${themeConfig.sizes.extraSmallMobileMaxWidth})`]: {
      '& #compact-section-button-link': {
        width: '100%',
        margin: 'auto',
      },
    },
  } as SxStyleProp,
  neobanksImage: {
    display: ['block', 'block', 'none'],
    mb: ['inherit', 7, 'inherit'],
    borderRadius: '50%',
    width: ['73vw', '40vw'],
    height: ['73vw', '40vw'],
  },
};
