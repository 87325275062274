/* eslint-disable camelcase */
import { Box, Button, Flex, Text } from 'theme-ui';
import { FaCloudUploadAlt, FaRegEdit } from 'react-icons/fa';
import { IoMdRemoveCircle } from 'react-icons/io';
import React from 'react';
import { themeConfig } from '@themed';
import { CTAButton, ImageLoader, InfoButton, SubTitle } from '@core';
import { UpdatedInstitution } from '@types';
import { useGlobalUpdatedInstitutionStack } from 'src/store/InstitutionState';
import { useUserToken } from '@store';
import { updateInstitutionsAndRecalculatePeriod, UpdateResponse } from '../../networking';
import LoadingPacman from './LoadingPacman';
import { SnackBar } from '../../../upload/components';
import { stackContainer, stackModal, stackRow, stackModalButtons, stackModalUpdatedInstitutions } from './UpdateStackPreviewer.styles';

interface UpdateInstitutionRowProps {
  institution: UpdatedInstitution;
  setCurrentlyEditedInstitution: (i: UpdatedInstitution | null) => void;
  setIsUpdatePreview: (b: boolean) => void;
}

const UpdatedInstitutionRow = ({ institution, setCurrentlyEditedInstitution, setIsUpdatePreview }: UpdateInstitutionRowProps) => {
  const { logo, name } = institution;
  const [updateStack, setUpdateStack] = useGlobalUpdatedInstitutionStack();

  const removeGlobalInstitution = (ins: UpdatedInstitution) => {
    const indexOfInstitutition = updateStack.map((gi) => gi.name).indexOf(updateStack.find((i) => i.name === ins.name)?.name || '-1');
    if (indexOfInstitutition !== -1) {
      setUpdateStack([...updateStack.slice(0, indexOfInstitutition), ...updateStack.slice(indexOfInstitutition + 1)]);
    }
  };
  return (
    <Flex sx={stackRow}>
      <Flex variant='flex.rowCenterVertical'>
        {logo && <ImageLoader src={logo.url} alt={logo.alternativeText} sx={stackRow.logo} />}
        <Text sx={stackRow.text}>{name}</Text>
      </Flex>
      <Flex>
        <Button
          onClick={() => {
            setCurrentlyEditedInstitution(institution);
            setIsUpdatePreview(false);
          }}
        >
          <FaRegEdit />
        </Button>
        <Button
          onClick={() => {
            removeGlobalInstitution(institution);
            if (updateStack.length === 1) setIsUpdatePreview(false);
          }}
        >
          <IoMdRemoveCircle color='#C10113' />
        </Button>
      </Flex>
    </Flex>
  );
};

interface UpdateStackPreviewerProps {
  display: boolean;
  setIsUpdatePreview: (b: boolean) => void;
  setCurrentlyEditedInstitution: (i: UpdatedInstitution | null) => void;
  previewUrl: string;
  onUpdateComplete: () => void;
}

const loaderStyles = {
  alignItems: 'center',
  '#pacman-loader-image': {
    width: '70px',
    height: '70px',
  },
};

export const UpdateStackPreviewer = ({
  display,
  setIsUpdatePreview,
  setCurrentlyEditedInstitution,
  previewUrl,
  onUpdateComplete,
}: UpdateStackPreviewerProps) => {
  const token = useUserToken();
  const [updateStack, setUpdateStack] = useGlobalUpdatedInstitutionStack();
  const [snackData, setSnackData] = React.useState<UpdateResponse | null>(null);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const update = async () => {
    setIsLoading(true);
    const { fs_period, fsType } = updateStack[0];
    const response = await updateInstitutionsAndRecalculatePeriod(updateStack, +fs_period, fsType, token);
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setSnackData({ ...response, link: previewUrl });
    setIsLoading(false);
    onUpdateComplete();
  };

  const handleSnackBarClose = () => {
    if (snackData?.status === 200) {
      setUpdateStack([]);
    }
    setSnackData(null);
    setIsLoading(false);
    setIsUpdatePreview(false);
  };

  return (
    <Box sx={stackContainer(display)}>
      <Box sx={stackModal}>
        <SubTitle>
          <FaCloudUploadAlt size={30} color={themeConfig.colors.primary} /> Update Institutions
        </SubTitle>
        <Flex variant='flex.col' sx={stackModalUpdatedInstitutions}>
          {updateStack.map((institution) => (
            <UpdatedInstitutionRow
              key={institution.id}
              institution={institution}
              setCurrentlyEditedInstitution={setCurrentlyEditedInstitution}
              setIsUpdatePreview={setIsUpdatePreview}
            />
          ))}
        </Flex>
        <Box sx={stackModalButtons}>
          {snackData?.status !== 200 && (isLoading ? <LoadingPacman sx={loaderStyles} /> : <CTAButton onClick={() => update()}>Confirm</CTAButton>)}
          <InfoButton onClick={() => setIsUpdatePreview(false)}>{snackData?.status !== 200 ? 'Cancel' : 'Close'}</InfoButton>
        </Box>
      </Box>
      <SnackBar open={!!snackData} handleClose={handleSnackBarClose} type={snackData?.status === 200 ? 'success' : 'error'}>
        {snackData?.data}
        <div>
          <a style={{ color: 'white' }} href={snackData?.link}>
            Preview Link
          </a>
        </div>
      </SnackBar>
    </Box>
  );
};
