import { FsType, Image, Node, WithFsType } from '@types';
import { graphql, useStaticQuery } from 'gatsby';
import { useSelectedFsType } from '.';

interface TopInstitutionBase {
  rank: number;
  name: string;
  logo?: Image;
}
interface TopInstitutionFromApi extends TopInstitutionBase, WithFsType {}
interface TopInstitution extends TopInstitutionBase {}

interface TopInstitutionQueryResult {
  allInstitution: {
    edges: Node<TopInstitutionFromApi>[];
  };
}

const formatInsitution = (data: TopInstitutionQueryResult, type: FsType): TopInstitution[] =>
  data.allInstitution.edges
    .filter(({ node: { fsType } }) => type === fsType)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    .map(({ node: { fsType, ...node } }) => node);

export const useTopInstitutions = (limit = 12, distinct = true) => {
  const [type] = useSelectedFsType();

  // NOTE, we always select the German as it does not have textual content, and limit is currently hardcoded
  const data: TopInstitutionQueryResult = useStaticQuery(graphql`
    query {
      allInstitution(sort: { order: ASC, fields: rank }, limit: 50, filter: { language: { eq: "de" } }) {
        edges {
          node {
            logo {
              url
              alternativeText
            }
            fsType
            name
            rank
          }
        }
      }
    }
  `);

  return formatInsitution(data, type)
    .reduce(
      (distinctLogos, currentLogo) => [
        ...distinctLogos,
        ...(distinct && !distinctLogos.find((logo1) => logo1.logo === currentLogo.logo) ? [currentLogo] : []),
      ],
      [] as TopInstitution[]
    )
    .slice(0, limit);
};
