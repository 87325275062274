import { useStaticQuery, graphql } from 'gatsby';
import { FluidImageProps, ImageEdgesType } from '@types';

export function useBackgroundImage(src: string): FluidImageProps | undefined {
  const data = useStaticQuery(graphql`
    query {
      allImageSharp {
        edges {
          node {
            fluid(maxHeight: 1440) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `);

  const source = (data.allImageSharp.edges as ImageEdgesType[])
    // Match string after final slash
    .find((element) => element.node.fluid.src.match(new RegExp(`/${src}$`)));

  // TODO: return source?.node only, as node.original contains the original sized image

  return source?.node?.fluid;
}
