import React from 'react';
import { Box, SxProps } from 'theme-ui';
import { VideoPlayer } from '@core';
import { Paragraph } from '@themed';
import { ChildrenProps, findFirstContentWithText, getVideoContent } from '@types';
import { videoStyles } from '../common/PostContent/index.styles';
import { PageMetaProps } from './PDFForm';
import { styles, headlineStyle } from './Video.styles';

export const VideoDescription = ({ content }: PageMetaProps) => {
  const firstTextContent = findFirstContentWithText(content?.content);
  return <Paragraph as='p'>{firstTextContent?.text}</Paragraph>;
};

interface VideoProps extends PageMetaProps, SxProps, ChildrenProps {}

export const Video = ({ content, children, ...props }: VideoProps) => {
  const video = getVideoContent(content);

  return video ? (
    <Box {...props} sx={{ ...styles, ...props.sx }}>
      {video.headline && (
        <Paragraph as='h2' sx={headlineStyle}>
          {video.headline}
        </Paragraph>
      )}
      {children}
      <VideoPlayer video={video} sx={videoStyles} />
    </Box>
  ) : null;
};
