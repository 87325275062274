import { Flex, SectionWithContent } from '@core';
import React from 'react';
import { StyledTestimonals } from '../Testimonals';
import { styles, testimonialStyles } from './WorkshopTestimonals.styles';

export const WorkshopTestimonals = React.forwardRef<HTMLDivElement>((_, ref) => (
  <SectionWithContent id='workshops-testimonials' ref={ref} variant='styles.layoutMaxWidth' sx={styles.section}>
    <Flex sx={styles.content} variant='flex.colCenter'>
      <StyledTestimonals styles={testimonialStyles} />
    </Flex>
  </SectionWithContent>
));
