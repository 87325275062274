import { SxStyleProp } from 'theme-ui';

const maxItems = 9;
const minItems = 3;

export const getListHeight = (items: number) => {
  if (items > maxItems) return 280;
  return 93 + (items > minItems ? (items - minItems) * 30 : 0);
};

export const styles = {
  box: {
    px: 3,
    py: 2,
    '&>*': {
      width: '100%',
    },
    pb: 5,
  },
  list: (items: number) =>
    ({
      mt: 4,
      height: `${getListHeight(items)}px`,
      overflowY: items > maxItems ? 'scroll' : 'auto',
      overflowX: 'auto',
      /* Hide scrollbar for Chrome, Safari and Opera */
      ...(items <= maxItems
        ? {
            '&::-webkit-scrollbar': {
              display: 'none',
            },
            MsOverflowStyle: 'none' /* IE and Edge */,
            ScrollbarWidth: 'none' /* Firefox */,
          }
        : {}),
      width: '100%',
    }) as SxStyleProp,
};
