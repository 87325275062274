import React from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/all';
import { __tr, __select } from '@modules';
import { scrub, withAnimation } from '@core';
import { getBreakpoint } from '@themed';

interface AnimateWorkshopPackagesProps {
  triggerRef?: () => React.RefObject<HTMLDivElement>;
  onlyMobile?: boolean;
}

type AnimationProps = {
  trigger: Element;
};

const animateFly = ({ trigger }: AnimationProps) => {
  const tl = gsap.timeline();

  const packages = trigger.querySelectorAll('.package');

  packages.forEach((pkg, i) => {
    const leftPosition = (100 / packages.length) * i;

    tl.set(pkg, {
      marginTop: `-${23 * i + 1}vh`,
      position: 'absolute',
      left: `${leftPosition}%`,
      zIndex: i + 1,
    });
  });

  tl.to(packages, {
    marginTop: 0,
    ease: 'expoScale(0.5, 7, none)',
    stagger: {
      amount: 1,
      from: 'start',
    },
    duration: 2,
  });

  return tl;
};

const runFlyingAnimation = ({ triggerRef }: AnimateWorkshopPackagesProps) => {
  const trigger = __tr(triggerRef);
  if (!trigger) return;

  ScrollTrigger.create({
    refreshPriority: 1,
    trigger,
    ...scrub(),
    // markers: process.env.NODE_ENV === 'development',
    start: '+=10% bottom',
    end: '-=20% top',
    animation: animateFly({ trigger }),
  });
};

export const animatePackages = (props: AnimateWorkshopPackagesProps) => {
  gsap.registerPlugin(ScrollTrigger);

  const desktopAnimations = { [`(min-width: ${getBreakpoint(1)})`]: () => runFlyingAnimation(props) };

  ScrollTrigger.saveStyles([__select('[aria-label]')]);
  ScrollTrigger.matchMedia({
    ...desktopAnimations,
  });
};

export const useAnimateWorkshopPackages = (props: AnimateWorkshopPackagesProps) =>
  withAnimation<AnimateWorkshopPackagesProps>(animatePackages, props);
