import React from 'react';
import { Box, CompactSectionContentStyles, ParallaxImage, Paragraph, Title, Flex, SectionWithContent, PageTitle } from '@core';
import { useTranslate } from '@modules';
import { styles } from './WhoWeAre.styles';

export const WhoWeAre = () => {
  const __ = useTranslate();
  const whoWeAreRef = React.useRef(null);
  const [, setHeight] = React.useState(1000);

  React.useLayoutEffect(() => {
    setHeight(window?.innerHeight);
    window.addEventListener('resize', () => setHeight(window?.innerHeight));
  }, []);

  return (
    <SectionWithContent ref={whoWeAreRef} id='init' sx={{ bg: 'background' }}>
      <CompactSectionContentStyles>
        <Flex sx={styles.containerRow}>
          <Flex sx={styles.topRow}>
            <Box sx={styles.textContainer} data-cy='who-we-are-text-container'>
              <PageTitle>{__('pages.aboutus.init.pagetitle')}</PageTitle>
              <Title displayAs='h1' sx={styles.title} data-cy='who-we-are-title'>
                {__('pages.aboutus.init.title')}
              </Title>
              <Paragraph className='paragraph' data-cy='who-we-are-paragraph'>
                {__('pages.aboutus.init.paragraph')}
              </Paragraph>
            </Box>
            <Box sx={styles.topTeamContainer} data-cy='who-we-are-parallax-image'>
              <ParallaxImage sx={styles.topTeam} src='team_04.jpg' alt={__('pages.aboutus.init.parallaxImgAlt')} />
            </Box>
          </Flex>
        </Flex>
      </CompactSectionContentStyles>
    </SectionWithContent>
  );
};
