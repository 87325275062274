import React from 'react';
import { useLocale, getFormattedDate, getContentTypeUrl, ContentTypes } from '@modules';
import { Link, UserAvatar } from '@core';
import { AuthorHeader } from '@types';
import { Box, Flex, Text } from '@themed';
import { PostContentContainer } from './PostContentContainer';
import { styles } from './PostAuthor.styles';

interface PostAuthorProps extends AuthorHeader {
  date?: Date;
  link: string;
}

export const PostAuthor = ({ avatar, date, link, name }: PostAuthorProps) => {
  const locale = useLocale();

  return (
    <PostContentContainer>
      <Flex sx={styles.author}>
        <Box>
          {avatar && (
            <Link to={getContentTypeUrl(link, locale, ContentTypes.author)}>
              <UserAvatar src={avatar.url} alt={avatar.alternativeText} />
            </Link>
          )}
        </Box>
        <Box sx={styles.authorInfo}>
          <Box>
            <Link to={getContentTypeUrl(link, locale, ContentTypes.author)}>
              <Text>{name}</Text>
            </Link>
          </Box>
          <Box>
            <Text>{getFormattedDate(date, locale)}</Text>
          </Box>
        </Box>
      </Flex>
    </PostContentContainer>
  );
};
