/** @jsx jsx */
import React from 'react';
import { Flex, jsx, SxProps } from 'theme-ui';
import { ChildrenProps } from '@types';
import { chartStyles } from './Chart.styles';

interface ChartProps extends ChildrenProps, SxProps {}

export const Chart = React.forwardRef<HTMLDivElement, ChartProps>(({ children, ...props }, ref) => (
  <Flex ref={ref} {...props} sx={{ ...chartStyles, ...props.sx }}>
    {children}
  </Flex>
));
