import * as React from 'react';

const Bulb4 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width='61px' height='91px' viewBox='0 0 61 91' version='1.1' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g id='Page-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g id='Artboard' transform='translate(-453, -3)'>
        <g id='bulb-4' transform='translate(453.0077, 4.33)'>
          <line x1='30.060346' y1='31.376' x2='30.060346' y2='0' id='Path' stroke='#D2D2D2' strokeWidth='1.40511' strokeLinecap='round'></line>
          <path
            d='M33.994346,33.539 L42.383346,44.078 C42.934346,44.771 43.662346,45.303 44.490346,45.616 L57.136346,50.344 C57.825346,50.605 58.446346,51.017 58.954346,51.549 C59.462346,52.082 59.844346,52.722 60.072346,53.421 C60.300346,54.121 60.368346,54.863 60.271346,55.593 C60.174346,56.323 59.914346,57.021 59.511346,57.637 L52.085346,68.878 C51.592346,69.617 51.312346,70.477 51.277346,71.365 L50.680346,84.819 C50.645346,85.555 50.445346,86.274 50.095346,86.922 C49.745346,87.571 49.254346,88.132 48.658346,88.565 C48.061346,88.998 47.375346,89.292 46.650346,89.424 C45.926346,89.556 45.180346,89.524 44.469346,89.329 L31.493346,85.739 C30.636346,85.502 29.730346,85.502 28.873346,85.739 L15.840346,89.322 C15.129346,89.517 14.384346,89.549 13.659346,89.417 C12.934346,89.285 12.248346,88.991 11.652346,88.558 C11.056346,88.125 10.565346,87.564 10.214346,86.915 C9.86434605,86.267 9.66534605,85.548 9.63034605,84.812 L9.03234605,71.358 C8.99734605,70.47 8.71834605,69.61 8.22434605,68.871 L0.798346047,57.63 C0.396346047,57.013 0.137346047,56.313 0.0413460475,55.582 C-0.0536539525,54.852 0.0153460475,54.109 0.245346047,53.409 C0.475346047,52.708 0.860346047,52.069 1.37034605,51.537 C1.88134605,51.006 2.50434605,50.596 3.19434605,50.337 L15.840346,45.609 C16.668346,45.296 17.397346,44.764 17.948346,44.071 L26.336346,33.532 C26.798346,32.961 27.381346,32.5 28.044346,32.184 C28.707346,31.867 29.433346,31.704 30.167346,31.704 C30.902346,31.705 31.627346,31.87 32.289346,32.188 C32.951346,32.505 33.534346,32.967 33.994346,33.539 Z'
            id='Path'
            fill='#019877'
            fillRule='nonzero'
          ></path>
          <path
            d='M41.742346,72.463 L41.325346,72.463 L41.325346,69.803 C41.325346,69.185 40.752346,68.662 40.075346,68.662 L39.242346,68.662 L39.242346,61.44 L36.742346,61.44 L36.742346,68.662 L34.242346,68.662 L34.242346,61.44 L31.742346,61.44 L31.742346,68.662 L29.242346,68.662 L29.242346,61.44 L26.742346,61.44 L26.742346,68.662 L24.242346,68.662 L24.242346,61.44 L21.742346,61.44 L21.742346,68.662 L20.909346,68.662 C20.179346,68.662 19.659346,69.185 19.659346,69.803 L19.659346,72.463 L19.242346,72.463 C18.513346,72.463 17.992346,72.986 17.992346,73.604 L17.992346,74.364 C17.992346,74.602 18.148346,74.744 18.409346,74.744 L42.575346,74.744 C42.784346,74.744 42.992346,74.602 42.992346,74.364 L42.992346,73.604 C42.992346,72.986 42.419346,72.463 41.742346,72.463 Z M38.825346,72.463 L22.159346,72.463 L22.159346,70.943 L38.825346,70.943 L38.825346,72.463 Z M42.575346,57.163 L31.273346,52.839 C31.013346,52.744 30.752346,52.744 30.492346,52.744 C30.179346,52.744 29.919346,52.744 29.659346,52.839 L18.357346,57.163 C18.148346,57.211 17.992346,57.448 17.992346,57.686 L17.992346,58.969 C17.992346,59.302 18.252346,59.539 18.617346,59.539 L19.659346,59.539 L19.659346,60.109 C19.659346,60.442 19.919346,60.679 20.284346,60.679 L40.700346,60.679 C41.013346,60.679 41.325346,60.442 41.325346,60.109 L41.325346,59.539 L42.367346,59.539 C42.679346,59.539 42.992346,59.302 42.992346,58.969 L42.992346,57.686 C42.992346,57.448 42.784346,57.211 42.575346,57.163 Z M20.492346,58.399 L30.492346,54.978 L40.492346,58.399 L20.492346,58.399 Z'
            id='Shape'
            fill='#FFFFFF'
            fillRule='nonzero'
          ></path>
          <path
            d='M45.699346,84.777 L32.702346,81.187 C31.026346,80.728 29.257346,80.728 27.580346,81.187 L14.597346,84.777 C14.571346,84.781 14.545346,84.779 14.519346,84.773 C14.494346,84.766 14.470346,84.754 14.450346,84.737 C14.429346,84.721 14.412346,84.7 14.400346,84.677 C14.388346,84.653 14.381346,84.628 14.379346,84.601 L13.782346,71.147 C13.710346,69.408 13.164346,67.722 12.201346,66.272 L4.77534605,55.031 C4.76134605,55.006 4.75434605,54.978 4.75434605,54.95 C4.75434605,54.922 4.76134605,54.894 4.77534605,54.869 C4.78134605,54.841 4.79534605,54.814 4.81434605,54.793 C4.83434605,54.772 4.86034605,54.757 4.88834605,54.75 L17.499346,50.022 C19.129346,49.41 20.564346,48.369 21.651346,47.008 L30.039346,36.469 C30.055346,36.444 30.076346,36.424 30.102346,36.41 C30.128346,36.397 30.158346,36.39 30.187346,36.3916624 C30.217346,36.391 30.247346,36.398 30.274346,36.411 C30.301346,36.425 30.324346,36.445 30.341346,36.469 L38.730346,47.008 C39.816346,48.369 41.251346,49.41 42.882346,50.022 L55.493346,54.75 C55.519346,54.76 55.543346,54.776 55.563346,54.796 C55.582346,54.817 55.597346,54.841 55.606346,54.868 C55.6153105,54.895 55.617346,54.924 55.613346,54.952 C55.610346,54.98 55.600346,55.007 55.584346,55.031 L48.158346,66.272 C47.200346,67.724 46.654346,69.409 46.577346,71.147 L45.945346,84.615 C45.942346,84.664 45.920346,84.709 45.885346,84.743 C45.850346,84.777 45.804346,84.797 45.755346,84.798 L45.699346,84.777 Z'
            id='Path'
            stroke='#FFFFFF'
            strokeWidth='1.40511'
            strokeLinecap='round'
            strokeLinejoin='round'
          ></path>
          <path
            d='M26.378346,14.227 C27.910346,15.758 30.151346,23.536 30.151346,23.536 C30.151346,23.536 22.374346,21.288 20.849346,19.763 C20.143346,19.024 19.754346,18.038 19.766346,17.016 C19.777346,15.994 20.188346,15.018 20.910346,14.294 C21.632346,13.571 22.608346,13.159 23.630346,13.147 C24.652346,13.134 25.638346,13.522 26.378346,14.227 Z'
            id='Path'
            stroke='#D2D2D2'
            strokeWidth='1.40511'
            strokeLinecap='round'
          ></path>
          <path
            d='M33.720346,14.226 C32.195346,15.757 29.947346,23.535 29.947346,23.535 C29.947346,23.535 37.724346,21.286 39.256346,19.762 C39.619346,19.398 39.908346,18.967 40.105346,18.492 C40.301346,18.017 40.403346,17.508 40.403346,16.994 C40.403346,16.48 40.301346,15.971 40.105346,15.496 C39.908346,15.021 39.619346,14.589 39.256346,14.226 C38.892346,13.862 38.461346,13.574 37.986346,13.377 C37.511346,13.18 37.002346,13.079 36.488346,13.079 C35.974346,13.079 35.465346,13.18 34.990346,13.377 C34.515346,13.574 34.083346,13.862 33.720346,14.226 Z'
            id='Path'
            stroke='#D2D2D2'
            strokeWidth='1.40511'
            strokeLinecap='round'
          ></path>
          <path
            d='M38.112346,27.863 C37.250346,28.227 36.297346,28.319 35.381346,28.126 C34.465346,27.932 33.631346,27.463 32.990346,26.781 L29.948346,23.535 L28.051346,28.833 C27.723346,29.745 27.062346,30.501 26.201346,30.947 C25.340346,31.394 24.342346,31.499 23.407346,31.242'
            id='Path'
            stroke='#D2D2D2'
            strokeWidth='1.40511'
            strokeLinecap='round'
          ></path>
        </g>
      </g>
    </g>
  </svg>
);
export default Bulb4;
