import React from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/all';
import { TriggerElementProps } from '@types';
import { __el, __tr } from '@modules';
import { cleanupScrolls, getBreakpoint, scrub, withAnimation } from '@core';

interface AnimateBlogScrollerMaskProps extends TriggerElementProps {
  animatedElementRef: () => React.RefObject<HTMLDivElement>;
  contentRef: () => React.RefObject<HTMLDivElement>;
}

export const animateBlogScrollerMask = ({ triggerRef, animatedElementRef, contentRef }: AnimateBlogScrollerMaskProps) => {
  gsap.registerPlugin(ScrollTrigger);
  const scrollers = [] as gsap.plugins.ScrollTriggerInstance[];

  const initScrollrs = () => {
    const trigger = __tr(triggerRef);
    if (!trigger) return;

    scrollers.push(
      ScrollTrigger.create({
        toggleActions: 'restart complete reset reset',
        trigger,
        start: 'top top',
        end: () => `top+=${window?.innerHeight} center`,
        invalidateOnRefresh: true,
        ...scrub(),
        animation: gsap
          .timeline()
          .to(__el(animatedElementRef), {
            transformOrigin: 'bottom right',
            rotate: '-15deg',
            yPercent: () => -50,
            duration: 0.5,
          })
          .to(__el(animatedElementRef), {
            transformOrigin: 'bottom right',
            rotate: '0deg',
            yPercent: () => -50,
            duration: 0.5,
          })
          .to(
            __el(contentRef),
            {
              opacity: 0,
            },
            '<'
          ),
      })
    );
  };

  ScrollTrigger.matchMedia({
    [`(min-width: ${getBreakpoint(0)})`]: initScrollrs,
  });

  return () => {
    cleanupScrolls();
  };
};

export const useAnimationBlogScrollerMask = (props: AnimateBlogScrollerMaskProps) =>
  withAnimation<AnimateBlogScrollerMaskProps>(animateBlogScrollerMask, props);
