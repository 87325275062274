import React from 'react';
import { ImageProps, ChildrenProps } from '@types';
import { BackgroundImage } from '@core';
import { useAnimationParallaxBackgroundImage } from './animateParallaxBackgroundImage';

interface ParallaxBackgroundImage extends ImageProps, ChildrenProps {}

export const ParallaxBackgroundImage = ({ src, children, ...props }: ParallaxBackgroundImage) => {
  const bgRef = React.useRef(null);

  useAnimationParallaxBackgroundImage({ triggerRef: () => bgRef });

  return (
    <BackgroundImage {...props} src={src} ref={bgRef} data-animation='parallax-background'>
      {children}
    </BackgroundImage>
  );
};
