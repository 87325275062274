import { getTypeFromPath } from '@config';
import { CTAWithOptionalLink, SvgIcon } from '@core';
import { ThemeContext } from '@emotion/core';
import { __, useFinnoscoreBannerContent } from '@modules';
import { useLocation } from '@reach/router';
import { Box, BoxProps, Paragraph, SubTitle, Theme } from '@themed';
import { Colors } from '@types';
import React, { useContext } from 'react';
import { styles } from './ReportAdvertLink.styles';

interface Props extends BoxProps {}

export default ({ sx, ...props }: Props) => {
  const theme = useContext(ThemeContext) as Theme;
  const { pathname } = useLocation();
  const { title, paragraph } = useFinnoscoreBannerContent(`finnoscore-${getTypeFromPath(pathname)}-banner`);

  return (
    <Box {...props} sx={{ ...styles.box, ...sx }}>
      <SubTitle sx={styles.title}>{title}</SubTitle>

      <Paragraph sx={styles.paragraph}>{paragraph}</Paragraph>

      <CTAWithOptionalLink to={__(`finnoscore.${getTypeFromPath(pathname)}.ranking.link`)} buttonVariant='ctaBlackText' sx={styles.button(theme)}>
        <span className='text'>{__(`finnoscore.${getTypeFromPath(pathname)}.ranking.buttonText`)}</span>
        <SvgIcon name='tailedArrow' color={(theme.colors?.textInvert ?? 'text') as Colors} />
      </CTAWithOptionalLink>
    </Box>
  );
};

const reportButtonStyles = {
  width: ['100%', '100%', 10],
  marginRight: [0, 0, '1rem'],
  marginBottom: [4, 4, 0],
  span: {
    display: 'flex',
    justifyContent: 'center',
    color: 'text',
    margin: 'auto',
  },
};

export function ReportButton() {
  const { pathname } = useLocation();
  const { link, target, buttonText } = useFinnoscoreBannerContent(`finnoscore-${getTypeFromPath(pathname)}-banner`);

  if (link && buttonText) {
    return (
      <CTAWithOptionalLink buttonVariant='cta' to={link} target={target} sx={reportButtonStyles}>
        <span className='text'>{buttonText}</span>
      </CTAWithOptionalLink>
    );
  }

  return <></>;
}
