/** @jsx jsx */
import React from 'react';
import { useCookie } from '@modules';
import { jsx, Button, Box } from '@themed';
import { ChildrenProps } from '@types';
import { styles } from './Modal.styles';

interface ModalOverlayProps extends ChildrenProps {
  onClose: () => void;
}
const ModalOverlay = ({ children, onClose }: ModalOverlayProps) => (
  <Box sx={styles.container} onClick={onClose}>
    <Box sx={styles.content} onClick={(e) => e.stopPropagation()}>
      <Button sx={styles.closeButton} onClick={onClose} />
      {children}
    </Box>
  </Box>
);

interface TimerProps {
  minutes?: number;
  seconds?: number;
}

export const useModalOverlayTimer = ({ minutes = 0, seconds = 0 }: TimerProps) => {
  const {
    value: offer,
    setValue: setOffer,
    isLoaded,
  } = useCookie(process.env.GATSBY_COOKIE_NAME_FINNOSCORE_NEWSLETTER_OFFERED || 'finnoscoreNewsletter');
  const displayed = offer === 'DISPLAYED';
  const started = !displayed && offer && new Date(offer) && !Number.isNaN(new Date(offer).getTime()) ? new Date(offer) : null;
  const cookieExpirationDays = Number(process.env.GATSBY_COOKIE_EXPIRATION_FINNOSCORE_NEWSLETTER_OFFERED) || 7;

  const timeout = (minutes * 60 + seconds) * 1000;
  const [active, setActive] = React.useState(false);

  const setActiveWithCookie = (value: boolean) => {
    if (value) setOffer('DISPLAYED', cookieExpirationDays);
    setActive(value);
  };

  React.useEffect(() => {
    // NOTE: might be better to implement later with XState
    // if (process.env.NODE_ENV === 'development') setActive(true);

    // Don't show again if it was already shown within `cookieExpirationDays`, and also if we hadn't received cookie item
    if (displayed || !isLoaded) {
      return () => null;
    }

    // timeout had passed, during page transition, based on cookie
    if (started && new Date().getTime() - started.getTime() > timeout) {
      setActiveWithCookie(true);
      return () => null;
    }

    // restart cookie date value either if we need timeout or on the first load
    if (!started) {
      setOffer(new Date().toISOString(), cookieExpirationDays);
      // Don't return here
      // return () => null;
    }

    // start timeout, and trigger after 30 seconds being inactive on the page
    const timeoutHandler = setTimeout(() => setActiveWithCookie(true), timeout);
    return () => clearTimeout(timeoutHandler);
  }, [isLoaded, timeout]);

  return {
    // active,
    // setActive: setActiveWithCookie,
    close: () => setTimeout(() => setActiveWithCookie(false), 100),
    Component: ({ ...props }: ChildrenProps) => (active ? <ModalOverlay onClose={() => setActiveWithCookie(false)} {...props} /> : null),
  } as const;
};
