import { FsType, Locale } from '@types';

const { GATSBY_STRAPI_API_URL: gatsbyStrapiUrl } = process.env;

export const getStrapiRoutes = (apiRoot = gatsbyStrapiUrl || '') => {
  if (!apiRoot) throw new Error(`apiRoot has to be defined (apiRoot=${apiRoot}, GATSBY_STRAPI_API_URL=${gatsbyStrapiUrl})!`);
  return Object.freeze({
    root: `${apiRoot}`,
    previewLink: (locale: Locale, fsType: FsType, date?: string) => `${apiRoot}/${locale}/${fsType}s-preview/?date=${date}`,
    cms: {
      dashboard: `${apiRoot}/admin`,
      login: `${apiRoot}/admin/login`,
      forgotPassword: `${apiRoot}/admin/forgot-password`,
      authenticate: `${apiRoot}/auth/local`,
      excelUpload: `${apiRoot}/fs-excel/upload`,
      recalculateForChartGenerator: (periodId: number, fsType: FsType) => `${apiRoot}/fs-excel/chart-generator/recalculate/${periodId}/${fsType}`,
      saveSubscription: `${apiRoot}/subscriptions/save`,
    },
    cdn: {
      prefix: process.env.GATSBY_CDN_IMAGE_PREFIX || '',
      regex: new RegExp(process.env.GATSBY_CDN_IMAGE_REGEX || '.*'),
    },
  });
};
