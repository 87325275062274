import { useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/all';
import { OptionalTriggerElementProps } from '@types';
import { __el, __tr } from '@modules';
import { selectResponsiveProp } from '@themed';

import { scrub } from '../animation';

export interface AnimateParallaxProps extends OptionalTriggerElementProps {
  image: () => React.RefObject<HTMLDivElement>;
}

export const animateParallax = ({ image, triggerRef }: AnimateParallaxProps) => {
  gsap.registerPlugin(ScrollTrigger);

  const transitions = selectResponsiveProp([
    [-30, 30],
    [-30, 30],
  ]);

  ScrollTrigger.create({
    trigger: __tr(triggerRef) || __tr(image),
    ...scrub(),
    refreshPriority: 5,
    // markers: process.env.NODE_ENV === 'development',
    start: () => 'top bottom',
    end: () => 'bottom top',
    animation: gsap.timeline().fromTo(
      __el(image),
      {
        yPercent: transitions[0], // TODO: set to 0 for mobile, once merged with parallax bg image PR
      },
      {
        yPercent: transitions[1],
        ease: 'linear',
      }
    ),
  });
};

export interface UseAnimationParallaxProps extends AnimateParallaxProps {
  init: boolean;
}

export const useAnimationParallax = ({ init, ...props }: UseAnimationParallaxProps) =>
  useEffect(() => {
    if (init) animateParallax(props);
  }, [init]);
