/** @jsx jsx */
import { NewsletterModal } from '@composite';
import { Layout, StaticSEO } from '@core';
import {
  FinnoscoreComparisonSectionData,
  SelectedCountryContextProvider,
  SelectedFsTypeContextProvider,
  useDefaultCountry,
  useSelectionBasedOnFsType,
} from '@modules';
import { SxStyles, WithFsType } from '@types';
import React from 'react';
import { jsx } from 'theme-ui';
import { ColorContextProvider } from '../colorpicker';
import { finnoscoreDataStyles } from '../finnoscore-data/index.styles';
import Profile from '../finnoscore-data/Profile';
import ReportAdvertLink from '../finnoscore-data/ReportAdvertLink';
import { FSCompareTabSelector, useFsSelectorOptions } from '../finnoscore-list/FSTabSelector';
import { FinnoscoreComparisonChart, FinnoscoreComparisonSection, FinnosoreComparisonSectionMeta } from './FinnoscoreComparisonSection';

const styles: SxStyles = {
  main: finnoscoreDataStyles.main,
};

export interface PageContextProps extends FinnosoreComparisonSectionMeta {
  pageContext: FinnoscoreComparisonSectionData & WithFsType;
}

const ComparePage = ({ isLoading, error, pageContext }: PageContextProps) => {
  const { countries, institutions, countryCalculations } = useSelectionBasedOnFsType(pageContext);
  const tabOptions = useFsSelectorOptions(institutions);

  return (
    <FinnoscoreComparisonSection isLoading={isLoading} error={error}>
      <FSCompareTabSelector id='chartType' sx={finnoscoreDataStyles.tabSelector} options={tabOptions} />

      <ReportAdvertLink id='report' />
      <Profile id='contact' />

      <FinnoscoreComparisonChart countries={countries} institutions={institutions} countryCalculations={countryCalculations} />
    </FinnoscoreComparisonSection>
  );
};

const PageWithLayout = ({ pageContext, isLoading, error }: PageContextProps) => {
  const { countries, fsType } = pageContext;
  const { country: selectedCountry } = useDefaultCountry(countries);

  return (
    <React.Fragment>
      <Layout sx={styles.main}>
        <StaticSEO pageId={`finnoscore-list-${fsType}`} />
        <SelectedFsTypeContextProvider>
          {selectedCountry ? (
            <SelectedCountryContextProvider defaultValue={selectedCountry}>
              <ColorContextProvider>
                <ComparePage pageContext={pageContext} isLoading={isLoading} error={error} />
              </ColorContextProvider>
            </SelectedCountryContextProvider>
          ) : (
            <FinnoscoreComparisonSection isLoading={isLoading} error={error} />
          )}
        </SelectedFsTypeContextProvider>
      </Layout>
      <NewsletterModal />
    </React.Fragment>
  );
};

export default PageWithLayout;
