import React from 'react';
import { Image } from '@core';
import { useTranslate } from '@modules';
import { ContactSection } from '../ContactSection';
import { Init } from './Init';
import { Steps } from './Steps';
import { Deliver } from './Deliver';
import { Implementation } from './Implementation';

export const contactIconStyles = {
  borderRadius: 'fullSize',
  variant: 'flex.center',
  width: 'bigMobileIcon',
  height: 'bigMobileIcon',
};

export const Rapid = () => {
  const __ = useTranslate();

  return (
    <React.Fragment>
      <Init />
      <Steps />
      <Deliver />
      <Implementation />
      <ContactSection id='rapid.contact'>
        <Image src='/images/pacman-white_2x.gif' alt={__('pages.rapid.contact.contactImgAlt')} sx={contactIconStyles} />
      </ContactSection>
    </React.Fragment>
  );
};
