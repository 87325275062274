import React from 'react';
import { Flex, SectionWithContent, useIsDesktop } from '@core';
import { StyledTestimonals } from '../Testimonals';
import { testimonialStyles } from '../workshops/WorkshopTestimonals.styles';
import { testimonialStyles as desktopTestimonialStyles } from './Testimonial.styles';
import { useFinnoscoreTestimonialAnimation } from './animateTestimonials';

const styles = {
  section: {
    bg: ['muted', 'muted', 'background'],
    px: [4, 7, 4, 0],
  },
  flex: {
    variant: 'flex.left',
    width: '100%',
  },
};

export function FinnoscoreTestimonials() {
  const isDesktop = useIsDesktop();

  const testimonialRef = React.useRef(null);

  useFinnoscoreTestimonialAnimation({
    sectionRef: () => testimonialRef,
  });

  return (
    <SectionWithContent id='finnoscore-testimonials' ref={testimonialRef} sx={styles.section}>
      <Flex sx={styles.flex}>
        <StyledTestimonals styles={isDesktop ? desktopTestimonialStyles : testimonialStyles} />
      </Flex>
    </SectionWithContent>
  );
}
