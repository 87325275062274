import React from 'react';
import { SxProps } from '@themed';
import { ImageLoader } from '@core';

interface AvatarProps extends SxProps {
  src: string;
  alt?: string;
}

const styles = {
  wrapper: {
    width: 8,
    height: 8,
    overflow: 'hidden',
    borderRadius: '50%',
    '&, *': {
      pr: 0,
    },
  },
};

export const UserAvatar = ({ src, alt, ...props }: AvatarProps) => (
  <ImageLoader src={src} alt={alt} {...props} sx={{ ...styles.wrapper, ...props.sx }} />
);
