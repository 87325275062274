import { SxStyleProp } from 'theme-ui';
import { themeConfig } from '@themed';

export const styles = {
  section: {
    zIndex: 4,
    padding: [4, 4, null],
    mt: [-8, 0],
  },
  box: {
    flexDirection: 'column',
    width: '100%',

    '&>*:nth-of-type(even)': {
      flexDirection: ['column', 'column', 'row'],
    },
    '&>*:nth-of-type(odd)': {
      flexDirection: ['column', 'column', 'row-reverse'],
    },

    mb: [0, 0, 8],
    mt: [0, 9],
  } as SxStyleProp,
  outerBox: {
    position: 'relative',
    justifyContent: ['flex-start', 'flex-start', 'flex-end'],
    alignItems: ['center', 'center', 'flex-end'],
    marginBottom: 4,
  } as SxStyleProp,
  innerBox: {
    [`@media screen and (max-width: ${themeConfig.sizes.mobileMaxWidth})`]: {
      mt: 'bigMobileIcon',
    },
    flex: '0 0 auto',
  },
  content: {
    flexDirection: 'column',
    paddingBottom: 8,
    '&#pages-finnoscore-services-finnopretation': {
      output: {
        display: ['block', 'inline-block', 'block', 'inline-block'],
        width: 'max-content',
        b: {
          display: 'block',
        },
        br: {
          display: 'none',
        },
        mb: 4,
      },
      'output + output': {
        ml: [0, 4, 0, 4],
      },
    },
  } as SxStyleProp,
  image: {
    marginLeft: 8,
    marginRight: 8,
    [`@media screen and (max-width: ${themeConfig.sizes.mobileMaxWidth})`]: {
      transform: (t: any) => `translateY(${t.sizes.bigMobileIcon})`,
    },
  },
  icon: {
    variant: 'images.pureCircleIcon',
    backgroundColor: 'primary',
    'svg path': {
      fill: 'background',
    },
    mb: 5,

    zIndex: 4,
  },
};

export const listStyles = {
  imageN: [
    {
      width: [10, '346px'],
      height: [10, '346px'],

      borderRadius: '50%',
      '.parallax-resize': {
        transformOrigin: 'center',
      },
    },
    {
      width: [10, '380px'],
      height: [10, '380px'],

      borderRadius: '50%',
      '.parallax-resize': {
        transformOrigin: 'center',
      },
    },
  ],
  iconN: [
    {
      [`@media screen and (max-width: ${themeConfig.sizes.mobileMaxWidth})`]: {
        position: 'absolute',
        right: '10vw',
        top: '15vw',
      },
    },
    {
      [`@media screen and (max-width: ${themeConfig.sizes.mobileMaxWidth})`]: {
        position: 'absolute',
        left: '10vw',
        top: '15vw',
      },
    },
  ],
};
