/** @jsx jsx */
import { Children } from '@types';
import React from 'react';
import { RGBColor } from 'react-color';
import { BoxProps, jsx } from 'theme-ui';
import PercentageContext from '../../context/PercentageContext';
import { Bar } from './Bar';
import { Monochrome } from './Monochrome';

interface ChartItemProps extends BoxProps {
  percentage: number;
  fillComponent?: Children;
  defaultColor?: RGBColor;
}

export const ChartItem = React.forwardRef<HTMLDivElement, ChartItemProps>(
  ({ percentage, children, defaultColor, fillComponent = <Monochrome defaultColor={defaultColor} />, ...props }, ref) => (
    <PercentageContext.Provider value={percentage}>
      <Bar ref={ref as unknown as any} {...props}>
        {fillComponent}
        {children}
      </Bar>
    </PercentageContext.Provider>
  )
);
