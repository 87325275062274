export const styles = {
  containerRow: {
    gap: 0,
    display: 'grid',
    gridTemplateColumns: ['1fr', '1fr', '1fr 1fr'],
    gridTemplateAreas: ['"topImage" "texts" "bottomImage"', '"topImage" "texts" "bottomImage"', '"topImage texts" "bottomImage texts"'],
    placeItems: 'center',
  },
  topImage: {
    width: ['65vw', '65vw', '350px'],
    height: ['65vw', '65vw', '350px'],
    borderRadius: '50%',
    gridArea: 'topImage',

    transform: ['translate(0, 0)', 'translate(50%, 0)', 'translate(-10%, 0)', 'translate(-20%, 0)'],
    '@media screen and (orientation: landscape) and (max-height: 600px)': {
      transform: 'none !important',
    },
  },
  bottomImage: {
    width: ['41vw', '41vw', '276px'],
    height: ['41vw', '41vw', '276px'],
    borderRadius: '50%',
    gridArea: 'bottomImage',

    transform: ['none', 'none', 'translate(-60%, 10%)', 'translate(-80%, 10%)'],
  },
  texts: {
    gridArea: 'texts',
    flexBasis: '50%',
  },
};
