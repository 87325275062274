/** @jsx jsx */
import React from 'react';
import { HiMenu } from 'react-icons/hi';
import { Box, Flex, jsx, themeConfig } from '@themed';
import { MobileNav, SimpleNavItem } from '@core';
import { useLogoWithLink } from '@modules';
import { useAnimateHeaderToggle } from './animateHeaderToggle';
import { useAnimateNavigationOpen } from './animateNavigationOpen';
import { FloatingBackButton } from './common/FloatingBackButton';
import { styles } from './MobileHeader.styles';

interface MenuOpenButtonProps {
  isOpen: boolean;
  onOpen: (b: boolean) => void;
}

const MenuOpenButton = React.forwardRef<HTMLDivElement, MenuOpenButtonProps>(({ onOpen, isOpen }, ref) => (
  <Box sx={styles.navigationButton} onClick={() => onOpen(!isOpen)}>
    <HiMenu size={24} color={themeConfig.colors.background} />
    <Box sx={styles.bubble} ref={ref} />
  </Box>
));

export const MobileHeader = () => {
  const Logo = useLogoWithLink();

  const [isMobileMenuOpen, setIsMobileMenuOpen] = React.useState(false);
  const [isAnimationInProgress, setIsAnimationInProgress] = React.useState(false);

  const triggerRef = React.useRef(null);
  const defaultStateRef = React.useRef(null);
  const hambugerIconRef = React.useRef(null);
  const bubbleRef = React.useRef(null);
  const dropdownRef = React.useRef(null);

  useAnimateHeaderToggle({
    triggerRef: () => triggerRef,
    defaultStateRef: () => defaultStateRef,
    hiddenStateRef: () => hambugerIconRef,
  });

  useAnimateNavigationOpen({
    dropdownRef: () => dropdownRef,
    bubbleRef: () => bubbleRef,
    isMobileMenuOpen,
    emitEvent: (params) => setIsAnimationInProgress(params),
  });

  const openNavigationMenu = (arg: boolean) => {
    if (!isAnimationInProgress) {
      setIsMobileMenuOpen(arg);
    }
  };

  return (
    <Box sx={styles.display} ref={triggerRef} id='mobile-header'>
      <Box sx={styles.defaultState} ref={defaultStateRef}>
        <Logo sx={styles.logo} />
        <FloatingBackButton />
      </Box>
      <Flex sx={styles.hambugerIcon} ref={hambugerIconRef}>
        <SimpleNavItem id='contact' link='/contact' sx={styles.contactLink} />
        <MenuOpenButton isOpen={isMobileMenuOpen} onOpen={openNavigationMenu} ref={bubbleRef} />
      </Flex>
      <MobileNav ref={dropdownRef} onClose={() => openNavigationMenu(false)} />
    </Box>
  );
};
