import { RGBColor } from 'react-color';
import { SxStyleProp } from 'theme-ui';

export const styles = {
  swatch: {
    padding: '5px',
    background: '#fff',
    borderRadius: '1px',
    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
    display: 'inline-block',
    cursor: 'pointer',
  },
  popover: {
    position: 'absolute',
    zIndex: '2',
  } as SxStyleProp,
  cover: {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  } as SxStyleProp,
};

export const colorStyles = (color: RGBColor) => ({
  width: '36px',
  height: '14px',
  borderRadius: '2px',
  background: `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`,
});
