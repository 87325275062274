/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import { useLocale } from '@modules';
import { MetaQuery } from '@types';
import { formatPageMetaOgImage, usePageMetaAttributes } from './usePageMetaAttributes';
import { useSiteMeta } from './useSiteMeta';
import { SEOGenericStructuredData } from './SEOStructuredData';

interface MetaA {
  name: string;
  content: string;
  property?: undefined;
}

interface MetaB {
  property: string;
  content: string;
  name?: undefined;
}

type Meta = MetaA | MetaB;

export interface SeoProps {
  title?: string;
  description?: string;
  viewport?: string;
  author?: string;
  type?: string;
  meta?: Meta[];
}

interface StaticSeoProps {
  pageId?: string;
}

export function SEO(props: SeoProps) {
  const seoAttributes = useSiteMeta(props);
  const { htmlAttributes, title, link, meta } = usePageMetaAttributes(seoAttributes);

  return (
    <Helmet htmlAttributes={htmlAttributes} title={title} link={link} meta={meta.concat(meta || [])}>
      {process.env.NODE_ENV === 'development' && ( // If development, we don't want to show [React Intl] error messages
        <script type='text/javascript'>{`
        // eslint-disable-next-line
        const consoleError = console.error.bind(console);
        // eslint-disable-next-line
        console.error = (message, ...args) => {
          if (typeof message === 'string' && message.startsWith('[React Intl]')) {
            return;
          }
          consoleError(message, ...args);
        };
    `}</script>
      )}
    </Helmet>
  );
}

export function StaticSEO({ pageId }: StaticSeoProps) {
  const lang = useLocale();

  const { allPageMeta } = useStaticQuery<MetaQuery>(graphql`
    query {
      allPageMeta {
        edges {
          node {
            pageId
            title
            thumbnail {
              url
            }
            language {
              code
            }
            description
            viewport
          }
        }
      }
    }
  `);
  const staticPage = allPageMeta.edges.find(({ node }) => node.pageId === pageId && node.language.code === lang);

  return (
    <React.Fragment>
      <SEO
        title={staticPage?.node?.title}
        description={staticPage?.node?.description}
        viewport={staticPage?.node?.viewport}
        meta={formatPageMetaOgImage(staticPage?.node?.thumbnail?.url)}
      />
      <SEOGenericStructuredData primaryImage={staticPage?.node?.thumbnail?.url} />
    </React.Fragment>
  );
}
