import { themeConfig } from '@themed';

export const testimonialStyles = {
  list: {
    variant: 'flex.left',
    width: 'auto',
    overflow: 'visible',
  },
  item: {
    variant: 'flex.colLeft',
    flex: ['0 0 100vw', '0 0 65vw', '0 0 60vw', '0 0 50vw', '0 0 40rem'],
    '&:last-of-type': {
      flex: ['0 0 100vw', '0 0 65vw', '0 0 min-content', '0 0 min-content'],
      pr: [0, 0, 0, 6],
    },
    '.listItemImage': {
      mt: [0, -4],
      mb: [4, 5],
      width: 9,
      height: 9,
      order: 2,
    },
    '.listItemText': {
      variant: 'flex.center',
      textAlign: 'center',
      width: ['90vw', '52vw', '67vh', '55vh'],
      height: ['90vw', '52vw', '67vh', '55vh'],
      maxWidth: 11,
      maxHeight: 11,
      fontSize: ['0.875rem', 'xs', 'xs', 'xs'],

      [`@media screen and (max-height: ${themeConfig.sizes.tabletMaxWidth}) and (min-width: ${themeConfig.sizes.mobileMaxWidth})`]: {
        width: '70vh',
        height: '70vh',
      },
      [`@media screen and (max-height: ${themeConfig.sizes.mobileMaxWidth}) and (min-width: ${themeConfig.sizes.mobileMaxWidth})`]: {
        fontSize: '0.875rem',
        transition: 'transform 0.5s ease-in-out',
        transform: 'translateX(4vw)',
      },

      p: [6, 7, 7, 8],
      mb: -8,
      borderRadius: 'fullSize',
      bg: 'muted',
      order: 1,
    },
    h2: {
      fontSize: 'xs',
      order: 3,
    },
    h3: {
      fontSize: 'xs',
      fontWeight: 'normal',
      mr: [6, 7, 8, 9],
      pr: [6, 7, 8, 9],
      order: 4,
    },
  },
};
