import { graphqlRequest } from '@modules';
import { FsPeriod } from '@types';
import { formatFsTypeForGQL } from '../helpers';

interface FsPeriodsDTO {
  fsPeriods: FsPeriod[];
}

// Shall we merge getAllFsPeriods into getFsPeriodsByFsType?
export const getAllFsPeriods = async (): Promise<FsPeriod[]> => {
  try {
    const response = await graphqlRequest<FsPeriodsDTO>(`
      {
        fsPeriods (publicationState: PREVIEW){
          id
          text:fsType
          date
        }
      }
    `);
    return response.fsPeriods;
  } catch (err) {
    console.error(err);
  }
  return [{ id: '', text: '', date: '' }];
};
export const getFsPeriodsByFsType =
  (sort = 'date:desc') =>
  async (fsType: string): Promise<FsPeriod[]> => {
    try {
      const response = await graphqlRequest<FsPeriodsDTO>(`
      {
        fsPeriods(where:{fsType:"${formatFsTypeForGQL(fsType)}"}, sort:"${sort}", publicationState: PREVIEW){
          id
          text:date
          date
          published: published_at
        }
      }
    `);
      return response.fsPeriods;
    } catch (err) {
      console.error(err);
    }
    return [{ id: '', text: '', date: '' }];
  };
