/** @jsx jsx */
import { jsx, SxProps } from 'theme-ui';
import React from 'react';
import { SvgIcon, SectionWithContent, CompactSectionContent, CompactSectionContentStyles } from '@core';
import { ChildrenProps, OptionalTriggerElementProps } from '@types';
import { useAnimationContact } from './animateContact';

interface ContactProps extends ChildrenProps, OptionalTriggerElementProps, SxProps {
  id: string;
  variant?: string;
}

// shall we inject other `props`?
export const ContactSection = React.forwardRef<HTMLDivElement, ContactProps>(({ id, children, triggerRef, variant, sx, ...props }, ref) => {
  useAnimationContact({
    triggerRef,
  });

  return (
    <SectionWithContent
      ref={ref}
      {...props}
      sx={{
        zIndex: 121,
        position: 'relative',
        bg: 'primary',
        ...sx,
      }}
      id='contact'
    >
      <CompactSectionContentStyles sx={{ variant: variant || 'grids.ContactScene' }}>
        <CompactSectionContent id={id} buttonVariant='ctaInvert' titleSx={{ color: 'background' }}>
          {children || <SvgIcon name='podium' sx={{ variant: 'images.pureCircleIcon', p: 2 }} />}
        </CompactSectionContent>
      </CompactSectionContentStyles>
    </SectionWithContent>
  );
});
