/** @jsx jsx */
import React from 'react';
import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/all';
import { jsx, Box, Button } from '@themed';
import { SectionWithContent, CompactSectionContent, CompactSectionContentStyles, useSmoothScrollbar } from '@core';
import { useAnimationBlogScrollerMask } from './animateBlogScrollerMask';
import { styles } from './BlogHeader.styles';

export const BlogHeader = () => {
  const sectionRef = React.useRef<HTMLDivElement>(null);
  const backgroundRef = React.useRef<HTMLDivElement>(null);
  const contentRef = React.useRef<HTMLDivElement>(null);
  const scrollBar = useSmoothScrollbar();

  useAnimationBlogScrollerMask({
    triggerRef: () => sectionRef,
    animatedElementRef: () => backgroundRef,
    contentRef: () => contentRef,
  });

  const scrollToBottom = () => {
    const newsletter = document.querySelector('#contact');
    if (window && scrollBar && newsletter) {
      const diff = newsletter.getBoundingClientRect().y - scrollBar.scrollTop;
      scrollBar.scrollTo(undefined, diff, 1500);
    } else if (window) gsap.to(window, { duration: 1.5, ease: 'power4', scrollTo: '#contact' });
  };

  React.useEffect(() => {
    gsap.registerPlugin(ScrollToPlugin);
  }, []);

  return (
    <SectionWithContent id='blog.header' sx={styles.container} ref={sectionRef}>
      <Box sx={styles.background} ref={backgroundRef} />
      <Button onClick={scrollToBottom} variant='pure' sx={styles.button}>
        <CompactSectionContentStyles ref={contentRef}>
          <CompactSectionContent titleAs='h1' id='blog.header' buttonVariant='ctaInvert' />
        </CompactSectionContentStyles>
      </Button>
    </SectionWithContent>
  );
};
