import React, { useCallback, useState } from 'react';
import axios from 'axios';
import { SubmitHandler, useForm } from 'react-hook-form';
import { navigate } from 'gatsby';
import { Button, Checkbox, Input, Label } from 'theme-ui';
import { useRecoilState } from 'recoil';
import { useLocale, getStrapiRoutes } from '@modules';
import { IFormInput, StrapiResponse } from '@types';
import { authState } from '@store';
import { ErrorMessage } from './ErrorMessage';

export const LoginForm = () => {
  const [, setUser] = useRecoilState(authState);
  const [loginError, setLoginError] = useState('');
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInput>();
  const locale = useLocale();

  const onSubmit: SubmitHandler<IFormInput> = useCallback(async (data) => {
    setLoginError('');
    try {
      const res: StrapiResponse = await axios.post(getStrapiRoutes().cms.login, {
        email: data.email,
        password: data.password,
      });

      if (res.data.data) {
        setUser({ token: res.data.data.token, user: res.data.data.user });
        navigate(`/${locale}/charts/`);
      }
    } catch (err: any) {
      const errorMessage = err?.response?.data?.message || 'An error occurred during login. Please try again.';
      setLoginError(errorMessage);
    }
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)} id='form'>
      <Label>
        Email
        <Input {...register('email')} type='email' placeholder='your.name@finnoconsult.at' />
      </Label>
      {errors.email && <ErrorMessage>Email is required</ErrorMessage>}

      <Label>
        Password
        <Input {...register('password')} type='password' />
      </Label>
      {errors.password && <ErrorMessage>Password is required</ErrorMessage>}

      <Label className='rememberMe'>
        <Checkbox />
        Remember me
      </Label>

      <Button type='submit'>Log in</Button>
      <ErrorMessage>{loginError}</ErrorMessage>
    </form>
  );
};
