import React from 'react';
import { CompactSectionContentStyles, Flex, SubTitle, Paragraph, Grid } from '@core';
import { CaseStudyTexts, ChildrenProps, ThumbnailProps } from '@types';
import { Box, SxProps } from '@themed';
import { useAnimationCustomScroll } from './animateCustomScroll';
import { getAnimation } from './animationForCards';
import { Thumbnail } from './Thumbnail';
import { referenceStyles } from './Reference.styles';

export interface ReferenceProps extends CaseStudyTexts, ThumbnailProps, ChildrenProps, SxProps {
  className?: string;
}

export const Reference = ({ title, teaser, thumbnails, children, ...props }: ReferenceProps) => {
  const ref = React.useRef(null);
  const asideRef = React.useRef(null);

  const animationFunction = getAnimation(title);

  useAnimationCustomScroll({
    triggerRef: () => asideRef,
    animation: () => animationFunction(() => asideRef),
  });

  return (
    <CompactSectionContentStyles key={title} {...props} sx={{ ...referenceStyles.section, ...props.sx }} ref={ref}>
      <Flex id='reference-info' sx={referenceStyles.content} data-cy='finnoscore-casestudies-text'>
        <Box>
          <SubTitle sx={referenceStyles.title}>{title}</SubTitle>

          <Paragraph as='p' sx={referenceStyles.paragraph}>
            {teaser}
          </Paragraph>
        </Box>

        {children}
      </Flex>

      <Grid as='aside' sx={referenceStyles.aside} ref={asideRef}>
        <Thumbnail thumbnails={thumbnails} />
      </Grid>
    </CompactSectionContentStyles>
  );
};
