/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import { Helmet } from 'react-helmet';
import { SiteMeta } from '@types';
import { useGenericSchema } from './useSeoSchema';
import { useSiteMeta } from './useSiteMeta';

export interface SeoStructuredDataProps {
  schema: Record<string, any>;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function SEOStructuredData({ schema }: SeoStructuredDataProps) {
  const minifiedSeoSchemaInfo = JSON.stringify(schema).replace(/\s(?=[\s":{}])/g, '');

  return (
    <Helmet>
      <script type='application/ld+json' id='second-helmet'>
        {minifiedSeoSchemaInfo}
      </script>
    </Helmet>
  );
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function SEOGenericStructuredData(props?: Pick<Partial<SiteMeta>, 'primaryImage'>) {
  const seoAttributes = useSiteMeta(props);
  const schema = useGenericSchema(seoAttributes);

  return <SEOStructuredData schema={schema} />;
}
