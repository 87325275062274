import React from 'react';
import { Box } from '@themed';
import { styles } from './Tag.styles';

interface TagProps {
  title?: string;
}

// TODO: Link to display all article with this tag
export const Tag = ({ title }: TagProps) => <Box sx={styles.tag}>{title}</Box>;
