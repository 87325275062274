import * as React from 'react';

const SvgHandshake = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={74} height={58} viewBox='0 0 74 58' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g id='handshake_svg__Page-1' stroke='none' strokeWidth={1} fill='none' fillRule='evenodd'>
      <g id='handshake_svg__\xDCberuns-6' transform='translate(-697 -238)'>
        <g id='handshake_svg__Group-13-Copy' transform='translate(697 238)'>
          <path
            d='M38.593 30.34a1.75 1.75 0 012.351.113l1.203 1.203 1.887 1.886 3.33 3.33 1.444 1.443.795.795.977.977 2.029 2.028c.288.29.553.553.792.793l.528.527c2.57-.497 3.481-2.758 3.25-4.257l3.229-.053c.121 2.7-1.31 6.272-5.374 7.373-.378 2.182-2.412 5.451-6.794 5.649-2.048 4.536-7.055 5.314-10.307 3.068-1.563 1.797-6.174 3.955-9.809.32l-2.528-2.527 2.092-2.458 2.712 2.712c1.18 1.18 3.617 1.588 5.294-.383l-5.922-5.924a1.751 1.751 0 010-2.476l.125-.114a1.75 1.75 0 012.35.114l8.543 8.543c1.927.323 3.377-.224 4.45-2.048L34.086 39.82a1.751 1.751 0 012.477-2.476l11.579 11.579c1.744.017 3.273-1.268 3.616-2.705l-4.077-4.077-1.245-1.244-.538-.538-2.705-2.705-.664-.664-1.76-1.76-1.51-1.511-.79-.79a1.751 1.751 0 01-.001-2.476zM11.191.145L27.97 5.575 22.98 7.34 11.706 3.694l-.017.011-.049.035c-.819.593-8.982 6.808-7.938 18.341.062.683.462 1.489 1.091 2.365-1.285.498-2.178 1.245-2.662 1.795-1.214-1.707-1.546-2.918-1.63-3.87C-.395 12.274 5.004 3.993 11.19.145z'
            id='handshake_svg__Combined-Shape'
            fill='#002938'
          />
          <path
            d='M73.919 29.823S65.583 12.098 61.5 8.38l-9.98 2.758-11.793-4.346c-1.71-.634-3.094-.901-5.538-.171l-13.112 4.643c-1.98.884-3.297 3.41-2.227 5.768.836 1.845 3.846 2.732 8.4 2.732 2.892 0 6.406-.357 10.382-1.056L56.39 36.06c5.272.585 13.553-.947 17.53-6.237'
            id='handshake_svg__Fill-5'
            fill='#00BD59'
          />
          <path
            d='M18.722 52.166c1.313.74 2.848.783 4.278-.847l2.046-2.334c1.27-1.614.98-3.794-.8-5.358-.439-.386-1.75-1.254-3.498-.752 1.312-1.421 2.08-3.529.489-5.416-.96-1.138-2.379-2.145-4.307-1.548 1.137-1.493 1.322-3.815-.764-5.575-1.132-.956-3.491-1.233-4.65-.31a4.22 4.22 0 00-1.49-2.312c-1.801-1.443-4.274-.69-5.546.504l-1.312 1.398c-1.304 1.66-.89 2.744-.07 4.826 2.66 6.767 9.906 14.495 15.624 17.724'
            id='handshake_svg__Fill-7'
            fill='#00BD59'
          />
        </g>
      </g>
    </g>
  </svg>
);

export default SvgHandshake;
