/** @jsx jsx */
import { copyTextToClipboard, downloadAsPng, saveToClipboard, useTranslate } from '@modules';
import { CTAButton, InfoButton } from '@themed';
import { BoxProps, Flex, jsx } from 'theme-ui';

interface Props extends BoxProps {
  copyChart?: () => void;
  downloadChart?: () => void;
  copyText?: () => void;
}

type Action = 'download' | 'copy';
type CopyType = 'chart' | 'legend';
interface ExportButtonProps {
  type: CopyType;
  action?: Action;
  element: HTMLDivElement | null | undefined;
  name?: string;
}

const getAction = (action: Action = 'download', type: CopyType) => {
  switch (action) {
    case 'copy':
      return type === 'legend' ? copyTextToClipboard : saveToClipboard;
    default:
    case 'download':
      return downloadAsPng;
  }
};

function ucFirst(text: string) {
  return text.charAt(0).toUpperCase() + text.slice(1);
}

export const ExportButton = ({ type, action = 'download', name, element }: ExportButtonProps) => {
  const __ = useTranslate();
  const Component = type === 'chart' ? CTAButton : InfoButton;
  const filename = `${name ? `${name.replace(/\s/gi, '_')}.` : ''}${type}`;
  return element ? (
    <Component sx={{ fontSize: '0.875rem' }} onClick={() => getAction(action, type)(element, filename)}>
      {__(`${ucFirst(action)} ${type}`)}
    </Component>
  ) : null;
};

export const ExportButtons = ({ copyChart, downloadChart, copyText, ...props }: Props) => {
  const __ = useTranslate();

  return (
    <Flex {...props}>
      {copyChart && <CTAButton onClick={copyChart}>{__('Copy chart')}</CTAButton>}
      {downloadChart && <CTAButton onClick={downloadChart}>{__('Download chart')}</CTAButton>}
      {copyText && <InfoButton onClick={copyText}>{__('Copy text')}</InfoButton>}
    </Flex>
  );
};
