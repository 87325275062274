export const arrowStyles = (isOpen: boolean) => ({
  width: '20px',
  height: '20px',
  transform: isOpen ? 'rotate(180deg)' : '',
  transition: 'transform 0.2s',
});

export const defaultDropDownHeaderStyles = {
  header: {
    height: '50px',
    px: 5,
  },
  title: {
    fontSize: 'xxsImportant',
    pl: 4,
  },
  titleArea: {
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
};

export const styles = {
  box: (isOpen: boolean) => ({
    width: ['100%', '100%', 'auto'],
    height: '50px',
    variant: 'flex.col',
    justifyContent: 'flex-start',
    zIndex: isOpen ? 'popup' : 1,
    overflow: 'visible',
    flex: '0 0 auto',
  }),
  body: {
    variant: 'flex.col',
    width: '100%',
    height: 'auto',
    backgroundColor: ['muted', 'muted', 'background'],
    borderRadius: '25px',
  },
  header: {
    width: '100%',
    height: '100%',
  },
  content: {
    width: '100%',
  },
};
