import React from 'react';
import { Box, ImageLoader } from '@core';
import { Image, ThumbnailProps } from '@types';
import CardShadow from '../../../images/rapid/references/adac/Shadow';
import { imageStyles } from './Thumbnail.styles';

interface MultipleThumbnailProps {
  thumbnails: Image[];
}
const MultipleThumbnail = ({ thumbnails }: MultipleThumbnailProps) => (
  <React.Fragment>
    {thumbnails.map((image) => (
      <Box key={image.url} sx={imageStyles.imageContainer} className='cards'>
        <ImageLoader src={image.url} alt={image.alternativeText} sx={imageStyles.multipleImages(thumbnails.length)} key={image.url} />
      </Box>
    ))}
    <Box sx={imageStyles.shadowContainer} className='shadow'>
      <CardShadow sx={imageStyles.shadow} />
    </Box>
  </React.Fragment>
);

interface SimpleThumbnailProps {
  thumbnail: Image;
}
const SimpleThumbnail = ({ thumbnail }: SimpleThumbnailProps) => (
  <Box key={thumbnail.url}>
    <ImageLoader src={thumbnail.url} alt={thumbnail.alternativeText} key={thumbnail.url} />
  </Box>
);

const isImageList = (imageOrList?: Image[] | Image): imageOrList is Image[] =>
  typeof imageOrList === 'object' && imageOrList.constructor.name === 'Array';

export const Thumbnail = ({ thumbnails }: ThumbnailProps) => {
  if (isImageList(thumbnails) && thumbnails.length > 1) return <MultipleThumbnail thumbnails={thumbnails} />;
  if (isImageList(thumbnails)) return <SimpleThumbnail thumbnail={thumbnails[0]} />;
  if (thumbnails) return <SimpleThumbnail thumbnail={thumbnails} />;
  return null;
};
