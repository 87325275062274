import React from 'react';
import { gsap } from 'gsap';
import { __el } from '@modules';
import { cleanupTimeline } from '@core';

interface AnimateNavigationOpenProps {
  dropdownRef: () => React.RefObject<HTMLDivElement>;
  bubbleRef: () => React.RefObject<HTMLDivElement>;
  isMobileMenuOpen: boolean;
  emitEvent: (arg: boolean) => void;
}

export const animateNavigationOpen = ({ dropdownRef, bubbleRef, isMobileMenuOpen, emitEvent }: AnimateNavigationOpenProps) => {
  const handleEvent = (arg: boolean) => {
    emitEvent(arg);
  };

  const menuOpenAnimation = gsap
    .timeline()
    .pause()
    .to(
      __el(bubbleRef),
      {
        visibility: 'visible',
        duration: 0,
        onStart: () => {
          handleEvent(true);
        },
      },
      0
    )
    .to(
      __el(bubbleRef),
      {
        duration: 0.5,
        scale: 100,
      },
      0
    )
    .to(
      __el(dropdownRef),
      {
        duration: 0,
        visibility: 'visible',
      },
      0.5
    )
    .to(
      __el(bubbleRef),
      {
        duration: 0,
        visibility: 'hidden',
        onComplete: () => {
          handleEvent(false);
        },
      },
      0.5
    );

  const menuCloseAnimation = gsap
    .timeline()
    .pause()
    .to(
      __el(dropdownRef),
      {
        duration: 0,
        visibility: 'hidden',
        onStart: () => {
          handleEvent(true);
        },
      },
      0
    )
    .to(
      __el(bubbleRef),
      {
        duration: 0,
        visibility: 'visible',
      },
      0
    )
    .to(
      __el(bubbleRef),
      {
        duration: 0.5,
        scale: 1,
      },
      0
    )
    .to(
      __el(bubbleRef),
      {
        visibility: 'hidden',
        duration: 0,
        onComplete: () => {
          handleEvent(false);
        },
      },
      0.5
    );

  if (isMobileMenuOpen) {
    menuOpenAnimation.play();
  } else {
    menuCloseAnimation.play();
  }

  return () => {
    cleanupTimeline(menuOpenAnimation);
    cleanupTimeline(menuCloseAnimation);
  };
};

export const useAnimateNavigationOpen = (props: AnimateNavigationOpenProps) =>
  React.useEffect(() => animateNavigationOpen(props), [props.isMobileMenuOpen]);
