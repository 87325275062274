import * as React from 'react';

const SvgNn = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={30} height={15} viewBox='0 0 30 15' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g id='nn_svg__nn' stroke='none' strokeWidth={1} fill='#00BD59' fillRule='nonzero'>
      <g id='nn_svg__n'>
        <path d='M8.8 0L4 7.7l-4 6.6v.5h3.5l5.7-9L12.7 0h-4 .1zM0 0v13.4L3.3 8V0H0zm13 1L9.5 6v9H13V.8 1z' id='nn_svg__Shape' />
      </g>
      <g id='nn_svg__n' transform='translate(17)'>
        <path d='M8.8 0L4 7.7l-4 6.6v.5h3.5l5.7-9L12.7 0h-4 .1zM0 0v13.4L3.3 8V0H0zm13 1L9.5 6v9H13V.8 1z' id='nn_svg__Shape' />
      </g>
    </g>
  </svg>
);

export default SvgNn;
