import React from 'react';
import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';
import { ChildrenProps, FS_TYPES, FsType } from '@types';
import { getTypeFromPath } from '@config';

interface SelectedFsTypeContextProps {
  type: FsType;
  setType: (type: FsType) => void;
}

export const SelectedFsTypeContext = React.createContext({} as SelectedFsTypeContextProps);

export const useSelectedFsType = () => {
  const { type, setType } = React.useContext(SelectedFsTypeContext);
  return [type, setType] as const;
};

export const useIsBankMiddleEastType = () => {
  const [type] = useSelectedFsType();
  return type === FS_TYPES[1];
};

interface ProviderProps extends ChildrenProps {}

export const SelectedFsTypeContextProvider = ({ children }: ProviderProps) => {
  const { pathname, search } = useLocation();
  const type = getTypeFromPath(`${pathname}${search}`);

  const setTypeAndNavigate = (newType: FsType) => {
    navigate(`${newType}`);
  };

  return <SelectedFsTypeContext.Provider value={{ type, setType: setTypeAndNavigate }}>{children}</SelectedFsTypeContext.Provider>;
};
