import { ALL_COUNTRIES } from '@modules';
import { SxStyleProp } from 'theme-ui';

export const itemBg = {
  backgroundColor: 'muted',
  borderRadius: '5px',
};

export const commonStyles = {
  box: {
    py: 2,
    px: 4,
    display: 'flex',
    width: '100%',
  },
  tick: {
    width: '24px',
    height: 'auto',
  },
  hover: {
    '&:hover': {
      ...itemBg,
    },
  },
  icon: {
    height: '10px',
    objectFit: 'fill',
    width: 4,
    mr: 3,
  } as SxStyleProp,
};

export const countryListItemStyles = {
  box: (selected?: boolean) => {
    const bgStyles = selected ? itemBg : {};

    return {
      ...commonStyles.box,
      ...commonStyles.hover,
      ...bgStyles,
    };
  },
  textWithSelection: {
    flexGrow: 1,
    justifyContent: 'space-between',
  },
};

export const countryCalculationListItemStyles = {
  box: (selected?: boolean) => {
    const bgStyles = selected ? itemBg : {};

    return {
      ...commonStyles.box,
      ...bgStyles,
      '&:nth-of-type(2n)': {
        mb: 3,
      },
    };
  },
  titleArea: (id?: string) =>
    String(id).match(ALL_COUNTRIES)
      ? {
          width: '28px',
        }
      : {
          width: '60px',
        },
  title: (id?: string) =>
    String(id).match(ALL_COUNTRIES)
      ? {
          display: 'none',
        }
      : {
          fontWeight: 'bold',
        },
  textArea: {
    width: '100%',
    justifyContent: 'space-between',
  },
};

export const institutionListItemStyles = {
  item: (selected?: boolean) => {
    const bgStyles = selected ? itemBg : {};

    return {
      my: 1,
      py: 2,
      pl: selected ? 0 : 6,

      display: 'flex',
      flexDireciton: 'row',
      alignItems: 'center',
      width: '100%',
      ...bgStyles,
      ...commonStyles.hover,
    };
  },
  tick: {
    ...commonStyles.tick,
    marginRight: '8px',
  },
};
