import { CaseStudyFromApi, CaseStudy, Locale, CaseStudyBase } from '@types';
import { ContentTypes } from '../../post';
import { formatContent, formatImages, formatThumbnails, getMainResource } from '../commonFormatting';

const getBasicDataForCaseStudy = (caseStudy: CaseStudyFromApi): CaseStudyBase => ({
  slug: caseStudy.slug,
  title: caseStudy.title,
  subtitle: caseStudy.subtitle,
  teaser: caseStudy.teaser,
  supportScope: caseStudy.supportScope,
  covers: caseStudy.covers && formatImages(caseStudy.covers),
  thumbnails: caseStudy.thumbnails && formatThumbnails(caseStudy.thumbnails),
  published: caseStudy.published,
  publishDate: caseStudy.publishDate,
  content: formatContent(caseStudy.content),
});

const getAdditionalDataForCaseStudy = (caseStudy: CaseStudyFromApi) => ({
  covers: caseStudy.covers && formatImages(caseStudy.covers),
  thumbnails: caseStudy.thumbnails && formatThumbnails(caseStudy.thumbnails),
  site: caseStudy?.de?.site || caseStudy.site || '',
  slugs: {
    de: `/${ContentTypes.casestudy}/${caseStudy.slug}`,
    en: `/${ContentTypes.casestudy}/${caseStudy.en?.slug || caseStudy.slug}`,
    fr: `/${ContentTypes.casestudy}/${caseStudy.fr?.slug || caseStudy.slug}`,
  },
});

export const formatCaseStudy = (caseStudy: CaseStudyFromApi, locale: Locale, mainCaseStudies?: CaseStudyFromApi[]): CaseStudy => {
  if (locale === 'de') {
    return {
      language: locale,
      ...getBasicDataForCaseStudy(caseStudy),
      ...getAdditionalDataForCaseStudy(caseStudy),
    };
  }

  const germanCaseStudy = getMainResource(caseStudy.slug, locale, mainCaseStudies);
  if (!germanCaseStudy) throw new Error(`Non-German case studies must have a main (German) case study! (${caseStudy.slug})`);
  return {
    language: locale,
    ...getBasicDataForCaseStudy(caseStudy),
    ...getAdditionalDataForCaseStudy(germanCaseStudy),
  };
};

export const formatCaseStudies = (caseStudies: CaseStudyFromApi[], locale: Locale, mainCaseStudies?: CaseStudyFromApi[]) =>
  caseStudies.map((study) => formatCaseStudy(study, locale, mainCaseStudies));
