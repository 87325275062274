export const styles = {
  header: {
    alignItems: 'center',
    height: ['1px', '1px', '50px'],
    mx: [4, 4, 0],
    width: ['auto', 'auto', 'fullSize'],
    overflow: 'hidden',
    borderBottom: 'thin',
    borderBottomColor: 'mutedUi',
    '&:hover': {
      bg: 'initial',
    },
    '&:before': {
      content: '""',
      display: 'none',
    },
  },
  cell: {
    variant: 'styles.fsText',
    color: 'grey.1',
    fontSize: '0.875rem',
    fontWeight: 'bold',
    width: '100%',
    textAlign: 'center',
  },
};
