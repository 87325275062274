import React, { useState } from 'react';
import { SketchPicker } from 'react-color';
import { themeConfig as theme } from '@themed';

interface BasicColorPickerProps {
  presetColors?: string[];
}

export const defaultColors = [
  theme.colors.text,
  theme.colors.background,
  theme.colors.primary,
  theme.colors.secondary,
  theme.colors.secondary2,
  theme.colors.border,
  theme.colors.muted,
  theme.colors.mutedDark,
  theme.colors.mutedUi,
  theme.colors.warning,
  theme.colors.black50Percent,
  theme.colors.white50Percent,
];

export const BasicColorPicker = ({ presetColors = defaultColors }: BasicColorPickerProps) => {
  const [color, setColor] = useState(defaultColors[2]);

  return (
    <SketchPicker presetColors={presetColors} color={color} onChangeComplete={(col) => setColor(col.hex)} onChange={(col) => setColor(col.hex)} />
  );
};
