/* eslint-disable camelcase */
import { CountryFromApi, Translation } from '@types';
import { ALL_COUNTRIES } from '../../formatting/finnoscore/formatCountries';
import { getTranslationsQuery } from '../queries';
import { graphqlRequest } from '../graphqlRequest';
import { FetchPeriodsProps } from '..';
import { applyAllStrapiQueryParams, applyStrapiQueryDate, FormatParam, QueryTemplate, strapiQueryBuilder } from './strapiQueryBuilder';

interface WithData {
  fs_institutions: { id: string }[];
  fs_country_calculations: { id: string }[];
}

export interface CountriesQueryResult {
  totalCountries: (CountryFromApi & WithData)[];
  fsCountries: (CountryFromApi & WithData)[];
  translations: Translation[];
}

export const useCompanyFilters = (filters?: FetchPeriodsProps) => {
  if (filters) {
    const { fsType, date, fsPeriod } = filters;
    const institutionQueryTemplate: QueryTemplate = ({ extraFlags, where }) =>
      `${extraFlags}${where ? `, where: { fs_institutions: { ${where} } fs_country_calculations: { ${where} } }` : ''}`;
    const fsPeriodDateTemplate: FormatParam = (where, value, name) =>
      `${where ? `${where}, ` : ''}${value ? `fs_period: { ${name}: "${value}" ${fsPeriod ? `id: "${fsPeriod}"` : ''}}` : ''}`;
    const where = strapiQueryBuilder(
      '',
      [...applyAllStrapiQueryParams({ fsType }), applyStrapiQueryDate('date_lte', date, fsPeriodDateTemplate)],
      institutionQueryTemplate
    );
    return where;
  }
  return '';
};

export const fetchCountries = async (filters?: FetchPeriodsProps) => {
  const where = useCompanyFilters(filters);

  // TODO: simplify This filtering by retrieving all fsCountries & filter out unnecessary ALL_COUNTRIES.
  const query = `{
    totalCountries:fsCountries(limit: 1, where: { countryCode_contains:"${ALL_COUNTRIES}" }) {
      countryCode
      flag {
        url
        alternativeText
      }
    }
    fsCountries(sort: "created_at:desc"${where}) {
      countryCode
      flag {
        url
        alternativeText
      }
    }
    ${getTranslationsQuery()}
  }`;

  try {
    const response = await graphqlRequest<CountriesQueryResult>(query);
    const countriesWithData = response.totalCountries.concat(response.fsCountries.filter((country) => country.countryCode !== ALL_COUNTRIES));

    const countries = countriesWithData.map((country) => ({
      countryCode: country.countryCode,
      flag: country.flag,
    }));

    return {
      countries,
      translations: response.translations,
    };
  } catch (error) {
    throw new Error(`Fetching countries failed: ${error}`);
  }
};
