import React from 'react';
import { Flex, Box, Select } from 'theme-ui';
import ReactCountryFlag from 'react-country-flag';
import { changeLocale, useLocale, useAllLanguages, formatSEOLink, useTranslate } from '@modules';
import { Locale } from '@types';
import { SlugContext } from '../layout/SlugContext';
import { useIsWidescreen } from '../themed/ThemedHelper';
import { styles } from './LanguageSelector.styles';

export const LanguageSelector = () => {
  const currentLocale = useLocale();
  const getAllLocales = useAllLanguages();
  const __ = useTranslate();

  const slugs = React.useContext(SlugContext);

  const onLanguageChange = (locale: Locale) => {
    changeLocale(locale, formatSEOLink(slugs[locale]));
  };

  const getCountryFlag = (code: string) => {
    switch (code) {
      case 'en':
        return 'gb';
      case 'fr':
        return 'fr';
      default:
        return 'at';
    }
  };

  const isWideScreen = useIsWidescreen();

  return (
    <Flex variant='flex.center'>
      <Box sx={styles.flagContainer}>
        <ReactCountryFlag countryCode={getCountryFlag(currentLocale)} alt={__(`languages.${currentLocale}`)} svg style={styles.flag} />
      </Box>
      <Box sx={styles.selectContainer}>
        <Select
          variant='languageSelector'
          onChange={(e) => onLanguageChange(e.target.value as Locale)}
          defaultValue={currentLocale}
          sx={styles.selectTitle}
        >
          {getAllLocales.map((locale) => (
            <option key={`${locale.id}_${locale.name}`} value={locale.id}>
              {isWideScreen ? locale.name : locale.id}
            </option>
          ))}
        </Select>
      </Box>
    </Flex>
  );
};
