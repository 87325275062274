import React from 'react';
import { SvgIcon, SectionWithContent, ThemedIcon } from '@core';
import { ContactSection } from '../ContactSection';
import { WhoWeAre } from './WhoWeAre';
import { WeMakeYouBetter } from './WeMakeYouBetter';
import { Founders } from './Founders';
import { OurPhilosophy } from './OurPhilosophy';
import Video from './Video';

import { useAnimationFounders } from './animateFoundersToPhilosophy';

export const AboutUsPage = () => {
  const foundersRef = React.useRef(null);
  const philosophyRef = React.useRef(null);
  const videoRef = React.useRef(null);

  useAnimationFounders({
    founderBox: () => foundersRef,
    philosophyBox: () => philosophyRef,
  });

  return (
    <React.Fragment>
      <WhoWeAre />
      <WeMakeYouBetter />
      <Founders ref={foundersRef} />
      <OurPhilosophy ref={philosophyRef} />
      <SectionWithContent ref={videoRef} data-cy='about-us-video'>
        <Video />
      </SectionWithContent>
      <ContactSection id='aboutus.contact' triggerRef={() => videoRef}>
        <ThemedIcon sx={{ variant: 'images.pureCircleIcon' }}>
          <SvgIcon name='handshake' />
        </ThemedIcon>
      </ContactSection>
    </React.Fragment>
  );
};
