/** @jsx jsx */
import { jsx } from 'theme-ui';
import { forwardRef } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image/withIEPolyfill';
import { ImageProps, ImageEdgesType } from '@types';
import { StaticImage } from './StaticImage';

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

type PreloadType = 'tiny' | 'original';

interface ImageLoaderProps extends ImageProps {
  preload?: PreloadType;
}

const getPreloadProp = (type: PreloadType) => {
  if (type === 'original') return 'src';
  return 'base64';
};

export const ImageLoader = forwardRef<HTMLDivElement, ImageLoaderProps>(
  ({ src = 'cards.jpg', alt, objectFit = 'contain', preload = 'tiny', ...props }, ref) => {
    if (src.match(/^(http[s]:\/\/)|\/static/)) {
      // NOTE: this is not Gatsby image, just a simple powered by Theme-ui
      return <StaticImage {...props} src={src} alt={alt} sx={{ objectFit, ...props.sx }} />;
    }

    const data = useStaticQuery(graphql`
      query {
        allImageSharp {
          edges {
            node {
              fluid(maxWidth: 1200, quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    `);

    const re = new RegExp(`/${src.replace(/^\//, '')}$`);
    // NOTE: original src is not available here
    const source = (data.allImageSharp.edges as ImageEdgesType[])
      // Match string after final slash
      .find((element) => element.node.fluid.src.match(re));

    if (!source?.node?.fluid) {
      console.error('image not found!', `"${src}"`);
      return null;
    }

    const ratioClass = ((ratio = source?.node?.fluid?.aspectRatio) => {
      if (ratio === 1) return 'square';
      if (ratio < 1) return 'portrait';
      return 'landscape';
    })();

    const parallaxClass = ((ratio = source?.node?.fluid?.aspectRatio) => {
      if (ratio >= 0.8) return 'parallax-resize';
      return 'parallax-ok';
    })();

    return (
      <Img
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ref={ref as any}
        fluid={{ ...source.node.fluid, base64: source.node.fluid?.[getPreloadProp(preload)] || source.node.fluid.base64 }}
        objectFit={objectFit}
        alt={alt}
        {...props}
        className={`${ratioClass} ${parallaxClass} ${props.className || ''} aspect-${Math.round(source?.node?.fluid?.aspectRatio * 10) / 10}`}
        data-aspectratio={source?.node?.fluid?.aspectRatio}
        sx={{
          width: '100%',
          ...props.sx,
        }}
      />
    );
  }
);
