import { blogConfig } from '../config/blog';

export enum ContentTypes {
  blog = 'blog',
  author = 'author',
  tag = 'tag',
  casestudy = 'case-study',
}

type ContentType = ContentTypes.blog | ContentTypes.author | ContentTypes.tag | ContentTypes.casestudy;

// type ContentType = 'case-study' | 'blog' | 'author' | 'tag';

export const getContentTypeUrl = (slug?: string, locale?: string, contentType?: ContentType) =>
  `${blogConfig.blogPublicBaseUrl || ''}/${locale}/${contentType}/${slug}/`;

export const getLocalSlug = (contentType: ContentType, slug?: string) => `/${contentType}/${slug}`;
