import React from 'react';
import { ChildrenProps, Institution, Country, CountryCalculationType, ComparisonSubjectType } from '@types';

interface SelectedCountryContextProps {
  country: Country;
  setCountry: (country: Country) => void;
}

export const SelectedCountryContext = React.createContext({} as SelectedCountryContextProps);

export const useSelectedCountry = () => {
  const { country } = React.useContext(SelectedCountryContext);
  return country;
};

interface ProviderProps extends ChildrenProps {
  defaultValue: Country;
}

export const SelectedCountryContextProvider = ({ children, defaultValue }: ProviderProps) => {
  const [country, setCountry] = React.useState(defaultValue);

  return <SelectedCountryContext.Provider value={{ country, setCountry }}>{children}</SelectedCountryContext.Provider>;
};

export const getComparisonQueryParams = (institution: Institution) => {
  const compareA = `${ComparisonSubjectType.CountryCalculation}.${institution.country.countryCode}.${CountryCalculationType.Average}`;
  const compareB = `${ComparisonSubjectType.Institution}.${institution.country.countryCode}.${institution.name}`;

  return [compareA, compareB];
};
