import * as React from 'react';

const SvgPlay = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={65} height={65} viewBox='0 0 65 65' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g id='play_svg__Page-1' stroke='none' strokeWidth={1} fill='none' fillRule='evenodd'>
      <g id='play_svg__play' fill='#00BD59' fillRule='nonzero'>
        <path
          d='M32.5 0C14.55 0 0 14.567 0 32.5 0 50.45 14.567 65 32.5 65 50.45 65 65 50.433 65 32.5 65 14.55 50.433 0 32.5 0zm-8.667 22.028v20.94c0 1.387 1.497 2.256 2.701 1.568l18.337-10.47c1.214-.693 1.214-2.443 0-3.136L26.534 20.46c-1.204-.687-2.7.182-2.7 1.568zM32.5 3.61c15.94 0 28.889 12.935 28.889 28.889 0 15.94-12.935 28.889-28.889 28.889-15.94 0-28.889-12.935-28.889-28.889 0-15.94 12.935-28.889 28.889-28.889z'
          id='play_svg__Path-Copy-3'
        />
      </g>
    </g>
  </svg>
);

export default SvgPlay;
