import React, { useRef } from 'react';
import { Box, Grid, Paragraph, ParallaxImage, SectionWithContent, Title, CompactSectionContentStyles } from '@core';
import { useTranslate } from '@modules';
import { styles } from './WeMakeYouBetter.styles';

export const WeMakeYouBetter = () => {
  const weMakeYouBetterRef = useRef(null);
  const __ = useTranslate();

  return (
    <SectionWithContent ref={weMakeYouBetterRef} id='init' data-cy='we-make-you-better' sx={{ bg: 'background' }}>
      <CompactSectionContentStyles>
        <Grid sx={styles.containerRow} data-cy='we-make-you-better-container'>
          <ParallaxImage sx={styles.topImage} src='phone.png' alt={__('pages.aboutus.flexible.parallaxImgAlt.0')} />
          <Box sx={styles.texts} data-cy='we-make-you-better-texts'>
            <Title>{__('pages.aboutus.flexible.title')}</Title>
            <Paragraph>{__('pages.aboutus.flexible.paragraph')}</Paragraph>
          </Box>
          <ParallaxImage sx={styles.bottomImage} src='teo.png' alt={__('pages.aboutus.flexible.parallaxImgAlt.1')} />
        </Grid>
      </CompactSectionContentStyles>
    </SectionWithContent>
  );
};
