import { getStrapiRoutes, graphqlRequest } from '@modules';
import { Dimension, FsType, UpdatedInstitution } from '@types';
import axios from 'axios';

export interface UpdateResponse {
  status: number;
  data:
    | {
        updateFsInstitutions: {
          fsInstitution: {
            id: number;
            dimensions: Dimension[];
          };
        };
      }
    | string;
  link?: string;
}
interface AxiosJsonError {
  message: string;
  status: number;
}

export const updateInstitutionMutation = (fsInstitutions: UpdatedInstitution[]) => {
  const res = ` mutation updateInstitution {
      ${fsInstitutions.map((update) => {
        const { id, dimensions } = update;
        const dimensionString = JSON.stringify(dimensions).replace(/"(\w+)"\s*:/g, '$1:');
        return `update${id}: updateFsInstitution(input:{where:{id:"${id}"}, data:{dimensions: ${dimensionString}}}){fsInstitution{id}}`;
      })}}`;
  return res;
};

const isSuccessfullUpdate = (response: any) =>
  !Object.values(response)
    .map((key) => key)
    .some((val) => val === null);

const updateFsInstitutions = async (updatedInstitutions: UpdatedInstitution[], token: string | null): Promise<UpdateResponse> => {
  try {
    const response = await graphqlRequest<any>(updateInstitutionMutation(updatedInstitutions), token);
    if (isSuccessfullUpdate(response)) {
      return { status: 200, data: 'Successfull update! 🥳' };
    }
    return {
      status: 500,
      data: 'Something went wrong, its possible that your login session has expired. 😔 Please try to log-out and log-in again. Your changes have been saved and you can continue working from your current state after re-logging. 😉',
    };
  } catch (err) {
    return { status: 500, data: `Something went wrong 😔 ${err.message}` };
  }
};

const recalculateFsPeriod = async (periodId: number, fsType: FsType, token: string | null): Promise<UpdateResponse> => {
  const url = getStrapiRoutes().cms.recalculateForChartGenerator(periodId, fsType);

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  try {
    await axios.put(url, {}, { headers });
    return { status: 200, data: 'Successful update and recalculation! 🥳' };
  } catch (error) {
    const { status, message } = error.toJSON() as AxiosJsonError;

    if (status === 401) {
      return {
        status,
        data: `${message}. Your login session may have expired. 😔 Please try to log-out and log-in again. Your changes have been saved and you can continue working from your current state after re-logging. 😉`,
      };
    }

    return { status, data: `Something went wrong 😔${message}` };
  }
};

export const updateInstitutionsAndRecalculatePeriod = async (
  updatedInstitutions: UpdatedInstitution[],
  periodId: number,
  fsType: FsType,
  token: string | null
) => {
  await updateFsInstitutions(updatedInstitutions, token);
  const recalculateRes = await recalculateFsPeriod(periodId, fsType, token);
  return recalculateRes;
};
