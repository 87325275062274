import React, { useRef, useState } from 'react';
import { useSwipeable as useSwipeableForDesktop } from 'react-swipeable';
import { Button, Flex, SxProps, ButtonProps } from 'theme-ui';
import { useIsMobile } from '@themed';
import { OffsetProps, styles } from './SegmentedControl.styles';

type ControlValue = number | string;

export interface ControlItem {
  value?: ControlValue;
  title: string;
}

interface ChangeCallbackProps {
  value?: ControlValue;
  index: number;
}

type ChangeCallback = (props: ChangeCallbackProps) => void;

const calcOffset = (item?: HTMLButtonElement | null): OffsetProps => {
  if (item) {
    const { offsetLeft, offsetWidth } = item;
    return { left: offsetLeft, width: offsetWidth };
  }
  return { left: 0, width: 0 };
};

interface SwipeableProps {
  onSwipedLeft: () => void;
  onSwipedRight: () => void;
  onTap: () => void;
}
const useSwipeable = ({ onSwipedLeft, onSwipedRight, onTap }: SwipeableButtonProps) => {
  const [pos, setPos] = useState(0);

  const handleTouchStart = (e: React.TouchEvent<HTMLButtonElement>) => setPos(e.touches[0].clientX);

  const handleTouchEnd = (e: React.TouchEvent<HTMLButtonElement>) => {
    const currPos = e.changedTouches[0].clientX;
    if (pos > currPos + 5) {
      onSwipedLeft();
    } else if (pos < currPos - 5) {
      onSwipedRight();
    }
  };

  const handlers = useSwipeableForDesktop({
    onTap,
    onSwipedLeft,
    onSwipedRight,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
    trackTouch: true,
  });

  return {
    ...handlers,
    onTouchStart: handleTouchStart,
    onTouchEnd: handleTouchEnd,
  };
};

interface SwipeableButtonProps extends SwipeableProps, ButtonProps {}

const SwipeableButton = React.forwardRef<HTMLButtonElement, SwipeableButtonProps>(
  ({ children, onSwipedLeft, onSwipedRight, onTap, ...props }, ref) => {
    const handlers = useSwipeable({
      onSwipedLeft,
      onSwipedRight,
      onTap,
    });
    return (
      <Button {...handlers} {...props} ref={ref}>
        {children}
      </Button>
    );
  }
);

interface SegmentedControlProps extends SxProps {
  id?: string;
  items: ControlItem[];
  defaultIndex?: number;
  onChange?: ChangeCallback;
}

export const SegmentedControl = ({ sx, items, onChange, defaultIndex, ...props }: SegmentedControlProps) => {
  const [currentIndex, setCurrentIndex] = React.useState(defaultIndex || 0);
  const getCurrentValue = (index = currentIndex) => items[index].value;
  const [currentOffset, setOffset] = React.useState<OffsetProps>({ left: 0, width: 0, transition: 'none' });
  const refs = useRef(items.map(() => React.createRef<HTMLButtonElement>()));
  const isMobile = useIsMobile();

  const initOffset = React.useCallback(
    (noTransition = false) =>
      setOffset({
        ...calcOffset(refs.current[currentIndex]?.current),
        ...(!currentOffset.width || noTransition ? { transition: 'none' } : {}), // FIX to avoid animation on receiving width first
      }),
    [refs, currentIndex]
  );

  React.useLayoutEffect(() => {
    initOffset();
    window.addEventListener('resize', () => initOffset(true));
  }, [refs, currentIndex]);

  const newIndex = (index: number) => {
    if (index >= 0 && index <= items.length - 1) {
      setCurrentIndex(index);
      if (onChange) {
        setTimeout(
          () =>
            onChange({
              value: getCurrentValue(index),
              index,
            }),
          200
        );
      }
    }
  };

  return (
    <Flex {...props} sx={{ ...styles.container(currentOffset, isMobile), ...sx }} data-cy='finnoscore-segmented-button'>
      {items.map(({ value, title }, index) => (
        <SwipeableButton
          sx={styles.item(getCurrentValue() === value)}
          onSwipedLeft={() => newIndex(currentIndex - 1)}
          onSwipedRight={() => newIndex(currentIndex + 1)}
          onTap={() => newIndex(index)}
          key={`${value}-${title}`}
          ref={refs.current[index]}
        >
          {title}
        </SwipeableButton>
      ))}
    </Flex>
  );
};
