import * as React from 'react';

const SvgGlobeAndPlay = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={120} height={120} viewBox='0 0 120 120' {...props}>
    <g fill='none' fillRule='evenodd'>
      <circle cx={60} cy={60} r={60} fill='#FFF' />
      <path
        fill='#000'
        fillRule='nonzero'
        d='M44 17c13.807 0 25 11.193 25 25S57.807 67 44 67 19 55.807 19 42s11.193-25 25-25zm5.818 26.001H38.182c.108 5.654 1.128 10.853 2.774 14.594.75 1.702 1.574 2.969 2.347 3.711l.114.108.196.17.082.066.134.095.052.033.077.038.042.012.042-.012.077-.038.052-.033.134-.095.082-.066.196-.17.114-.108c.773-.742 1.598-2.009 2.347-3.711 1.646-3.741 2.666-8.94 2.774-14.594zm-16.81 0h-8.81c.391 7.88 5.384 14.55 12.346 17.377l-.194-.414c-.13-.284-.255-.575-.378-.873l-.242-.605c-.158-.41-.31-.83-.456-1.263l-.215-.657-.206-.673c-.133-.454-.26-.919-.382-1.393l-.176-.72-.165-.733-.08-.372-.15-.753-.14-.767-.128-.78-.06-.394-.111-.797-.1-.809-.088-.82-.04-.413-.07-.835-.058-.844a54.89 54.89 0 01-.096-2.462zm30.795 0H54.99a54.89 54.89 0 01-.096 2.462l-.058.844-.07.835-.04.414-.088.82-.1.808-.111.797-.06.394-.129.78-.14.767-.15.753c-.025.125-.052.249-.079.372l-.165.734-.176.719c-.121.474-.249.939-.382 1.393l-.206.673-.215.657c-.146.432-.298.853-.456 1.263l-.242.605c-.123.298-.249.589-.378.873l-.194.414c6.962-2.827 11.955-9.497 12.347-17.377zm-39.405-4l8.68-.002.027-.462.058-.844.07-.835.04-.414.088-.82.1-.808.111-.797.06-.394.129-.78.14-.767.15-.753c.025-.125.052-.249.079-.372l.165-.734.176-.719c.121-.474.249-.939.382-1.393l.206-.673.215-.657c.146-.432.298-.853.456-1.263l.242-.605c.123-.298.249-.589.378-.873l.194-.414C30.18 26.207 25.46 32.003 24.398 39zM44 22.171l-.042.012-.077.038-.052.033-.134.095-.082.066-.196.17-.114.108c-.773.742-1.598 2.009-2.347 3.711-1.45 3.296-2.414 7.723-2.697 12.596H49.74c-.283-4.873-1.247-9.3-2.697-12.596-.75-1.702-1.574-2.969-2.347-3.711l-.114-.108-.196-.17-.082-.066-.134-.095-.052-.033-.077-.038-.042-.012zm7.456 1.45l.194.414c.13.284.255.575.378.873l.242.605c.158.41.31.83.456 1.263l.215.657.206.673c.133.454.26.919.382 1.393l.176.72.165.733.08.372.15.753.14.767.128.78.06.394.111.797.1.809.088.82.04.413.07.835.058.844.024.463h8.683c-1.062-6.997-5.78-12.793-12.146-15.378z'
      />
      <circle cx={80} cy={81} r={22} fill='#00BD59' />
      <path fill='#FFF' d='M75.518 70.777l16.069 9.725a1 1 0 010 1.711l-16.07 9.726A1 1 0 0174 91.084V71.632a1 1 0 011.518-.855z' />
    </g>
  </svg>
);

export default SvgGlobeAndPlay;
