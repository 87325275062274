import * as React from 'react';

const SvgMail = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={54} height={40} viewBox='0 0 54 40' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g id='mail_svg__Page-1' stroke='none' strokeWidth={1} fill='none' fillRule='evenodd'>
      <g id='mail_svg__mail' transform='translate(2 1)'>
        <rect id='mail_svg__Rectangle' stroke='#000' strokeWidth={4} x={0} y={5} width={44} height={32} rx={2} />
        <path id='mail_svg__Path-3' stroke='#000' strokeWidth={4} strokeLinejoin='round' d='M0 12l22 10 22-10' />
        <circle id='mail_svg__Oval' stroke='#FFF' strokeWidth={2} fill='#00BD59' fillRule='nonzero' cx={44} cy={7} r={7} />
      </g>
    </g>
  </svg>
);

export default SvgMail;
