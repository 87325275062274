import { getTranslationsQuery, graphqlRequest } from '@modules';
import { InstitutionsPeriodList, InstitutionWithImage, Translation } from '@types';
import { formatFsTypeForGQL } from '../helpers';

export const institutionFields = `
            id
            name
            text:name
            image:logo{
              url
              alternativeText
            }
            fsType
            rank
            total
            logo{
              url
              alternativeText
            }
            dimensions
            country:fs_country{
              countryCode
              flag{
                url
              }
            }
`;

interface FSPeriodsWithInstitutionsDTO {
  fsPeriods: InstitutionsPeriodList[];
  translations: Translation[];
}

export interface InstitutionSelectionList {
  list: InstitutionWithImage[];
  translations: Translation[] | null;
}

export const getInstitutionsForPeriodByType = async (type: string, period?: string | number) => {
  try {
    const filterPeriod = period ? `id:"${period}", ` : '';
    const filterWwithoutWeightRecord = `, where: {fsType:"${formatFsTypeForGQL(type)}"}`;

    const response = await graphqlRequest<FSPeriodsWithInstitutionsDTO>(`
      {
        fsPeriods(where:{${filterPeriod}fsType:"${formatFsTypeForGQL(type)}"}, sort:"date:desc", publicationState: PREVIEW){
          date
          institutions:fs_institutions(sort:"total:desc"${filterWwithoutWeightRecord}){
            ${institutionFields}
          }
        }
        ${getTranslationsQuery()}
      }
    `);
    return {
      // this filter needs to eliminate weight entity from institution list
      list: response.fsPeriods[0]?.institutions.filter((institution) => institution.fsType === type),
      translations: response.translations,
    };
  } catch (err) {
    console.error(err);
    return { list: [], translations: [] };
  }
};
