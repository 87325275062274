import { useIntl } from 'gatsby-plugin-intl';
import { useLocation } from '@reach/router';
import { graphql, useStaticQuery } from 'gatsby';
import { useIsMobile } from '@themed';
import { getAllNavBarItems, platformFilterItem } from '@config';
import { MetaQuery } from '@types';

export const useNavbarItems = (isMobile?: boolean) => {
  const { pathname } = useLocation();
  const intl = useIntl();
  const isMobileRuntime = useIsMobile();

  const { allPageMeta } = useStaticQuery<MetaQuery>(graphql`
    {
      allPageMeta {
        edges {
          node {
            pageId
            language {
              code
            }
          }
        }
      }
    }
  `);
  const pageIds = allPageMeta.edges.filter(({ node }) => node.language.code === intl.locale).map(({ node }) => node.pageId);

  return getAllNavBarItems(intl.locale, pathname, pageIds).filter((item) =>
    platformFilterItem(isMobile !== undefined ? isMobile : isMobileRuntime, item.forMobile)
  );
};
