import React from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/all';
import { TriggerElementProps } from '@types';
import { __el, __tr, __select } from '@modules';
import { cleanupScrolls, cleanupTimeline, themedProp, withAnimation } from '@core';

import { finniConfig } from '../../../config/Finni.styles';

const { pacman } = finniConfig;

function isHTMLElement(node: Node | null): node is HTMLElement {
  return node !== null && node.nodeType === Node.ELEMENT_NODE;
}

const totalDuration = 1.5;

interface AnimateFinniPacManProps extends TriggerElementProps {
  pacman?: () => React.RefObject<HTMLDivElement>;
  pacmanForehead: () => React.RefObject<HTMLDivElement>;
  pacmanChin: () => React.RefObject<HTMLDivElement>;
  pacmanTie: () => React.RefObject<HTMLDivElement>;
  pacmanHat: () => React.RefObject<HTMLDivElement>;
  foodContainer: () => React.RefObject<HTMLDivElement>;
}

export const animateFinniPacMan = ({ pacmanForehead, pacmanChin, pacmanHat, pacmanTie, foodContainer, triggerRef }: AnimateFinniPacManProps) => {
  gsap.registerPlugin(ScrollTrigger);

  const pacmanAnim = gsap
    .timeline({
      repeat: -1,
    })
    // start mouth open
    .to([__el(pacmanForehead)], { rotation: -26, yPercent: '-=3', ease: 'sine' }, 0)
    .to(__el(pacmanChin), { rotation: 26, yPercent: '-=3', ease: 'sine' }, '<')
    .to(__el(pacmanTie), { yPercent: '-=3', ease: 'sine' }, '<')
    // start mouth close
    .to([__el(pacmanForehead)], { rotation: 0, yPercent: 0, ease: 'sine.in' })
    .to(__el(pacmanChin), { rotation: 0, yPercent: 0, ease: 'sine.in' }, '<')
    .to(__el(pacmanTie), { yPercent: 0, ease: 'sine.in' }, '<')
    // start mouth open (and floating down)
    .to([__el(pacmanForehead)], { rotation: -26, yPercent: '+=3', ease: 'sine' })
    .to(__el(pacmanChin), { rotation: 26, yPercent: '+=3', ease: 'sine' }, '<')
    .to(__el(pacmanTie), { yPercent: '+=3', ease: 'sine' }, '<')
    // start mouth close (returning from down)
    .to([__el(pacmanForehead)], { rotation: 0, yPercent: 0, ease: 'sine.in' })
    .to(__el(pacmanChin), { rotation: 0, yPercent: 0, ease: 'sine.in' }, '<')
    .to(__el(pacmanTie), { yPercent: 0, ease: 'sine.in' }, '<')
    .duration(totalDuration);

  const getFoodSize = () => {
    const lastFood = __select('figure:nth-child(6)', foodContainer) as HTMLElement;
    const firstFood = __select('figure:nth-child(1)', foodContainer) as HTMLElement;
    const secondFood = __select('figure:nth-child(2)', foodContainer) as HTMLElement;

    const startFoodSpace = (secondFood?.getBoundingClientRect()?.x || 0) - (firstFood?.getBoundingClientRect()?.x || 0);
    const endFoodSpace = (lastFood?.getBoundingClientRect()?.x || 0) - (firstFood?.getBoundingClientRect()?.x || 0);
    const foodSpacingFallback = parseInt(themedProp('sizes.pacmanFoodSize'), 10) || parseInt(themedProp('sizes.pacmanFoodSpacing'), 10);

    return {
      start: startFoodSpace || foodSpacingFallback,
      end: -endFoodSpace || -foodSpacingFallback,
    };
  };

  const foodAnim = gsap.fromTo(
    __el(foodContainer),
    {
      x: getFoodSize().start, // using += should be a problem, on page reload!
    },
    {
      x: getFoodSize().end,
      ease: 'linear',
      duration: totalDuration * 3,
      repeat: -1,
      onUpdate: () => {
        const containerRef = foodContainer();

        // check foods separately if it already behind pacman's head or not
        if (containerRef.current) {
          const pacmanForeheadLeftX = pacmanForehead().current?.getBoundingClientRect().left;
          containerRef.current.childNodes.forEach((foodNode) => {
            if (pacmanForeheadLeftX && isHTMLElement(foodNode)) {
              const element = foodNode as HTMLElement;
              const boundingRect = element.getBoundingClientRect();
              if (boundingRect.left <= pacmanForeheadLeftX + 20) {
                element.style.visibility = 'hidden';
              } else {
                element.style.visibility = 'visible';
              }
            }
          });
        }
      },
    }
  );

  const scrollers = [] as gsap.plugins.ScrollTriggerInstance[];

  const initScroll = () => {
    const trigger = __tr(triggerRef);
    if (!trigger) return;
    scrollers.push(
      ScrollTrigger.create({
        toggleActions: 'restart none reverse none',
        trigger,
        start: `top+=${pacman.tolerance} bottom`, // was 'center' with `container` trigger, which was not working
        end: `top+=${pacman.tolerance * 2} bottom`, // was 'center' with `container` trigger, which was not working
        invalidateOnRefresh: true,
        // markers: process.env.NODE_ENV === 'development',
        onEnter: () => {
          pacmanAnim.pause(0);
          foodAnim.pause(0);
          // set initial setup on Entering, as this is immediate!, lot quicker than anything even with tiny timelime duration
          gsap.set(__el(foodContainer), { opacity: 0 });
          gsap.set(__el(pacmanHat), { opacity: 0 });
          gsap.set(__el(pacmanTie), { opacity: 0 });
        },
        onLeave: () => {
          foodAnim.pause(0);
        },
        // onRefresh: () => {
        //   pacmanAnim.invalidate();
        //   pacmanAnim.restart();
        //   foodAnim.invalidate();
        //   foodAnim.restart();
        // },
        onEnterBack: () => {
          pacmanAnim.restart();
          foodAnim.restart();
        },
        onLeaveBack: () => {
          pacmanAnim.restart();
          foodAnim.restart();
          // Decided to use this way rather, as this is immediate!, lot quicker than anything even with tiny timelime duration
          gsap.set(__el(foodContainer), { opacity: 1 });
          gsap.set(__el(pacmanHat), { opacity: 1 });
          gsap.set(__el(pacmanTie), { opacity: 1 });
        },
      })
    );
  };
  ScrollTrigger.matchMedia({ all: initScroll });

  return () => {
    cleanupScrolls();
    cleanupTimeline(foodAnim);
    cleanupTimeline(pacmanAnim);
  };
};

export const useAnimationFinniPacMan = (props: AnimateFinniPacManProps) => withAnimation<AnimateFinniPacManProps>(animateFinniPacMan, props);
