import React from 'react';
import { Box, CalendlyButton, CompactSection, CTAWithOptionalLink, Flex, SvgIcon } from '@core';
import { Title, Paragraph } from '@themed';
import { __ } from '@modules';
import { useStrapiPageContent } from 'src/content/useStrapiPageContent';
import { findFirstContentWithButton } from '@types';
import { useButtonContent } from 'src/types/Button';
import { styles } from './Init.styles';

export default function Init() {
  return (
    <CompactSection
      titleAs='h1'
      id='workshops.init'
      sx={styles.content}
      contentBottom={
        <CalendlyButton
          href={__('pages.aboutus.founders.list.0.calendar')}
          sx={styles.calendlyButton}
          title={__('pages.workshops.packages.button.text')}
        />
      }
    >
      <InitOffer />
    </CompactSection>
  );
}

function InitOffer() {
  return (
    <Box sx={styles.container}>
      <Flex sx={styles.offer}>
        <Flex sx={styles.titleCol}>
          <SvgIcon name='eye' color='primary' />
          <Title as='h2'>{__('pages.workshops.init.report.title')}</Title>
        </Flex>
        <Paragraph sx={styles.paragraph}>{__('pages.workshops.init.report.description')}</Paragraph>
        <RequestReportButton />
      </Flex>
    </Box>
  );
}

function RequestReportButton() {
  const pageContent = useStrapiPageContent('workshops');
  const buttonContent = findFirstContentWithButton(pageContent?.content);
  const { text, link, target } = useButtonContent(buttonContent);

  return (
    <CTAWithOptionalLink buttonVariant='info' sx={styles.link} to={link} target={target}>
      {text}
    </CTAWithOptionalLink>
  );
}
