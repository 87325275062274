import { NewsLetterSignup } from '@composite';
import { useModalOverlayTimer } from '@core';
import { useTranslate } from '@modules';
import { Box } from '@themed';
import React from 'react';
import { DefaultNewsLetterContent } from './NewsLetterContent';
import { styles } from './NewsletterModal.styles';

export const NewsletterModal = () => {
  const __ = useTranslate();
  const { close, Component: ModalOverlayTimer } = useModalOverlayTimer({
    seconds: Number(process.env.GATSBY_OFFER_FINNOSCORE_NEWSLETTER_TIMEOUT) || 30,
  });
  if (!__('finnoscore.newsletter.title', true)) return null;
  return (
    <ModalOverlayTimer>
      <Box sx={styles.container}>
        <NewsLetterSignup id='finnoscore.newsletter' onSubmit={() => close()}>
          <DefaultNewsLetterContent id='finnoscore.newsletter' />
        </NewsLetterSignup>
      </Box>
    </ModalOverlayTimer>
  );
};
