import * as React from 'react';

const SvgFinnoscore = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={81} height={12} viewBox='0 0 81 12' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g id='finnoscore_svg__Page-1' stroke='none' strokeWidth={1} fill='none' fillRule='evenodd'>
      <g id='finnoscore_svg__finnoscore' fillRule='nonzero'>
        <path
          d='M28.508 4h-2.012l-4.643 7.5v.263h1.828L28.508 4zm-6.655 7l1.729-2.8V4h-1.729v7zm-3.504.763h1.718V4.375l-1.718 2.782v4.606zM19.989 4h-2.024l-4.643 7.52v.243h1.827L19.99 4zm-6.667 7.02l1.729-2.8V4h-1.73v7.02zM26.88 7.136v4.626h1.718V4.356L26.88 7.137z'
          id='finnoscore_svg__Fill-8'
          fill='#FFF'
        />
        <path
          d='M2.471 11.686V7.33H6.81V5.327H2.455v-2.76h4.891V.498H.278v11.187h2.193zM10.13 2.66c.725 0 1.293-.584 1.293-1.278 0-.726-.568-1.31-1.293-1.31-.71 0-1.294.584-1.294 1.31 0 .694.584 1.278 1.294 1.278zm1.057 9.025V3.923H9.087v7.763h2.099z'
          id='finnoscore_svg__Fi'
          fill='#000'
        />
        <path
          d='M33.83 11.923c2.319 0 4.07-1.736 4.07-4.118 0-2.399-1.751-4.118-4.07-4.118-2.32 0-4.071 1.72-4.071 4.118 0 2.382 1.751 4.118 4.07 4.118zm0-1.91c-1.026 0-1.973-.757-1.973-2.208 0-1.468.947-2.21 1.972-2.21 1.026 0 1.973.742 1.973 2.21 0 1.467-.947 2.208-1.973 2.208zm9.66 1.91c2.604 0 4.04-1.736 4.04-3.456 0-1.578-1.089-2.903-3.124-3.297l-1.562-.3c-.82-.158-1.215-.647-1.215-1.262 0-.742.694-1.42 1.751-1.42 1.42 0 1.925.962 2.035 1.577l1.957-.6C47.119 1.905 46.078.264 43.364.264c-2.114 0-3.897 1.514-3.897 3.502 0 1.689 1.152 2.84 2.935 3.203l1.562.316c.868.173 1.357.678 1.357 1.325 0 .79-.647 1.373-1.815 1.373-1.577 0-2.35-.994-2.445-2.099l-2.02.537c.174 1.593 1.483 3.503 4.45 3.503zm9.299 0c2.067 0 3.282-1.326 3.629-2.62l-1.846-.615c-.174.6-.71 1.31-1.783 1.31s-1.988-.79-1.988-2.193c0-1.405.9-2.178 1.972-2.178 1.042 0 1.53.663 1.72 1.31l1.878-.631c-.332-1.31-1.53-2.62-3.645-2.62-2.256 0-4.023 1.72-4.023 4.119 0 2.382 1.798 4.118 4.086 4.118zm8.715 0c2.319 0 4.07-1.736 4.07-4.118 0-2.399-1.751-4.118-4.07-4.118-2.32 0-4.071 1.72-4.071 4.118 0 2.382 1.751 4.118 4.07 4.118zm0-1.91c-1.026 0-1.973-.757-1.973-2.208 0-1.468.947-2.21 1.973-2.21 1.025 0 1.972.742 1.972 2.21 0 1.467-.947 2.208-1.972 2.208zm7.957 1.673v-3.55c0-1.673.93-2.193 2.004-2.193.189 0 .394.016.63.063V3.892a4.778 4.778 0 00-.488-.032c-.663 0-1.736.19-2.21 1.215V3.923h-2.035v7.763h2.099zm7.594.237c1.91 0 3.14-1.12 3.534-2.462l-1.75-.52c-.253.694-.79 1.183-1.768 1.183-1.041 0-1.91-.742-1.956-1.767h5.553c0-.032.032-.347.032-.647 0-2.493-1.436-4.023-3.834-4.023-1.988 0-3.818 1.609-3.818 4.086 0 2.62 1.877 4.15 4.007 4.15zm1.578-5.002h-3.471c.047-.71.647-1.53 1.735-1.53 1.2 0 1.704.757 1.736 1.53z'
          id='finnoscore_svg__oScore'
          fill='#000'
        />
      </g>
    </g>
  </svg>
);

export default SvgFinnoscore;
