import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/all';
import { MotionPathPlugin } from 'gsap/MotionPathPlugin';
import { __selectAll } from '@modules';
import { getBreakpoint, scrub, snap, withAnimation } from '@core';

interface AnimateJumpingServiceIconsProps {
  sectionRef: () => React.RefObject<HTMLDivElement>;
}

export const animateJumpingServiceIcons = ({ sectionRef }: AnimateJumpingServiceIconsProps) => {
  const scArray: gsap.plugins.ScrollTriggerInstance[] = [];

  const initScrollers = () => {
    gsap.registerPlugin(ScrollTrigger);
    gsap.registerPlugin(MotionPathPlugin);

    const icons = Array.from(__selectAll('.serviceIcon', sectionRef));

    icons.forEach((icon, index) => {
      if (index < icons.length - 1) {
        const nextIcon = icons[index + 1];
        const distance = MotionPathPlugin.getRelativePosition(icon, nextIcon);

        const animation = gsap
          .timeline()
          .to(
            icon,
            {
              motionPath: [{ x: 0, y: 0 }, { x: distance.x * 0.7, y: distance.y * 0.03 }, { x: distance.x * 0.9, y: distance.y * 0.3 }, distance],
              duration: 2,
            },
            '>'
          )
          .to(
            nextIcon,
            {
              opacity: 1,
              duration: 0.1,
            },
            '>'
          )
          .to(
            icon,
            {
              opacity: 0,
              duration: 0.1,
            },
            '>'
          );

        scArray.push(
          ScrollTrigger.create({
            invalidateOnRefresh: true,
            trigger: icon,
            // markers: process.env.NODE_ENV === 'development',
            start: 'top 30%',
            end: 'bottom 0',
            ...scrub(),
            ...snap(),
            animation,
          })
        );
      }
    });
  };

  ScrollTrigger.matchMedia({
    [`(min-width: ${getBreakpoint(1)})`]: initScrollers,
  });
};

export const useAnimationJumpingServiceIcons = (props: AnimateJumpingServiceIconsProps) =>
  withAnimation<AnimateJumpingServiceIconsProps>(animateJumpingServiceIcons, props);
