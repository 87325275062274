/** @jsx jsx */
import React from 'react';
import { jsx, Box, BoxProps } from '@themed';
import { Institution } from '@types';
import { ALL_COUNTRIES, useSelectedCountry } from '@modules';
import { DesktopInstitutionHeader } from './DesktopInstitutionHeader';
import { InstitutionItem } from './InstitutionItem';
import { styles } from './FinnoscoreList.styles';

interface Props extends BoxProps {
  institutions: Institution[];
}

export default ({ institutions, ...props }: Props) => {
  const country = useSelectedCountry();
  const institutionssForCountry =
    !country || country.countryCode === ALL_COUNTRIES
      ? institutions
      : institutions.filter((institution) => institution.country.countryCode === country.countryCode);

  return (
    <Box {...props} sx={{ ...styles.box, ...props.sx }}>
      <DesktopInstitutionHeader />

      <Box data-cy='finnoscore-institution-table' sx={styles.list}>
        {institutionssForCountry.map((institution, index) => (
          <React.Fragment key={`${institution.name}-${institution.country.countryCode}`}>
            <InstitutionItem institution={institution} index={index} />
          </React.Fragment>
        ))}
      </Box>
    </Box>
  );
};
