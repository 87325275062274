/** @jsx jsx */
import { jsx, Flex } from '@themed';
import { useInstitutionsByCountries } from '@modules';
import { Institution, ComparisonSubjectType, Comparison } from '@types';
import { ListItem } from '@core';
import { useComparisonDropDown } from './ComparisonDropDownContext';
import { CountryCalculationDropDown } from './CountryCalculationDropDown';
import { InstitutionGroupedDropDown } from './InstitutionGroupedDropDown';
import { styles } from './ComparisonDropDown.styles';

export interface ComparisonDropDownControlProps {
  countries?: ListItem[];
  institutions?: Institution[];
  comparison?: Comparison | null;
  setA: (value: string) => void;
  setB: (value: string) => void;
}

export const ComparisonDropDownControl = ({ comparison, countries, institutions, setA, setB }: ComparisonDropDownControlProps) => {
  // TODO: countries shall be retrieved here as a useStaticQuery, based on institusions.
  const { open, setOpen } = useComparisonDropDown();
  const institutionsGrouped = useInstitutionsByCountries(institutions, comparison?.a.name);

  return (
    <Flex id='control' sx={styles.control}>
      {comparison && countries && institutions && (
        <CountryCalculationDropDown
          countries={countries}
          isOpen={open === 'a'}
          setIsOpen={() => setOpen('a')}
          onSelect={(value) => {
            setOpen('a');
            setA(`${ComparisonSubjectType.CountryCalculation}.${value}`);
          }}
          selectedCalculation={comparison.a}
        />
      )}

      {comparison && countries && institutions && (
        <InstitutionGroupedDropDown
          institutionsGrouped={institutionsGrouped}
          isOpen={open === 'b'}
          setIsOpen={() => setOpen('b')}
          onSelect={(value) => {
            setOpen('b');
            setB(`${ComparisonSubjectType.Institution}.${value}`);
          }}
          selectedInstitution={comparison.b}
        />
      )}
    </Flex>
  );
};
