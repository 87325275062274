import React from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/all';
import { TriggerElementProps } from '@types';
import { __el, __select } from '@modules';
import { themeConfig } from '@themed';
import { useSmoothScrollbarListener, withSmoothScrollBar } from '@core';

interface AnimateHeaderToggleProps extends TriggerElementProps {
  triggerRef: () => React.RefObject<HTMLDivElement>;
  defaultStateRef: () => React.RefObject<HTMLDivElement>;
  hiddenStateRef: () => React.RefObject<HTMLDivElement>;
}

export const animateHeaderToggle = ({ defaultStateRef, hiddenStateRef, triggerRef }: AnimateHeaderToggleProps) => {
  gsap.registerPlugin(ScrollTrigger);

  const headerHeight = parseInt(themeConfig.sizes.headerHeight, 10);

  const toggleAnimation = gsap
    .timeline()
    .to(__el(defaultStateRef), {
      y: -1 * headerHeight,
      // yPercent: -100,
      duration: 0.5,
    })
    .to(
      __el(hiddenStateRef),
      {
        y: 0,
        duration: 0.5,
      },
      '<'
    );

  const setHiddenStatePosition = () => {
    const defaultMenuWidth = parseInt(`${gsap.getProperty('[data-gsap-id=header-width]', 'width', 'px')}`, 10) || window?.innerWidth;
    const position = (window?.innerWidth - defaultMenuWidth) / 2;
    gsap.set(__el(hiddenStateRef), {
      position: 'fixed',
      right: position,
      left: 'unset',
      width: 'auto',
    });
  };

  useSmoothScrollbarListener({
    triggerRef: __el(triggerRef),
  });

  const toleranceTop = headerHeight + parseInt(themeConfig.sizes.footerHeight, 10);
  const toleranceProgress = 0.98;

  const hideMenu = () => toggleAnimation.play();
  const showMenu = () => toggleAnimation.reverse();

  ScrollTrigger.create({
    toggleActions: 'restart none reverse none',
    trigger: 'main',
    start: `top+=${toleranceTop} top`,
    end: 'bottom bottom',
    invalidateOnRefresh: true,
    onRefresh: setHiddenStatePosition,

    // markers: process.env.NODE_ENV === 'development',
    onUpdate: (self) => {
      if (self.direction === -1) {
        showMenu();
      } else if (self.direction === 1 && toggleAnimation.progress() !== 1 && self.progress <= toleranceProgress) {
        hideMenu();
        // NOTE: progress was not correct on smaller size pages, so instead created a separate ScrollTrigger below
        // } else if (self.direction === 1 && self.progress>toleranceProgress) {
        //   console.log('reverse()');
        //   showMenu();
      }
    },
    animation: toggleAnimation,
  });

  const section = __select('section#contact');

  if (section) {
    ScrollTrigger.create({
      trigger: section,
      start: () => `top-=${toleranceTop} top`, // needed function due to a previous section pin
      // markers: process.env.NODE_ENV === 'development',
      onEnter: () => {
        showMenu();
      },
    });
  }

  // TODO: should be cleaned up only if the page is being resized, ie from mobile to desktop
  // NOTE: we return the animation timeline to be able to govern from external sources
  return () => toggleAnimation;
};

export const useAnimateHeaderToggle = (props: AnimateHeaderToggleProps) => withSmoothScrollBar<AnimateHeaderToggleProps>(animateHeaderToggle, props);
