/** @jsx jsx */
import { BankPress, LandingPress, NewsletterModal } from '@composite';
import { Layout, StaticSEO } from '@core';
import { SelectedCountryContextProvider, SelectedFsTypeContextProvider, useDefaultCountry } from '@modules';
import { Flex, jsx, useIsTablet } from '@themed';
import { Country, Institution, WithFsType } from '@types';
import React from 'react';
import { finnoscoreDataStyles as styles } from '../finnoscore-data/index.styles';
import Profile from '../finnoscore-data/Profile';
import ReportAdvertLink, { ReportButton } from '../finnoscore-data/ReportAdvertLink';
import { CountrySelector } from './CountrySelector';
import InstitutionList from './FinnoscoreList';
import { FinnoscoreListSection, FinnoscoreListSectionMeta } from './FinnoscoreListSection';
import { FsTabSelector, useFsSelectorOptions } from './FSTabSelector';

interface FinnoscoreListSectionData {
  countries?: Country[];
  institutions?: Institution[];
}

export interface FinnoscoreListProps extends FinnoscoreListSectionMeta {
  pageContext: FinnoscoreListSectionData & WithFsType;
}

const ListPage = ({ isLoading, error, pageContext }: FinnoscoreListProps) => {
  const { countries, institutions } = pageContext;
  const isTablet = useIsTablet();
  const tabOptions = useFsSelectorOptions(institutions);

  const shouldRenderContent = countries && institutions && !error && !isLoading;

  return (
    <FinnoscoreListSection isLoading={isLoading} error={error}>
      {shouldRenderContent && (
        <React.Fragment>
          <ReportAdvertLink id='report' />
          <Profile id='contact' />

          {isTablet ? <LandingPress id='press' /> : <BankPress id='press' />}

          <FsTabSelector id='chartType' sx={styles.tabSelector} options={tabOptions} />

          <Flex id='control' sx={styles.control}>
            <ReportButton />
            {countries && <CountrySelector countries={countries} />}
          </Flex>

          <Flex id='content' variant='flex.colCenter'>
            {institutions && <InstitutionList institutions={institutions} />}
          </Flex>
        </React.Fragment>
      )}
    </FinnoscoreListSection>
  );
};

const PageWithLayout = ({ pageContext, isLoading, error }: FinnoscoreListProps) => {
  const { countries, fsType } = pageContext;
  const { country: selectedCountry } = useDefaultCountry(countries);

  return (
    <React.Fragment>
      <Layout sx={styles.main}>
        <StaticSEO pageId={`finnoscore-list-${fsType}`} />
        <SelectedFsTypeContextProvider>
          {selectedCountry ? (
            <SelectedCountryContextProvider defaultValue={selectedCountry}>
              <ListPage pageContext={pageContext} isLoading={isLoading} error={error} />
            </SelectedCountryContextProvider>
          ) : (
            <FinnoscoreListSection isLoading={isLoading} error={error} />
          )}
        </SelectedFsTypeContextProvider>
      </Layout>
      <NewsletterModal />
    </React.Fragment>
  );
};

export default PageWithLayout;
