/** @jsx jsx */
import { jsx, SubTitle, HeadingProps } from '@themed';
import { useTranslate } from '@modules';

export const DefaultErrorMessage = (props: HeadingProps & { error: Error }) => {
  const __ = useTranslate();
  const errorMessage = __('common.genericError');
  console.error('error: ', props.error?.message);

  return <SubTitle {...props}>{errorMessage}</SubTitle>;
};
