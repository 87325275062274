export function getDefaultLanguageId(languages: string[]) {
  return languages[0];
}

export const getCanonicalUrl = (host: string, pathname: string, allowedLanguages: string[]) => {
  const defaultLang = getDefaultLanguageId(allowedLanguages);
  // if we hve correct canonical with language at front
  const correctCanonical = new RegExp(`/(${allowedLanguages.join('|')})(/|$)`);

  const correctPathname = `${pathname.replace(/\/$/, '')}/`;

  switch (pathname) {
    case '/':
    case '/de':
    case '/de/':
      return `${host}/`;
    default:
      // we want to make the canonical urls to link to the pathnames which begin with language, ie /de
      return pathname.match(correctCanonical)
        ? `${host}${correctPathname}`
        : // other than, we redirect pathnames without language to the default language (comply with react-intl plugin)
          `${host}/${defaultLang}${correctPathname}`; // ?reroute-to-def-lang
  }
};
