import { RGBColor } from 'react-color';
import { SxStyleProp } from 'theme-ui';
import { finnoscoreDataStyles } from '../finnoscore-data/index.styles';

export const styles = {
  control: {
    ...finnoscoreDataStyles.control,
    rowGap: '12px',
    display: 'grid !important',
    gridTemplateColumns: ['1fr', '1fr', '1fr 1fr'],
    columnGap: '18px',
  },

  headerChart: (color?: RGBColor, defaultColor = 'black') =>
    ({
      height: '10px',
      width: 6,
      mt: 2,
      color: color ? `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})` : defaultColor,
    }) as SxStyleProp,

  rankText: {
    fontSize: '12px',
    ml: 3,
  },
};
