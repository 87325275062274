import React, { MouseEventHandler } from 'react';
import { NavigationItem } from '@config';
import { SxStyleProp, useIsDesktop } from '@themed';
import { isTouchDevice } from '@modules';

export const useNestedNavItemsProps = (items?: NavigationItem[]) => {
  const [isOpen, setIsOpenWithoutLog] = React.useState(false);
  const isDesktop = useIsDesktop();
  if (!items || !isDesktop) {
    return {
      isOpen: false,
      setIsOpen: () => null,
      parentProps: { sx: {} },
      childrenGroupProps: { sx: {} },
      childrenProps: {},
    };
  }
  const setIsOpen = (val: boolean, _from?: string) => {
    if (!isTouchDevice() || _from === 'onClick') {
      // console.log('setIsOpen', val, _from);
      setIsOpenWithoutLog(val);
    }
  };

  const onClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen, 'onClick');
  };

  const parentProps = {
    onClick,
    onMouseOver: () => setIsOpen(true, 'onMouseOver'),
    onMouseOut: () => setIsOpen(false, 'onMouseOut'),
    sx: {
      position: 'relative !important',
      overflow: 'hidden',
    } as SxStyleProp,
  };
  const childrenGroupProps = {
    onMouseOver: () => setIsOpen(true, 'onMouseOver'),
    onMouseOut: () => setIsOpen(false, 'onMouseOut'),
    sx: {
      // TODO: open/collapse animation?
      position: 'absolute !important',
      display: isOpen ? 'block' : 'none',
      minWidth: 'max-content',
    } as SxStyleProp,
  };
  const childrenProps = {
    // onClick: () => { console.log('onclick children'); setIsOpen(false); },
  };

  return {
    isOpen,
    setIsOpen,
    parentProps,
    childrenGroupProps,
    childrenProps,
  };
};
