import { printStyles } from '@config';
import { Global } from '@emotion/core';
import React from 'react';

export function GlobalStyles() {
  return (
    <Global
      styles={(theme) => ({
        a: {
          // at least we should attempt to have <a> to be formatted like it were with themed components.
          background: theme?.colors[theme?.styles?.a.background],
          color: theme?.colors[theme?.styles?.a.color],
          fontSize: theme?.fontSizes[theme?.styles?.a.fontSize],
          fontWeight: theme?.fontWeights[theme?.styles?.a.fontWeight],
          fontFamily: theme?.fonts[theme?.styles?.a.fontFamily],
          textDecoration: theme?.styles?.a.textDecoration,
        },
        '::-moz-selection': { backgroundColor: theme?.colors?.primary, color: theme?.colors?.background },
        '::selection': { backgroundColor: theme?.colors?.primary, color: theme?.colors?.background },
        ...printStyles(false),
        '@media print': {
          ...printStyles(true),
        },
      })}
    />
  );
}
