import { MoreSimilarArticleProps } from '@composite';
import { ArticleFromApi } from '@types';
import { languages } from '../../i18n';
import { formatArticles } from '../../formatting';

import { getQuery } from './previewQueries';
import { graphqlRequest } from '../graphqlRequest';

interface ArticleData {
  articles: ArticleFromApi[];
}
interface MappedArticleResult {
  de: MoreSimilarArticleProps;
  en?: MoreSimilarArticleProps;
  fr?: MoreSimilarArticleProps;
}

const formatArticleResult = (articleData: ArticleData): MappedArticleResult => {
  const articlesFromApi: ArticleFromApi[] | undefined | null = articleData && articleData.articles;

  if (!articlesFromApi || articlesFromApi.length === 0) throw new Error('No blog article found');

  const germanArticleFromApi = articlesFromApi[0];
  const germanArticle = formatArticles([germanArticleFromApi], 'de', undefined, true)[0];

  const germanMoreArticleWithSameTags = germanArticleFromApi.tags
    // we retrieve multiple list for each tag, due to N:M relation
    ?.reduce((articles, tag) => {
      const newUniqueArticles = (tag.articles || [])
        // to avoid duplicate links, adding only those articles, which are not already in the list
        .filter((newArticle) => germanArticleFromApi.slug !== newArticle.slug && !articles.find((article) => article.slug === newArticle.slug));
      return articles.concat(newUniqueArticles);
    }, [] as ArticleFromApi[]);

  const germanMoreSimilarArticle = germanMoreArticleWithSameTags && formatArticles(germanMoreArticleWithSameTags, 'de', undefined, true);

  const result: MappedArticleResult = {
    de: { ...germanArticle, moreSimilarArticle: germanMoreSimilarArticle || [] },
  };

  languages.forEach((lang) => {
    const articleFromApiForLang = germanArticleFromApi[lang];

    let tagLang: 'tag' | 'tagEn' | 'tagFr';

    if (lang === 'de') {
      tagLang = 'tag';
    } else if (lang === 'en') {
      tagLang = 'tagEn';
    } else tagLang = 'tagFr';

    if (articleFromApiForLang) {
      const articleForLang = formatArticles([articleFromApiForLang], lang, [germanArticleFromApi], true)[0];

      const articleTags = articleFromApiForLang?.tags?.map((t) => t[tagLang]);
      const articleWithSimilarTags = articlesFromApi
        .filter((article) => article.tags?.some((tag) => articleTags?.includes(tag.tag)))

        .filter((f) => f.title !== germanArticle.title);

      const moreWithSimilarTagForLang = formatArticles(articleWithSimilarTags, lang, germanMoreArticleWithSameTags, true);

      result[lang] = { ...articleForLang, moreSimilarArticle: moreWithSimilarTagForLang };
    }
  });

  return result;
};

export const fetchArticleWithSlug = async (locale: string, slug: string) => {
  const query = getQuery(locale, slug);
  if (!query) throw new Error(`No query found for locale ${locale}`);

  const response = await graphqlRequest<ArticleData>(query);
  return formatArticleResult(response);
};
