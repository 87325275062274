import React from 'react';
import { ChildrenProps, Content } from '@types';
import { PostContent } from '@composite';
import { SxProps } from '../themed';
import { SectionWithContent } from './Section';
import { styles } from './StaticSectionList.styles';

interface StaticSectionListProps extends SxProps {
  content?: Content[];
}
interface StaticSectionProps extends ChildrenProps, SxProps {
  index: number;
  max: number;
  className?: string;
}

export const StaticSection = React.forwardRef<HTMLDivElement, StaticSectionProps>(({ index, max, children, ...props }, ref) => (
  <SectionWithContent
    {...props}
    className={`${props.className || ''} static-section`}
    sx={{ ...styles.section(index), ...props.sx }}
    {...(index === max - 1 ? { ref } : {})}
  >
    {children}
  </SectionWithContent>
));

export const StaticSectionList = React.forwardRef<HTMLDivElement, StaticSectionListProps>(({ content, ...props }, ref) => {
  if (!content) return null;
  return (
    <React.Fragment>
      {content.map((oneSectionContent, index) => (
        <StaticSection index={index} max={content.length} {...props} ref={ref}>
          <PostContent sx={styles.content} content={[oneSectionContent]} />
        </StaticSection>
      ))}
    </React.Fragment>
  );
});
