import { Box } from 'theme-ui';
import React from 'react';
import { RGBColor } from 'react-color';
import { fillColorStyles } from './ChartItem.styles';
import { Bar } from './Bar';
import { positionBarStyles } from './PositionBar.styles';

export const barComponentStyles = {
  bar: {
    gridArea: 'chart',
    justifySelf: 'center',
    alignSelf: 'center',
    width: 6,
    height: 3,
  },
};

interface DefaultBarComponentProps {
  percentage: number;
  color?: RGBColor;
}

export const PositionChartItem = ({ percentage, color }: DefaultBarComponentProps) => (
  <Bar sx={barComponentStyles.bar}>
    <Box sx={{ ...positionBarStyles(percentage), ...fillColorStyles(color) }} />
  </Bar>
);
