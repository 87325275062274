import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image/withIEPolyfill';
import { ChildImageEdgesType } from '@types';

export const ClientIcons = () => {
  const data = useStaticQuery(graphql`
    {
      clientIcons: allFile(filter: { internal: { description: { regex: "/images/clients/banks//" } } }) {
        edges {
          node {
            relativePath
            extension
            publicURL
            childImageSharp {
              fluid(maxWidth: 600) {
                src
                base64
                aspectRatio
                sizes
                srcSet
                originalName
              }
            }
          }
        }
      }
    }
  `);
  const createAlt = (originalName?: string) =>
    `Logo of ${originalName
      ?.substring(2, originalName.length - 3)
      .replace('_', '')
      .replace('_', ' ')}`;
  return (
    <React.Fragment>
      {(data.clientIcons.edges as ChildImageEdgesType[])
        .sort((a, b) => {
          if (a.node.relativePath < b.node.relativePath) {
            return 1;
          }
          if (b.node.relativePath > a.node.relativePath) {
            return -1;
          }
          return 0;
        })
        .map((edge) => (
          <Img
            data-cy='client-icon'
            objectFit='contain'
            fluid={edge.node.childImageSharp.fluid}
            style={{
              width: '100%',
              height: '100%',
            }}
            key={edge.node.childImageSharp.fluid.src}
            alt={createAlt(edge.node.childImageSharp.fluid.originalName)}
          />
        ))}
    </React.Fragment>
  );
};
