import React from 'react';
import { Grid, Image, SectionWithContent } from '@core';
import { ChildrenProps, PageMeta } from '@types';
import { ContactSection } from '../ContactSection';
import { contactIconStyles } from '../rapid';
import { styles } from './index.styles';
import { Video } from './Video';
import { MoreArticle } from './MoreArticle';

interface DigitalExperienceProps extends ChildrenProps {
  pageContent: PageMeta | undefined;
}

export const DigitalExperience = ({ pageContent, children }: DigitalExperienceProps) => (
  <Grid sx={styles}>
    <SectionWithContent>{children}</SectionWithContent>

    <Video content={pageContent} />

    <MoreArticle />

    <ContactSection id='rapid.contact'>
      <Image src='/images/pacman-white_2x.gif' alt='pacman animation' sx={contactIconStyles} />
    </ContactSection>
  </Grid>
);
