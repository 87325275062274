import { FsType, PageMeta, hasContentTabbedImageList } from '@types';
import { useStrapiPageContent } from 'src/content/useStrapiPageContent';

export interface Logo {
  img: string;
  link: string;
  height: number | string;
  width?: number | string | string[];
  name: string;
}

export const groupMatrix = (logosOriginal: Logo[], numOfChilds: number): Logo[][] => {
  const more = Math.ceil(logosOriginal.length / numOfChilds) * numOfChilds - logosOriginal.length;
  const logos = logosOriginal.concat(logosOriginal.slice(Math.floor(logosOriginal.length / 2), Math.floor(logosOriginal.length / 2) + more));
  return Array(Math.ceil(logos.length / numOfChilds))
    .fill('')
    .reduce((acc, _, index) => [...acc, [...logos].splice(index * numOfChilds, numOfChilds)], [] as Logo[][]);
};

export const extractTabbedImagesFromPageMeta = (pageMeta: PageMeta | undefined) => {
  const tabbedImages = pageMeta?.content?.map((content) => (hasContentTabbedImageList(content) ? content.tabbedImages : []));
  const logos: Logo[] | undefined = tabbedImages?.[0]?.map((ti) => ({
    name: ti.image.url,
    img: ti.image.url,
    link: ti.title || '',
    height: ti.image.alternativeText ? +ti.image.alternativeText : '100%',
  }));
  return logos;
};

export const usePressLogosFromPageMeta = (fsType?: FsType) => {
  const strapiPageMeta = useStrapiPageContent(`press-logos-${fsType}`);
  const tabbedImages = extractTabbedImagesFromPageMeta(strapiPageMeta);
  return tabbedImages;
};

export const logoSourceReplacer = (logos: Logo[]) => logos.map((logo) => ({ ...logo, img: logo.img.replace(/.*\//, '') }));
