/** @jsx jsx */
import { jsx, Flex, FlexProps } from 'theme-ui';
import React from 'react';
import { useDimensions, DimensionItem, getScore, useTranslate } from '@modules';
import { TriggerElementProps } from '@types';
import { useAnimationDimensionsCircle } from './animateDimensionsCircle';
import { styles } from './DimensionCircle.styles';

interface CircleItemProps extends DimensionItem, FlexProps {}

const CircleItem = ({ name, best, average, ...props }: CircleItemProps) => {
  const __ = useTranslate();

  return (
    <Flex {...props} aria-label='Dimension'>
      <Flex sx={styles.text} aria-label='Title' data-finnoscore-best={best} data-finnoscore-average={average}>
        {__(`pages.finnoscore.circle.dimensions.${name}`, true) || __(`${name}`)}
      </Flex>
    </Flex>
  );
};

export const DimensionsCircle = ({ triggerRef }: TriggerElementProps) => {
  const dimensions = useDimensions();
  const circleRef = React.useRef<HTMLDivElement | null>(null);
  const __ = useTranslate();

  useAnimationDimensionsCircle({
    triggerRef,
    circleRef: () => circleRef,
  });

  return (
    <Flex data-cy='finnoscore-dimension-circle' sx={styles.container(dimensions.length)} ref={circleRef} aria-label='Finnoscore dimension list'>
      <Flex sx={styles.best} aria-label='best-container'>
        <span role='img' aria-label='strong hand'>
          {__('Top Score')} 💪
        </span>
        <span aria-label='best'>{getScore(dimensions?.[0]?.best || 0)}</span>
      </Flex>
      <Flex sx={styles.average} aria-label='average-container'>
        <span role='img' aria-label='conservative emoji'>
          {__('Average Score')} 🙄
        </span>
        <span aria-label='average'>{getScore(dimensions?.[0]?.average || 0)}</span>
      </Flex>

      {dimensions.map((item) => (
        <CircleItem {...item} key={item.name} sx={styles.dimension(dimensions.length)} />
      ))}
    </Flex>
  );
};
