import { CTAButton, InfoButton, SubTitle, themeConfig } from '@themed';
import React from 'react';
import { AiFillWarning } from 'react-icons/ai';
import { useGlobalUpdatedInstitutionStack } from 'src/store/InstitutionState';
import { Box } from 'theme-ui';
import { stackContainer, stackModal, stackModalButtons } from './UpdateStackPreviewer.styles';

interface UpdateStackResetterProps {
  setter: (props: any) => void;
  onCancel: () => void;
  value: string;
}

export const UpdateStackResetter = ({ setter, value, onCancel }: UpdateStackResetterProps) => {
  const [, setUpdateStack] = useGlobalUpdatedInstitutionStack();

  return (
    <Box sx={stackContainer(true)}>
      <Box sx={stackModal}>
        <SubTitle>
          <AiFillWarning size={30} color={themeConfig.colors.warning} /> You have unsaved changes, you lose them if you change. Are you sure?
        </SubTitle>
        <Box sx={stackModalButtons}>
          <CTAButton
            onClick={() => {
              setUpdateStack([]);
              setter(value);
              onCancel();
            }}
          >
            Yes
          </CTAButton>
          <InfoButton onClick={onCancel}>Cancel</InfoButton>
        </Box>
      </Box>
    </Box>
  );
};
