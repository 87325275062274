/** @jsx jsx */
import React, { ElementType } from 'react';
import { jsx, SxProps, Title, SxStyleProp, SubTitle, Flex, Grid, GridProps, Paragraph } from '@themed';
import { useTranslate } from '@modules';
import { Children, Image } from '@types';
import { CTAWithOptionalLink, ParallaxImage, ImageLoader } from '@core';
import { PageTitle } from '../ui';

export interface CompactSectionContentChildren {
  contentTop?: Children;
  contentBottom?: Children;
}

export interface CompactSectionContentProps extends SxProps, CompactSectionContentChildren {
  id?: string;
  children?: Children;
  titleSx?: SxStyleProp;
  icon?: Image;
  iconSx?: SxStyleProp;
  buttonVariant?: string;
  sx?: SxStyleProp;
  subtitle?: string;
  paragraph?: string;
  button?: string;
  buttonLink?: string;
  imageParallax?: Image;
  imageParallaxResize?: boolean;
  className?: string;
  price?: string;
  titleAs?: ElementType<any>;
}

export const CompactSectionContentStyles = React.forwardRef<HTMLDivElement, GridProps>(({ children, className, ...props }, ref) => (
  <Grid
    variant='CompactSectionContent'
    {...props}
    ref={ref}
    css={{ width: '100%' }}
    className={`${className} wrapper`}
    data-cy='compact-section-styles'
  >
    {children}
  </Grid>
));

const styles: SxStyleProp = {
  link: {
    '& > button': {
      alignSelf: 'flex-start',
    },
  },
  image: {
    height: 'giantIcon',
    borderRadius: 'unset',
    img: {
      objectFit: 'cover !important',
    } as SxStyleProp,
  },
  aside: {},
  paragraph: {
    span: {
      output: {
        maxWidth: ['19em', '100%'],
      },
    },
  },
};

export const CompactSectionContent = ({
  id,
  children,
  buttonVariant,
  titleSx,
  titleAs = 'h2',
  subtitle,
  paragraph,
  button,
  buttonLink,
  imageParallax,
  imageParallaxResize,
  className,
  contentTop,
  contentBottom,
  price,
  ...props
}: CompactSectionContentProps) => {
  const __ = useTranslate();
  const subTitleStr = (id && __(`pages.${id}.subtitle`, true)) || subtitle;
  const paragraphStr = (id && __(`pages.${id}.paragraph`, true)) || paragraph;
  const buttonStr = (id && __(`pages.${id}.button`, true)) || button;
  const mergedButtonLink = (id && __(`pages.${id}.buttonLink`, true)) || buttonLink;
  const imageParallaxStr = ((id && __(`pages.${id}.imageParallax`, true)) as Image | undefined) || imageParallax;
  const priceStr = (id && __(`pages.${id}.price`, true)) || price;
  return (
    <React.Fragment>
      <Flex {...props} className={`${className} content`} id={`pages-${id?.replace(/\./gi, '-')}`}>
        {contentTop}

        {__(`pages.${id}.pagetitle`, true) && <PageTitle>{__(`pages.${id}.pagetitle`, true)}</PageTitle>}
        {__(`pages.${id}.title`, true) && (
          <Title data-cy='compact-section-title' displayAs={titleAs} sx={titleSx}>
            {__(`pages.${id}.title`, true)}
          </Title>
        )}
        {subTitleStr && <SubTitle data-cy='compact-section-subtitle'>{subTitleStr}</SubTitle>}

        {__(`pages.${id}.image`, true) && (
          <ImageLoader src={__(`pages.${id}.image.url`, true)} alt={__(`pages.${id}.image.alternativeText`, true)} sx={styles.image} />
        )}

        {imageParallaxStr && (
          <ParallaxImage
            data-cy='compact-section-parallaxImg'
            src={imageParallaxStr?.url}
            alt={imageParallaxStr?.alternativeText}
            sx={styles.image}
            resize={imageParallaxResize}
          />
        )}

        {paragraphStr && (
          <Paragraph data-cy='compact-section-paragraph' className='paragraph' variant='styles.p' sx={styles.paragraph}>
            {paragraphStr}
          </Paragraph>
        )}

        {priceStr && (
          <Paragraph id='compact-section-price' data-cy='compact-section-price' className='paragraph' variant='styles.p' sx={{ fontWeight: 'bold' }}>
            {priceStr}
          </Paragraph>
        )}

        {buttonStr && (
          <CTAWithOptionalLink
            id='compact-section-button-link'
            data-cy='compact-section-button'
            aria-label={buttonStr}
            to={mergedButtonLink}
            sx={styles.link}
            buttonVariant={buttonVariant}
          >
            {buttonStr}
          </CTAWithOptionalLink>
        )}

        {contentBottom}
      </Flex>

      {children && (
        <Flex data-cy='compact-section-aside' as='aside' sx={styles.aside}>
          {children}
        </Flex>
      )}
    </React.Fragment>
  );
};
