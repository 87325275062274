import { SxStyleProp } from 'theme-ui';

export const styles = {
  box: {
    display: 'grid', // works better with chidlren (list) having overflow + height
    bg: ['background', 'background', 'background'],
    boxShadow: ['none', 'none', '0 0 6px 0 rgba(0, 0, 0, 0.1)'],
    table: {
      width: '100%',
    },
  },
  list: {
    // variant: 'debug.responsive',
    height: '100%',
    overflow: 'auto',
    overflowX: 'hidden',
    /* Hide scrollbar for Chrome, Safari and Opera */
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    msOverflowStyle: 'none' /* IE and Edge */,
    scrollbarWidth: 'none' /* Firefox */,
  } as SxStyleProp,
};
