import { DropDownArrow, DropDownContext, DropDownContextProvider, DropDownProps, DropDownWithContext, ImageLoader } from '@core';
import { getScore, useTranslate } from '@modules';
import { BoxProps, Flex, SubTitle, SxProps } from '@themed';
import { Children, ChildrenProps, ComparisonSubject, SxStyles } from '@types';
import React from 'react';
import { headerStyles } from './ComparisonSelector.styles';

interface ComparisonHeaderProps extends SxProps {
  subject?: ComparisonSubject;
  chartComponent: Children;
  colorPickerComponent?: Children;
}

export const ComparisonHeader = ({ subject, chartComponent, colorPickerComponent, ...props }: ComparisonHeaderProps) => {
  const __ = useTranslate();
  const { disabled } = React.useContext(DropDownContext);
  return (
    <Flex variant='flex.rowLeft' {...props} sx={{ ...headerStyles.box, ...props?.sx }}>
      <Flex variant='flex.colCenter' sx={headerStyles.scoreArea}>
        {colorPickerComponent || (
          <SubTitle id='institutionScore' sx={headerStyles.score(!!subject?.winner)}>
            {getScore(subject?.total)}
          </SubTitle>
        )}
      </Flex>

      <Flex sx={headerStyles.titleArea} data-cy='comparison-header-text'>
        <Flex variant='flex.rowCenter'>
          {subject?.image && <ImageLoader src={subject?.image.url} alt={subject?.image.alternativeText} sx={headerStyles.image} />}
          <Flex variant='flex.colLeft'>
            <SubTitle sx={headerStyles.title(__(subject?.name))}>{__(subject?.name)}</SubTitle>
            {chartComponent}
          </Flex>
        </Flex>
        {!disabled && <DropDownArrow />}
      </Flex>
    </Flex>
  );
};

const styles: SxStyles = {
  dropDown: {
    boxShadow: ['none', 'none', '0 0 6px 0 rgba(0, 0, 0, 0.1)'],
  },
};

interface Props extends ComparisonHeaderProps, BoxProps, ChildrenProps, SxProps {
  header?: Children;
}

const ComparisonDropDownComponent = ({ subject, chartComponent, colorPickerComponent, header, ...props }: Props) => (
  <DropDownWithContext
    {...props}
    sx={{ ...styles.dropDown, ...props.sx }}
    header={
      header || <ComparisonHeader subject={subject} sx={props.sx} chartComponent={chartComponent} colorPickerComponent={colorPickerComponent} />
    }
  />
);

export const ComparisonDropDownWithContext = ({ isOpen, setIsOpen, disabled, ...props }: Props & DropDownProps) => (
  <DropDownContextProvider isOpen={isOpen} setIsOpen={setIsOpen} disabled={disabled}>
    <ComparisonDropDownComponent {...props} />
  </DropDownContextProvider>
);
