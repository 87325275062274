import { useComparisonLink } from '@modules';
import { FsType } from '@types';
import React, { createContext, useEffect } from 'react';

type ComparisonHook = (a: string, b: string, fsType: FsType) => string;

type ComparisonLinkContextType = {
  useComparisonHook: ComparisonHook;
};

const ComparisonLinkContext = createContext<ComparisonLinkContextType | undefined>(undefined);

export const ComparisonLinkProvider = ({
  children,
  useComparisonHook = useComparisonLink,
}: {
  children: React.ReactNode;
  useComparisonHook?: ComparisonHook;
}) => <ComparisonLinkContext.Provider value={{ useComparisonHook }}>{children}</ComparisonLinkContext.Provider>;

const useComparisonLinkContext = () => {
  const context = React.useContext(ComparisonLinkContext);

  useEffect(() => {
    if (typeof window !== 'undefined' && !context) {
      // this warning mostly only useful client runtime, so we just simply want to disable logging during the build process
      console.warn('[Warning] useComparisonLinkContext is being used outside of a ComparisonLinkProvider. Falling back to useComparisonLink.');
    }
  }, []);

  return context?.useComparisonHook ?? useComparisonLink;
};

export const useContextualComparisonLink = (a: string, b: string, fsType: FsType) => {
  const getComparisonLink = useComparisonLinkContext();
  return getComparisonLink(a, b, fsType);
};
