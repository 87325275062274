import { isInternalLink, useLocale } from '@modules';

export interface ButtonFromApi {
  link: string;
  title: string;
}

export interface ContentWithButton {
  button?: ButtonFromApi;
}

export function useButtonContent(buttonContent: Required<ContentWithButton> | null) {
  const locale = useLocale();
  const isInternal = isInternalLink(`${buttonContent?.button?.link}`);

  return {
    link: isInternal ? `/${locale}/${buttonContent?.button?.link}` : buttonContent?.button?.link,
    target: isInternal ? '_self' : '_blank',
    text: buttonContent?.button?.title,
  };
}
