import React from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/all';
import { __tr, __selectAll, __select, __el } from '@modules';
import { scrub, withAnimation } from '@core';
import { getBreakpoint } from '@themed';

interface AnimateEyeglassesProps {
  triggerRef: () => React.RefObject<HTMLDivElement>;
  boxRef: () => React.RefObject<HTMLDivElement>;
}
interface CreateEyeglassesScrollerProps extends AnimateEyeglassesProps {
  isMobile?: boolean;
}
const getParallaxConfig = (item: HTMLElement) => {
  const zIndex = Number(gsap.getProperty(item, 'zIndex') || 0);
  return {
    y: zIndex * 50,
  };
};

const animateRevealEyeglass = (boxRef: () => React.RefObject<HTMLDivElement>, props?: gsap.TimelineVars, isMobile?: boolean) => {
  const tl = gsap.timeline(props).set(__selectAll('[aria-label=top-logo]', boxRef), {
    opacity: 0,
    scale: 0,
  });
  if (!isMobile) {
    tl.to(__el(boxRef), { duration: 2 }).fromTo(
      __select('[aria-label=eyeglasses]', boxRef),
      {
        opacity: 0,
        scale: 0,
        transformOrigin: '50% -100%',
      },
      {
        opacity: 1,
        scale: 1,
        ease: 'back',
        duration: 2,
      }
    );
  } else {
    tl.set(__select('[aria-label=eyeglasses]', boxRef), {
      opacity: 1,
      scale: 1,
    }).to(__el(boxRef), { duration: 2 });
  }
  tl.to(
    __selectAll('[aria-label=top-logo]', boxRef),
    {
      opacity: 1,
      scale: 1,
      stagger: { amount: 3, from: 'end', ease: 'linear' },
      ease: 'back',
    },
    '-=2'
  )
    .to(
      __selectAll('[aria-label^=dimension]', boxRef),
      {
        y: '+=15vh',
        duration: 8,
      },
      0
    )
    .to(
      __selectAll('[aria-label=top-logo]', boxRef),
      {
        y: (_, item) => `+=${getParallaxConfig(item).y}`,
        duration: 8,
      },
      0
    );
  return tl;
};

const createEyeglassesScroller = ({ triggerRef, boxRef, isMobile }: CreateEyeglassesScrollerProps) => {
  const trigger = __tr(triggerRef);

  if (trigger) {
    ScrollTrigger.create({
      refreshPriority: 2, // between Contact and Rankings
      trigger,
      // markers: process.env.NODE_ENV === 'development',
      ...scrub(),
      snap: isMobile
        ? undefined
        : {
            snapTo: (value: number) => {
              if (Math.abs(value - 0.5) < 0.15) return 0.5;
              return value;
            },
            duration: 1.3,
          },
      start: () => 'top bottom',
      end: () => 'bottom top',
      animation: animateRevealEyeglass(boxRef, {}, isMobile),
      invalidateOnRefresh: true,
    });
  }
};

export const animateEyeglasses = (props: AnimateEyeglassesProps) => {
  gsap.registerPlugin(ScrollTrigger);

  ScrollTrigger.saveStyles([__select('[aria-label]', props.boxRef)]);
  ScrollTrigger.matchMedia({
    [`(min-width: ${getBreakpoint(1)})`]: () => createEyeglassesScroller({ ...props, isMobile: false }),
    [`(max-width: ${getBreakpoint(1)})`]: () => createEyeglassesScroller({ ...props, isMobile: true }),
  });
};

export const useAnimationEyeglasses = (props: AnimateEyeglassesProps) => withAnimation<AnimateEyeglassesProps>(animateEyeglasses, props);
