import { TagFromApi, ArticleFromApi, AuthorFromApi, Locale, Article, Author, Tag } from '@types';
import { ContentTypes } from '../../post';
import { formatContent, formatIcon, formatImages, formatThumbnails, getMainResource } from '../commonFormatting';

interface Slugable {
  slug: string;
  slugEn: string;
  slugFr: string;
}

const getAllSlugsByLocale = (slugable: Slugable, contentType: ContentTypes) => ({
  de: `/${contentType}/${slugable.slug}`,
  en: `/${contentType}/${slugable.slugEn}`,
  fr: `/${contentType}/${slugable.slugFr}`,
});

const formatAuthorByLocale = (author: AuthorFromApi, locale: Locale): Author => {
  switch (locale) {
    case 'en':
      return {
        name: author.name,
        createdAt: author.createdAt,
        title: author.titleEn,
        description: author.descriptionEn,
        slug: author.slugEn,
        slugs: getAllSlugsByLocale(author, ContentTypes.author),
        text: author.textEn,
        avatar: formatIcon(author.avatar),
        language: 'en',
      };
    case 'fr':
      return {
        name: author.name,
        createdAt: author.createdAt,
        title: author.titleFr,
        description: author.descriptionFr,
        slug: author.slugFr,
        slugs: getAllSlugsByLocale(author, ContentTypes.author),
        text: author.textFr,
        avatar: formatIcon(author.avatar),
        language: 'fr',
      };
    default: {
      return {
        name: author.name,
        createdAt: author.createdAt,
        title: author.title,
        description: author.description,
        slug: author.slug,
        slugs: getAllSlugsByLocale(author, ContentTypes.author),
        text: author.text,
        avatar: formatIcon(author.avatar),
        language: 'de',
      };
    }
  }
};

const formatTagByLocale = (tag: TagFromApi, locale: Locale): Tag => {
  switch (locale) {
    case 'en':
      return {
        createdAt: tag.createdAt,
        slug: tag.slugEn,
        slugs: getAllSlugsByLocale(tag, ContentTypes.tag),
        description: tag.descriptionEn,
        name: tag.tagEn,
        title: tag.titleEn,
        language: 'en',
      };
    case 'fr':
      return {
        createdAt: tag.createdAt,
        slug: tag.slugFr,
        slugs: getAllSlugsByLocale(tag, ContentTypes.tag),
        description: tag.descriptionFr,
        name: tag.tagFr,
        title: tag.titleFr,
        language: 'fr',
      };
    default:
      return {
        createdAt: tag.createdAt,
        slug: tag.slug,
        slugs: getAllSlugsByLocale(tag, ContentTypes.tag),
        description: tag.description,
        name: tag.tag,
        title: tag.title,
        language: 'de',
      };
  }
};

export const formatAuthors = (authors: AuthorFromApi[], locale: Locale, articles: Article[]): Author[] =>
  authors.map((author) => {
    const formattedAuthor = formatAuthorByLocale(author, locale);
    const articlesByAuthor = articles.filter((article) => article.author && article.author.slug === formattedAuthor.slug);

    return {
      ...formattedAuthor,
      articles: articlesByAuthor,
    };
  });

export const formatTags = (tags: TagFromApi[], locale: Locale, articles?: Article[]): Tag[] =>
  tags.map((tag) => {
    const formattedTag = formatTagByLocale(tag, locale);
    if (!articles) return formattedTag;

    const articlesWithTag = articles.filter((article) => article.tags && article.tags.map((t) => t.slug).includes(formattedTag.slug));

    return {
      ...formattedTag,
      articles: articlesWithTag,
    };
  });

const isArticleActive = (article: ArticleFromApi) => {
  const today = new Date(Date.now());
  return article.publishDate && new Date(article.publishDate) <= today && article.publishedAt && new Date(article.publishedAt) <= today;
};

const getAdditionalDataForArticle = (article: ArticleFromApi, locale: Locale) => ({
  covers: article.covers && formatImages(article.covers),
  thumbnails: article.thumbnails && formatThumbnails(article.thumbnails),
  author: article.author ? formatAuthorByLocale(article.author, locale) : undefined,
  tags: article.tags ? formatTags(article.tags, locale) : undefined,
  slugs: {
    de: `/${ContentTypes.blog}/${article.slug}`,
    en: `/${ContentTypes.blog}/${article.en?.slug || article.slug}`,
    fr: `/${ContentTypes.blog}/${article.fr?.slug || article.slug}`,
  },
});

const getBasicDataForArticle = (article: ArticleFromApi) => ({
  title: article.title,
  slug: article.slug,
  subtitle: article.subtitle,
  published: article.published,
  publishDate: article.publishDate,
  modified: article.modified,
  content: formatContent(article.content),
});

export const formatArticles = (articles: ArticleFromApi[], locale: Locale, mainArticles?: ArticleFromApi[], includeDraft?: boolean): Article[] => {
  const activeArticles = articles.filter((post) => !!post).filter((post) => (includeDraft ? true : isArticleActive(post)));

  if (locale === 'de') {
    return activeArticles.map((article) => ({
      language: 'de',
      ...getBasicDataForArticle(article),
      ...getAdditionalDataForArticle(article, 'de'),
    }));
  }

  return activeArticles.map((article) => {
    // non-main articles don't have all the parts that german articles do, so we have to get them from the german article
    const germanArticle = getMainResource(article.slug, locale, mainArticles);
    if (!germanArticle) throw new Error(`Non-German articles must have a main (German) article! (${article.slug})`);
    return {
      language: locale,
      ...getBasicDataForArticle(article),
      ...getAdditionalDataForArticle(germanArticle, locale),
    };
  });
};
