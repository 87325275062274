/** @jsx jsx */
import React from 'react';
import { jsx, useIsPhone } from '@themed';
import { CompactSection, SectionWithContent, ParallaxImage, PureSection, SimpleSectionContent } from '@core';

import { useTranslate } from '@modules';
import { FS_TYPES } from '@types';
import { ContactSection } from '../ContactSection';
import { LandingPress, BankPress } from '../Press';
import { FinnoscoreBanner } from '../banners/FinnoscoreBanner';
import { styles } from './index.styles';
import { Howtodoit, HowtodoitBackground } from './Howtodoit';
import { Oursupport } from './Oursupport';
import { Ourservices } from './Ourservices';

import { FinniRevealCards } from './FinniRevealCards';
import { ClutchWidget } from './ClutchWidget';

export const LandingPage = () => {
  const initRef = React.useRef(null);
  const neobanksRef = React.useRef(null);
  const oursupportRef = React.useRef(null);
  const howtodoitRef = React.useRef(null);
  const ourservicesRef = React.useRef(null);
  const contactRef = React.useRef(null);
  const __ = useTranslate();

  const isPhone = useIsPhone();

  React.useEffect(() => {
    window.addEventListener('orientationchange', () => window.location.reload());
    return () => {
      window.addEventListener('orientationchange', () => window.location.reload());
    };
  }, []);

  return (
    <React.Fragment>
      <PureSection sx={styles.init.section}>
        <FinnoscoreBanner />
        <SimpleSectionContent sx={styles.init.wrapper}>
          <CompactSection sx={styles.init.textAndFinni} ref={initRef} id='landing.init' titleAs='h1'>
            <FinniRevealCards image='cards.jpg' triggerRef={() => neobanksRef} />
          </CompactSection>
          {isPhone ? <BankPress sx={{ my: 5 }} fsType={FS_TYPES[0]} /> : <LandingPress fsType={FS_TYPES[0]} />}
          <ClutchWidget />
        </SimpleSectionContent>
      </PureSection>

      <CompactSection data-cy='neobanks-section' ref={neobanksRef} id='landing.neobanks'>
        <ParallaxImage src='cards.jpg' alt={__('pages.landing.neobanks.parallaxBgAlt')} sx={styles.neobanksImage} />
      </CompactSection>

      <SectionWithContent data-cy='oursupport-section' id='oursupport' sx={{ zIndex: 111, position: 'relative', bg: 'primary' }} ref={oursupportRef}>
        <Oursupport triggerRef={() => oursupportRef} />
      </SectionWithContent>

      <SectionWithContent data-cy='howtodoit-section' sx={styles.howToDoIt} ref={howtodoitRef} contentComponent={HowtodoitBackground}>
        <Howtodoit />
      </SectionWithContent>

      <SectionWithContent data-cy='ourservice-section' sx={{ zIndex: 113, position: 'relative', bg: 'background' }} ref={ourservicesRef}>
        <Ourservices />
      </SectionWithContent>

      <ContactSection data-cy='contact-section' id='landing.contact' ref={contactRef} triggerRef={() => ourservicesRef} />
    </React.Fragment>
  );
};
