import React from 'react';
import { Box, Image, SxProps } from 'theme-ui';
import { contactIconStyles } from '../../../../rapid';

interface LoadingPacmanProps {
  sx?: SxProps;
}

export const pacmanLoader = {
  display: 'flex',
  justifyContent: 'center',
};

function LoadingPacman(props: LoadingPacmanProps) {
  return (
    <Box {...props} sx={{ ...pacmanLoader, ...props.sx }}>
      <Image id='pacman-loader-image' src='/images/pacman-white_2x.gif' alt='pacman animation' sx={contactIconStyles} />
    </Box>
  );
}

export default LoadingPacman;
