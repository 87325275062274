import * as React from 'react';

const SvgPlannedRoute = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={82} height={76} viewBox='0 0 82 76' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g id='planned-route_svg__Page-1' stroke='none' strokeWidth={1} fill='none' fillRule='evenodd'>
      <g id='planned-route_svg__Landing-Full' transform='translate(-969 -2081)'>
        <g id='planned-route_svg__Group-11' transform='translate(691 1490)'>
          <g id='planned-route_svg__Group' transform='translate(233 543)'>
            <g id='planned-route_svg__planned-route' transform='translate(45 48)'>
              <path
                d='M66.801 0C75.195 0 82 6.835 82 15.266c0 6.506-5.066 14.693-15.199 24.56-10.133-9.867-15.199-18.054-15.199-24.56C51.602 6.835 58.407 0 66.802 0zm0 8.629c-3.65 0-6.608 2.972-6.608 6.637 0 3.666 2.958 6.638 6.608 6.638 3.65 0 6.608-2.972 6.608-6.638 0-3.665-2.958-6.637-6.608-6.637z'
                id='planned-route_svg__Combined-Shape'
                fill='#00BD59'
              />
              <path
                d='M24.367 56.515a12.412 12.412 0 018.928 9.529l1.115 5.53A3.696 3.696 0 0130.787 76H3.69a3.696 3.696 0 01-3.623-4.426l1.115-5.53a12.411 12.411 0 018.928-9.53 12.462 12.462 0 007.128 2.228 12.46 12.46 0 007.128-2.227zM17.239 37.17c4.988 0 9.032 4.012 9.032 8.961 0 4.949-4.044 8.96-9.032 8.96s-9.032-4.011-9.032-8.96c0-4.949 4.044-8.96 9.032-8.96z'
                id='planned-route_svg__Combined-Shape'
                fill='#000'
              />
              <path
                d='M40.515 75.668h7.93a1.987 1.987 0 001.982-1.991c0-1.1-.887-1.991-1.982-1.991h-7.93a1.987 1.987 0 00-1.982 1.99c0 1.1.887 1.992 1.982 1.992zm15.722-1.837c3.03-1.705 4.996-4.49 5.54-7.673a1.99 1.99 0 00-1.618-2.3 1.983 1.983 0 00-2.29 1.626c-.34 1.996-1.582 3.754-3.57 4.873a1.996 1.996 0 00-.76 2.71 1.978 1.978 0 002.698.764zm3.414-15.994c-1.839-2.338-4.623-3.922-8.064-4.495a1.983 1.983 0 00-2.28 1.639 1.99 1.99 0 001.63 2.29c2.493.415 4.392 1.495 5.602 3.034a1.977 1.977 0 002.785.328 1.997 1.997 0 00.327-2.796zm-14.82-5.11c-2.538-.55-4.46-1.68-5.663-3.183a1.977 1.977 0 00-2.787-.307 1.997 1.997 0 00-.305 2.8c1.795 2.245 4.522 3.846 7.918 4.583a1.983 1.983 0 002.357-1.526 1.991 1.991 0 00-1.52-2.367zm-6.878-8.201c.43-1.867 1.783-3.531 3.938-4.63a1.995 1.995 0 00.869-2.678 1.978 1.978 0 00-2.666-.873c-3.174 1.62-5.302 4.238-6.004 7.283a1.992 1.992 0 001.484 2.389 1.982 1.982 0 002.379-1.491zm9.864-6.015l.363-.01 7.504-.003a1.987 1.987 0 001.983-1.991c0-1.1-.888-1.992-1.983-1.992h-7.137c-.294 0-.585.006-.872.016a1.988 1.988 0 00-1.91 2.061 1.986 1.986 0 002.052 1.919z'
                id='planned-route_svg__Path-13'
                fill='#000'
                fillRule='nonzero'
                transform='rotate(180 47.922 55.092)'
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SvgPlannedRoute;
