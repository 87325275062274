import * as React from 'react';

const SvgQuestionResponse = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={76} height={40} viewBox='0 0 76 40' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g id='QuestionResponse_svg__Page-1' stroke='none' strokeWidth={1} fill='none' fillRule='evenodd'>
      <g id='QuestionResponse_svg__Case-Studies-2b' transform='translate(-756 -568)' fillRule='nonzero'>
        <g id='QuestionResponse_svg__Group-7' transform='translate(734 528)'>
          <g id='QuestionResponse_svg__QuestionResponse' transform='translate(22 40)'>
            <path
              d='M59 0c9.389 0 17 7.611 17 17l-.004.364a16.986 16.986 0 01-7.414 13.68l-.286.19.143.33c.739 1.628 1.753 3.16 3.048 4.598 1.266 1.407.05 3.622-1.816 3.31l-.56-.096c-5.749-1.02-9.998-2.748-12.73-5.273L56.275 34 50 34a16.93 16.93 0 01-10.891-3.946 18.578 18.578 0 002.502-3.123A12.945 12.945 0 0050 30h7.11a2 2 0 011.478.655l.19.202c1.37 1.403 3.496 2.573 6.384 3.475l.217.066-.009-.016a20.436 20.436 0 01-1.44-3.423 2 2 0 01.993-2.385A12.995 12.995 0 0072 17c0-7.18-5.82-13-13-13h-9a12.948 12.948 0 00-8.39 3.07 18.525 18.525 0 00-2.501-3.124A16.933 16.933 0 0150 0h9z'
              id='QuestionResponse_svg__Combined-Shape'
              fill='#00BD59'
            />
            <path
              d='M60.086 11.586a2 2 0 012.945 2.701l-.117.127-9.5 9.5a2 2 0 01-2.701.117l-.127-.117-5-5a2 2 0 012.701-2.945l.127.117L52 19.672l8.086-8.086z'
              id='QuestionResponse_svg__Path-12'
              fill='#00BD59'
            />
            <path
              d='M26 0c9.389 0 17 7.611 17 17s-7.611 17-17 17h-6.275l-.106.103c-2.732 2.525-6.981 4.254-12.73 5.273l-.56.097c-1.867.31-3.082-1.904-1.816-3.31 1.295-1.44 2.31-2.97 3.048-4.599l.143-.33-.286-.19a16.986 16.986 0 01-7.414-13.68L0 17C0 7.611 7.611 0 17 0zm0 4h-9C9.82 4 4 9.82 4 17c0 4.93 2.767 9.364 7.077 11.574a2 2 0 01.993 2.385 20.436 20.436 0 01-1.44 3.423l-.01.016.218-.066c2.888-.902 5.014-2.072 6.383-3.475l.191-.202A2 2 0 0118.891 30L26 30c7.18 0 13-5.82 13-13S33.18 4 26 4zm-4.744 17.752c1.056 0 1.92.864 1.92 1.92a1.905 1.905 0 01-1.92 1.896 1.885 1.885 0 01-1.896-1.896c0-1.056.84-1.92 1.896-1.92zM21.496 8c3.72 0 5.664 2.352 5.664 4.776 0 1.896-.96 3.24-2.376 4.248l-.984.696c-.84.6-1.296 1.344-1.296 2.328v.144h-2.592c-.048-.312-.096-.624-.096-.984 0-1.272.528-2.448 1.896-3.48l1.152-.864c.72-.528 1.032-1.176 1.032-1.896 0-1.104-.792-2.16-2.4-2.16-1.704 0-2.448 1.368-2.448 2.712 0 .288.024.552.096.768l-3.024-.12c-.096-.336-.12-.72-.12-1.08C16 10.496 17.944 8 21.496 8z'
              id='QuestionResponse_svg__Combined-Shape-Copy-11'
              fill='#000'
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SvgQuestionResponse;
