import * as React from 'react';

const SvgBulbRecycle = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={79} height={83} viewBox='0 0 79 83' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g id='bulb-recycle_svg__Page-1' stroke='none' strokeWidth={1} fill='none' fillRule='evenodd'>
      <g id='bulb-recycle_svg__Landing-Full' transform='translate(-978 -1716)' fillRule='nonzero'>
        <g id='bulb-recycle_svg__Group-11' transform='translate(691 1490)'>
          <g id='bulb-recycle_svg__Group-3' transform='translate(240 181)'>
            <g id='bulb-recycle_svg__bulb-recycle' transform='translate(47 45)'>
              <path
                d='M44.412 61.681v1.957c0 1.22-.99 2.21-2.21 2.21h-8.078a2.21 2.21 0 01-2.21-2.21V61.68h12.498zm0-6.25v4.167H31.914v-4.166h12.498zm-6.249-29.164c8.053 0 14.58 6.529 14.58 14.582 0 5.305-2.831 9.948-7.066 12.5H30.649c-4.235-2.552-7.067-7.195-7.067-12.5 0-8.053 6.528-14.582 14.581-14.582zm-.842 3.16l-.114.004a11.46 11.46 0 00-10.442 10.254 1.042 1.042 0 102.073.21 9.377 9.377 0 018.54-8.388 1.042 1.042 0 00-.17-2.076z'
                id='bulb-recycle_svg__Combined-Shape-Copy-12'
                fill='#000'
              />
              <path
                d='M6.237 57.096l.193.044 9.096 2.437a2.651 2.651 0 01-1.18 5.166l-.192-.044-2.7-.723c9.72 13.596 28.365 17.843 43.14 9.312a33.04 33.04 0 005.175-3.691 2.65 2.65 0 113.481 4 38.342 38.342 0 01-6.006 4.283c-17.391 10.042-39.4 4.838-50.555-11.466l-.821 3.07a2.651 2.651 0 01-3.054 1.919l-.193-.045a2.652 2.652 0 01-1.919-3.054l.044-.193 2.437-9.096a2.651 2.651 0 013.054-1.92zM50.533 8.713a38.34 38.34 0 016.711 3.06c17.391 10.041 23.89 31.705 15.35 49.518l3.068-.822a2.651 2.651 0 013.189 1.686l.058.19a2.652 2.652 0 01-1.685 3.188l-.19.058-9.095 2.438a2.651 2.651 0 01-3.189-1.686l-.058-.189-2.437-9.097a2.651 2.651 0 015.063-1.561l.058.189.724 2.7c6.912-15.218 1.267-33.49-13.507-42.02a33.039 33.039 0 00-5.784-2.638 2.651 2.651 0 011.724-5.014zM36.54.622l.168.155 6.659 6.659c.98.98 1.032 2.539.155 3.58l-.155.17-6.659 6.659a2.65 2.65 0 01-3.904-3.581l.155-.169 1.977-1.977C18.302 13.74 5.302 27.765 5.302 44.826c0 2.144.205 4.26.608 6.329A2.651 2.651 0 11.706 52.17 38.35 38.35 0 010 44.826C0 24.743 15.51 8.282 35.205 6.772l-2.246-2.246a2.652 2.652 0 01-.155-3.58l.155-.17a2.651 2.651 0 013.58-.154z'
                id='bulb-recycle_svg__Combined-Shape'
                fill='#00BD59'
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SvgBulbRecycle;
