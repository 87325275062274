import React from 'react';
import { Grid } from 'theme-ui';
import { useAnimationCustomScroll } from '../rapid/animateCustomScroll';
import { getAnimation } from '../rapid/animationForCards';
import { ReferenceProps } from '../rapid/Reference';
import { Thumbnail } from '../rapid/Thumbnail';
import { referenceStyles } from './DevelopmentReference.styles';

export const Reference = ({ title, thumbnails }: ReferenceProps) => {
  const asideRef = React.useRef(null);

  const animationFunction = getAnimation(title);

  useAnimationCustomScroll({
    triggerRef: () => asideRef,
    animation: () => animationFunction(() => asideRef),
    start: () => 'top 30%',
    end: () => 'bottom 70%',
  });

  return (
    <Grid as='aside' sx={referenceStyles} ref={asideRef}>
      <Thumbnail thumbnails={thumbnails} />
    </Grid>
  );
};
