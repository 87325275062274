import { ListItem } from '@core';
import { Country, CountryCalculationType, FsType } from '@types';
import { useTranslate } from './i18n';
import { ALL_COUNTRIES } from './formatting';

export const convertCountriesToListItems = (countries: Country[]): ListItem[] => {
  const countryListItems = countries.map((country) => ({
    ...country,
    icon: country.flag,
    text: country.name || country.countryCode,
    id: country.countryCode,
  }));

  const sortedCountryListItems = countryListItems.sort((a, b) => {
    if (a.text < b.text) {
      return -1;
    }
    if (a.text > b.text) {
      return 1;
    }
    return 0;
  });

  return sortedCountryListItems;
};

export const convertCountriesToCountryCalculationListItems = (countries: Country[], fsType?: FsType) => {
  const __ = useTranslate();
  const averageText = __('common.average');
  const bestText = __('common.best');

  const sortedCountries = countries?.sort((a, b) => {
    if (a.countryCode < b.countryCode) {
      return -1;
    }
    if (a.countryCode > b.countryCode) {
      return 1;
    }
    return 0;
  });

  const countriesList =
    fsType === 'privatebank' || fsType === 'bankMiddleEast'
      ? sortedCountries?.filter((country) => country.countryCode.match(ALL_COUNTRIES))
      : sortedCountries;

  const countryCalulationListWithAverage = countriesList?.reduce(
    (listItems, country) => [
      ...listItems,
      {
        id: `${country.countryCode}.${CountryCalculationType.Average}`,
        title: country.countryCode,
        icon: country.flag,
        text: averageText,
      },
      {
        title: country.countryCode,
        id: `${country.countryCode}.${CountryCalculationType.Best}`,
        text: bestText,
      },
    ],
    [] as ListItem[]
  );

  return countryCalulationListWithAverage;
};
