/** @jsx jsx */
import { SectionWithContent } from '@core';
import { Flex, Grid, jsx } from '@themed';
import { ChildrenProps } from '@types';
import { DefaultErrorMessage } from '../finnoscore-data/DefaultErrorMessage';
import { finnoscoreDataStyles as styles } from '../finnoscore-data/index.styles';
import { PacmanLoader } from '../finnoscore-data/PacmanLoader';

export interface FinnoscoreListSectionMeta {
  isLoading?: boolean;
  error?: Error | null;
}

const ContentSection = ({ children: sectionChildren }: ChildrenProps) => <Flex sx={styles.page}>{sectionChildren}</Flex>;

interface FinnoscoreListSectionProps extends FinnoscoreListSectionMeta, ChildrenProps {
  withSection?: boolean;
}

export const FinnoscoreListSection = ({ children, isLoading, error, withSection = true }: FinnoscoreListSectionProps) => {
  const content = (
    <Grid sx={styles.content}>
      {isLoading && <PacmanLoader id='loader' />}
      {error && <DefaultErrorMessage id='loader' sx={styles.error} error={error} />}

      {children}
    </Grid>
  );

  if (!withSection) {
    return content;
  }

  return (
    <SectionWithContent sx={styles.section} contentComponent={ContentSection}>
      {content}
    </SectionWithContent>
  );
};
