import React from 'react';

export const useHeight = (callback?: () => void) => {
  const [height, setHeight] = React.useState(1000);

  const action = (h: number) => {
    setHeight(h);
    if (callback) callback();
  };

  React.useLayoutEffect(() => {
    action(window?.innerHeight);
    window.addEventListener('resize', () => action(window?.innerHeight));
  }, []);

  return height;
};
