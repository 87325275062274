/** @jsx jsx */
import React from 'react';
import { jsx, Grid, Box } from '@themed';
import { ClientIcons } from './ClientIcons';
import { useAnimationClients } from './animateClients';
import { styles } from './OurService.styles';

export const Ourservices = () => {
  const clientsRef = React.useRef(null);
  const clientAnimationTriggerRef = React.useRef(null);

  useAnimationClients({ triggerRef: () => clientAnimationTriggerRef, iconsRef: () => clientsRef });

  return (
    <React.Fragment>
      <Box ref={clientAnimationTriggerRef} sx={styles.outerIcons}>
        <Grid ref={clientsRef} sx={styles.icons} data-cy='client-icon-grid'>
          <ClientIcons />
        </Grid>
      </Box>
    </React.Fragment>
  );
};
