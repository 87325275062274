export const styles = {
  contactButton: {
    variant: 'buttons.cta',
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    justifyContent: 'flex-start',
    svg: {
      height: '100%',
    },

    '&[target=_blank]:before': {
      content: '""',
      display: 'none',
    },
  },
  contactButtonListContainer: {},
};
