import { useLocation, navigate } from '@reach/router';
import { parse, stringify } from 'query-string';

// TODO: handle all cases (string vs string[]) + add types
export const useQueryParam = (key: string): [string | undefined, (value: string) => void] => {
  const { search } = useLocation();

  const set = async (value: string) => {
    const newQueryObj = {
      ...parse(search),
      [key]: value,
    };
    const newQuerySring = stringify(newQueryObj);

    await navigate(`?${newQuerySring}`);
  };

  return [(parse(search)[key] as string | undefined) || undefined, set];
};
