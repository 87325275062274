import React from 'react';
import { Box, SxProps } from 'theme-ui';
import { Paragraph } from '@core';
import { styles } from './Divider.styles';

type DividerProps = {
  text: string;
  sx?: SxProps;
};
function Divider({ text, sx }: DividerProps) {
  return (
    <Box sx={{ ...styles.background, ...sx }}>
      <Box sx={styles.text}>
        <Paragraph sx={styles.paragraph}>{text}</Paragraph>
      </Box>
    </Box>
  );
}

export default Divider;
