/** @jsx jsx */
import React from 'react';
import { jsx, Flex, FlexProps, Button, ButtonProps } from 'theme-ui';
import { ChildrenProps } from '../../../types';

type ID = string;

interface ItemProps {
  id: ID;
  name: string;
}

interface RadioGroupProps extends Omit<FlexProps, 'onChange'> {
  items: ItemProps[];
  defaultValue?: ID;
  value?: ID;
  onChange?: (id: ID) => void;
  component?: (p: ChildrenProps) => JSX.Element;
  itemComponent?: (p: ButtonProps) => JSX.Element;
}

export const RadioGroup = React.forwardRef<HTMLDivElement, RadioGroupProps>(
  ({ items, defaultValue, value, onChange, component: Component = Flex, itemComponent: Item = Button, ...props }, ref) => {
    const [localeId, setLocaleId] = React.useState(defaultValue || value);
    return (
      <Component ref={ref} {...props}>
        {items.map((locale) => (
          <Item
            key={`${locale.id}_${locale.name}`}
            type='button'
            value={locale.id}
            className={locale.id === localeId ? 'active' : ''}
            onClick={() => {
              setLocaleId(locale.id);
              if (onChange) setTimeout(() => onChange(locale.id), 200);
            }}
          >
            {locale.name}
          </Item>
        ))}
      </Component>
    );
  }
);
