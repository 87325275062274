/** @jsx jsx */
import { jsx, Flex, Button, Box } from 'theme-ui';
import { useFinnoscoreBannerContent } from '@modules';
import { Paragraph, SubTitle, SvgIcon, Link } from '@core';
import { FinnoscoreBannerProps } from './FinnoscoreBannerProps';
import { styles, subtitleStyles } from './DesktopFinnoscoreBanner.styles';

export const DesktopFinnoscoreBanner = ({ onClick, ...props }: FinnoscoreBannerProps) => {
  const { title, paragraph, link, buttonText } = useFinnoscoreBannerContent('finnoscore-homepage-banner');

  if (!title || !paragraph || !link || !buttonText) {
    return null;
  }

  return (
    <Flex {...props} sx={styles.box}>
      <Flex sx={styles.content}>
        <Button variant='buttons.pure' onClick={onClick}>
          <Link to={link}>
            <Flex sx={styles.textContent}>
              <Box>
                <SubTitle sx={styles.title}>{title}</SubTitle>

                <SubTitle sx={subtitleStyles}>{paragraph}</SubTitle>
              </Box>

              <Flex sx={styles.buttonContent}>
                <Paragraph sx={styles.buttonText}>{buttonText}</Paragraph>
                <SvgIcon sx={styles.arrowIcon} name='tailedArrow' />
              </Flex>
            </Flex>
          </Link>
        </Button>
        <Button variant='buttons.pure' onClick={onClick} aria-label='close'>
          <SvgIcon sx={styles.closeIcon} name='close' />
        </Button>
      </Flex>
    </Flex>
  );
};
