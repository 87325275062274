import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/all';

import { __tr } from '@modules';
import { withAnimation, scrub } from '@core';

export interface AnimateCustomScrollProps {
  triggerRef: () => React.RefObject<HTMLDivElement>;
  animation: () => gsap.core.Timeline;
  start?: () => string;
  end?: () => string;
}

export const AnimateCustomScroll = ({ triggerRef, animation, start = () => 'top bottom', end = () => 'top top' }: AnimateCustomScrollProps) => {
  gsap.registerPlugin(ScrollTrigger);
  const trigger = __tr(triggerRef);

  if (trigger && animation) {
    ScrollTrigger.create({
      trigger,
      // markers: process.env.NODE_ENV === 'development',
      start,
      end,
      ...scrub(),
      animation: animation(),
      // invalidateOnRefresh: true,
      // onRefresh: () => animation().invalidate(),
    });
  }
};

export const useAnimationCustomScroll = (props: AnimateCustomScrollProps) => withAnimation<AnimateCustomScrollProps>(AnimateCustomScroll, props);
