import { Button, Input } from '@material-ui/core';
import { FS_TYPES } from '@types';
import React from 'react';
import { SubmitHandler, UseFormHandleSubmit, UseFormRegister } from 'react-hook-form';
import { Label } from 'theme-ui';
import { DatePicker, IFormInput, Select, UploadButton } from '../../../upload/components';
import LoadingPacman from './LoadingPacman';

interface ExcelUploadFormProps {
  register: UseFormRegister<IFormInput>;
  handleSubmit: UseFormHandleSubmit<IFormInput>;
  onSubmit: SubmitHandler<IFormInput>;
  isLoading: boolean;
}

export const ExcelUploadForm = ({ handleSubmit, onSubmit, register, isLoading }: ExcelUploadFormProps) => (
  <form onSubmit={handleSubmit(onSubmit)}>
    <DatePicker name='Date' label='Date' />

    <Select name='Type' options={FS_TYPES} />

    <UploadButton>Select File</UploadButton>

    <Label>
      Description
      <Input {...register('description')} type='text' />
    </Label>

    <Button type='submit' disabled={isLoading}>
      Upload
    </Button>

    <LoadingPacman sx={{ visibility: isLoading ? 'visible' : 'hidden' }} />
  </form>
);
