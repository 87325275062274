export const styles = {
  title: {
    pl: [6, 6, 0],
  },
  subtitle: {
    variant: 'styles.h4',
    mt: 5,
    mb: 4,
  },
  author: {},
  image: {
    variant: 'flex.colCenter',
    mb: 5,
  },
};
