import { SxStyleProp } from 'theme-ui';

export const subtitleStyles = {
  m: 0,
  p: 0,
  mr: [4, 4, 4, 7],
  fontSize: (t) => `${t.fontSizes.s} !important`,
} as SxStyleProp;

export const styles = {
  box: {
    width: 'fullSize',
    backgroundColor: 'primary',
    height: 'banner',
    span: {
      display: 'block',
    },
    display: ['none', 'none', 'flex'],
    position: 'relative',
  } as SxStyleProp,
  content: {
    width: '100%',
    variant: 'styles.layoutMaxWidth',
    mx: [4, 4, 4, 'auto'],
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  textContent: {
    alignItems: 'center',
  },
  buttonContent: {
    alignItems: 'flex-end',
  },
  title: {
    ...subtitleStyles,
    color: 'background',
  },
  buttonText: {
    m: 0,
    p: 0,
    color: 'background',
    fontSize: (t) => `[, ${t.fontSizes.xs}, ${t.fontSizes.xs}, ${t.fontSizes.xs}, ${t.fontSizes.s}] !important`,
  } as SxStyleProp,
  closeIcon: {
    svg: {
      width: 4,
      height: 4,
    },
  },
  arrowIcon: {
    svg: {
      height: '14px',
    },
    mb: 2,
    ml: 3,
  },
};
