import React from 'react';
import { Flex, FlexProps, List, SectionWithContent, SectionWithContentProps, SubTitle } from '@core';
import { useAllIntlTree, useTranslate } from '@modules';
import { styles } from './Founders.styles';

const FounderList = React.forwardRef<HTMLDivElement, FlexProps>((p, ref) => <Flex {...p} sx={styles.founderList} ref={ref} />);

export const Founders = React.forwardRef<HTMLDivElement, SectionWithContentProps>((_p, ref) => {
  const __ = useTranslate();
  const messages = useAllIntlTree();
  return (
    <SectionWithContent ref={ref} variant='styles.layoutMaxWidth' data-cy='founders'>
      <Flex sx={styles.contentBox} variant='flex.colCenter'>
        <SubTitle data-cy='founders-subtitle' sx={styles.title}>
          {__('pages.aboutus.founders.subtitle')}
        </SubTitle>
        <List component={FounderList} items={messages.pages.aboutus.founders.list.filter(({ type }) => type === 'founder')} />
      </Flex>
    </SectionWithContent>
  );
});
