/** @jsx jsx */
import { jsx } from 'theme-ui';
import React from 'react';
import { SectionWithContent } from '@core';
import { NewsLetterSignup } from '../NewsLetterSignup';
import { DefaultNewsLetterContent } from '../NewsLetterContent';
import { BlogHeader } from './BlogHeader';
import { BlogListContainer } from './BlogListContainer';

export * from './PostDetails';
export * from './BlogCard';

export const BlogPage = () => (
  <React.Fragment>
    <BlogHeader />
    <SectionWithContent data-cy='blog-container'>
      <BlogListContainer />
    </SectionWithContent>
    <NewsLetterSignup id='pages.blog.contact'>
      <DefaultNewsLetterContent id='pages.blog.contact' />
    </NewsLetterSignup>
  </React.Fragment>
);
