/** @jsx jsx */
import { Box, jsx, Text } from 'theme-ui';
import React from 'react';
import { Flex, Title } from '@core';
import { __, useAllIntlTree } from '@modules';
import { TriggerElementProps } from '@types';
import { useAnimationLists } from '../landing/animateOursupport';
import WorkshopOffer from './WorkshopOffer';
import { styles } from './FinnoscoreWorkshop.styles';

const contentBlocks = [
  { title: 'impuls', icon: 'rocket' },
  { title: 'discovery', icon: 'eye' },
];

export const FinnoscoreWorkshop = ({ triggerRef }: TriggerElementProps) => {
  const titleRef = React.useRef(null);
  const listRef = React.useRef(null);
  const textRef = React.useRef(null);
  const messages = useAllIntlTree();

  useAnimationLists({
    triggerRef,
    title: () => titleRef,
    list: () => listRef,
    text: () => textRef,
  });

  return (
    <Flex sx={styles.sectionContent} ref={listRef}>
      <Box>
        <Title ref={titleRef} data-cy='workshop-types-title'>
          {messages.pages.workshops.finnoworkshops.title}
        </Title>
        <Text ref={textRef} sx={styles.text}>
          {__('pages.workshops.finnoworkshops.details')}
        </Text>
      </Box>
      <Flex sx={styles.offers} data-cy='workshop-types-offers'>
        {contentBlocks.map(({ title, icon }, index) => (
          <WorkshopOffer title={title} id={index} key={title} icon={icon} />
        ))}
      </Flex>
    </Flex>
  );
};
