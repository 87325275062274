import { getBoundedNumericValue, getNumericValue } from '@modules';
import gsap from 'gsap';
import React from 'react';
import { RGBColor } from 'react-color';
import { Input } from 'theme-ui';
import { doubleChartStyles } from './DoubleChartContainer.styles';

interface ChartValueInputProps {
  value?: string;
  color: RGBColor;
  itemRef: React.RefObject<HTMLDivElement>;
  containerRef: React.RefObject<HTMLDivElement>;
  setter: (value: string) => void;
  globalDimensionSetter: (value?: string) => void;
}
export const ChartInput = ({ color, value, itemRef, setter, globalDimensionSetter, containerRef }: ChartValueInputProps) => {
  const setHandler = (val: string, ref: React.RefObject<HTMLDivElement>, setValue: (value: string) => void) => {
    setValue(`${getBoundedNumericValue(val)}`);
    const percentage = getNumericValue(value) * 10;
    const containerWidth = containerRef?.current?.offsetWidth;
    if (containerWidth !== undefined) {
      gsap.set(ref.current, { transform: `translateX(${containerWidth * (percentage / 100)}px)` });
      globalDimensionSetter(value);
    }
  };

  return (
    <Input
      type='number'
      min={0}
      max={10}
      sx={doubleChartStyles.percentageSetter(color)}
      value={getNumericValue(value).toFixed(2)}
      onKeyUp={(e) => setHandler(e.currentTarget.value, itemRef, setter)}
      onChange={(e) => setHandler(e.target.value, itemRef, setter)}
    />
  );
};
