import { SxStyleProp } from 'theme-ui';

export const _buttonStyles = {
  variant: 'buttons.textAction',
};
export const editableStyles = {
  changed: (hasChanged: boolean) => ({
    ...(hasChanged
      ? {
          borderColor: 'pink !important',
          borderWidth: '1px',
          borderStyle: 'dashed !important',
        }
      : {}),
  }),
  editable: {
    cursor: 'col-resize',
    '&:hover': {
      borderStyle: 'dotted',
      borderWidth: '1px',
      borderColor: 'cyan',
    },
    '&:hover:after': {
      content: '"EDIT?"',
      ..._buttonStyles,
    },
  },

  editContainer: (elementProps: React.CSSProperties) =>
    ({
      gridTemplateColumns: '1fr max-content',
      gridTemplateAreas: `
    "edit preview"
    "edit save"
    "edit cancel"
    "edit hint"
    `,
      gridTemplateRows: 'repeat(3, min-content) 1fr',
      position: 'relative',
      zIndex: 999,
      alignItems: 'flex-start',
      '& :nth-of-type(1)': {
        gridArea: 'edit',
      },
      ...(elementProps &&
        elementProps?.height &&
        elementProps?.height < 120 && {
          gridTemplateColumns: '1fr max-content max-content max-content',
          gridTemplateAreas: `
      "edit preview save cancel"
      "edit hint hint hint"
      `,
          gridTemplateRows: 'min-content 1fr',
          '*:last-child': {
            maxWidth: 'unset',
            whiteSpace: 'nowrap',
          },
        }),
    }) as SxStyleProp,

  editing: (elementProps: React.CSSProperties) => ({
    outline: 'none',
    fontSize: 'inherit',
    fontWeight: 'inherit',
    fontFamily: 'inherit',
    fontStyle: 'inherit',
    color: 'inherit',
    width: '100%',
    height: 'inherit',
    ...(elementProps || {}),
  }),
  hint: {
    gridArea: 'hint !important',
    fontSize: 'xxxs',
    fontWeight: 'normal',
    maxWidth: '100px',
  },
  button: {
    ..._buttonStyles,
    gridArea: 'auto !important',
  },

  editOverlay: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: 'fullSize',
    height: 'browserHeight',
    zIndex: 1000,

    gridTemplateColumns: '1fr max-content',
    gridTemplateAreas: `"edit save"
                        "edit cancel"`,
    gridTemplateRows: 'repeat(3, min-content) 1fr',
    alignItems: 'flex-start',
    '&>:nth-of-type(1)': {
      border: 'solid 1px pink',
      gridArea: 'edit',
    },
    ul: {
      maxHeight: '100px',
      overflow: 'scroll',
    },
    li: {
      listSTYLEType: 'decimal',
      listStylePosition: 'inside',
    },
  } as SxStyleProp,
};
