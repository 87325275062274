import { Image } from './Article';
import { Country, CountryFromApi } from './Country';
import { Dimensions } from './Dimension';
import { FsType } from './FsType';
import { Locale } from './Locale';

export enum Trend {
  Ascending = 'ascending',
  Descending = 'descending',
  Stagnant = 'stagnant',
  New = 'new',
}

export interface InstitutionBase {
  id: number;
  name: string;
  rank: number;
  countryRank: number;
  total: number;
  fsType: FsType;
}
interface InstitutionBaseWithDimension {
  dimensions: Dimensions;
}

export interface InstitutionFromApi extends InstitutionBase, InstitutionBaseWithDimension {
  logo?: Image;
  country: CountryFromApi;
}

export interface Institution extends InstitutionBase, InstitutionBaseWithDimension {
  logo?: Image;
  image?: Image;
  country: Country;
  language?: Locale;
  trend?: Trend;
}
