/** @jsx jsx */
import { jsx, Styled, Flex, Text } from 'theme-ui';
import { ListItem as ListItemType, ListItemProps } from '@core';
import { ImageLoader } from './ImageLoader';
import { GenericListItemProps, ListComponentType } from './List';
import { defaultSectionStyles, sectionedListStyles, sectionStyles } from './SectionedList.styles';

export type SectionType = GenericListItemProps & { id: string };

interface DefaultSectionProps {
  section: SectionType;
}

export const DefaultSection = ({ section }: DefaultSectionProps) => (
  <Flex variant='flex.rowCenter'>
    {section.icon && <ImageLoader sx={sectionStyles.icon} alt={section.icon?.alternativeText} src={section.icon?.url} />}
    <Text sx={defaultSectionStyles.text}>{section.text}</Text>
  </Flex>
);

export interface ItemsBySection {
  items: ListItemType[];
  section: SectionType;
}
interface SectionedListProps {
  itemsBySection: ItemsBySection[];
  component?: ListComponentType;
  sectionComponent?: ({ section }: { section: SectionType }) => JSX.Element;
  itemComponent: (props: ListItemProps) => JSX.Element;
}

export const SectionedList = ({
  itemsBySection,
  component: Component = Styled.ul,
  sectionComponent: SectionComponent = DefaultSection,
  itemComponent: ListItem,
}: SectionedListProps) => (
  <Component>
    {itemsBySection.map((itemBySection) => (
      <Flex key={itemBySection.section.id} as='li' variant='flex.colLeft' sx={sectionedListStyles.sectionWithItems}>
        <SectionComponent section={itemBySection.section} />
        {itemBySection.items.map((props) => (
          <ListItem key={props.id} {...props} />
        ))}
      </Flex>
    ))}
  </Component>
);
