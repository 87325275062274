/** @jsx jsx */
import { jsx, SxProps } from 'theme-ui';
import React from 'react';
import { ThemedIcon } from '../themed/ThemedIcon';
import { Colors } from '../../../types';
import { iconsGenerated } from './iconsGenerated';
import findIcon from './findIcon';

export interface TypedIconProps extends SxProps {
  name: string;
  type?: string;
  alt?: string;
  color?: Colors;
  as?: React.ElementType;
  className?: string;
}

export const SvgIcon = React.forwardRef<HTMLDivElement, TypedIconProps>((props, ref) => {
  const { name, type, color, alt } = props;

  const Source = findIcon(iconsGenerated, name, type);

  return (
    <ThemedIcon ref={ref} sx={{ svg: { display: 'block' }, ...props.sx }} alt={alt} {...props} color={color}>
      <Source {...props} className={undefined} />
    </ThemedIcon>
  );
});
