import { gsap } from 'gsap';
import { CSSRulePlugin, ScrollTrigger } from 'gsap/all';
import is from 'is_js';
import { cleanupScrolls, cleanupTimeline } from '@core';
import { TriggerElementProps } from '@types';
import { __el, __tr } from '@modules';
import { selectResponsiveProp } from '@themed';
import { scrub, snap, withAnimation } from '../animation';

interface AnimateParallaxBackgroundImageProps extends TriggerElementProps {}

export const animateParallaxBackgroundImage = ({ triggerRef }: AnimateParallaxBackgroundImageProps) => {
  gsap.registerPlugin(CSSRulePlugin);

  // Safari had performance issues with this on Tablet & Laptop as well. Iphone was fine.
  if (!is.safari() || is.iphone()) {
    const transitions = selectResponsiveProp([
      [-10, 10],
      [-30, 30],
    ]);

    const selectorPrefix = `.${__el(triggerRef)?.className?.split(' ')?.shift()}`;
    const small = CSSRulePlugin.getRule(`${selectorPrefix}::before`);
    const big = CSSRulePlugin.getRule(`${selectorPrefix}::after`);

    const anim = gsap.timeline();
    if (big) {
      anim.fromTo(big, { cssRule: { yPercent: transitions[0] } }, { cssRule: { yPercent: transitions[1] } }, '<');
    }

    // just to optimize to trigger the small background transformation animation if requested
    if (small) {
      anim.fromTo(small, { cssRule: { yPercent: transitions[0] } }, { cssRule: { yPercent: transitions[1] } }, '<');
    }
    ScrollTrigger.create({
      trigger: __tr(triggerRef),
      start: 'top bottom',
      end: 'bottom top', // ~ 100vh, but can be even more, if the list content is higher on low height display, due ot the icons
      toggleActions: 'restart complete reverse reset',
      ...scrub(),
      ...snap(0.7, 1.3),
      refreshPriority: 5,
      invalidateOnRefresh: true,
      // markers: process.env.NODE_ENV === 'development',
      animation: anim,
    });

    return () => {
      cleanupScrolls();
      cleanupTimeline(anim);
    };
  }
  return () => {};
};

export const useAnimationParallaxBackgroundImage = (props: AnimateParallaxBackgroundImageProps) =>
  withAnimation<AnimateParallaxBackgroundImageProps>(animateParallaxBackgroundImage, props);
