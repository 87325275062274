/** @jsx jsx */
import { jsx, Styled, Flex, SxProps } from 'theme-ui';
import { FaLinkedinIn, FaXing, FaPhoneAlt, FaTwitter } from 'react-icons/fa';
import { MdEmail } from 'react-icons/md';
import { MouseEvent } from 'react';
import { IoCalendar } from 'react-icons/io5';
import { FlexProps } from '@themed';
import { useTranslate } from '../../../modules';
import { ThemedIcon } from '../themed/ThemedIcon';
import { CalendlyButton } from './CalendlyButton';
import { styles } from './ContactButton.styles';

interface ContactButtonProps extends SxProps {
  href: string;
  title: string;
  className?: string;
  icon?: () => JSX.Element;
  target?: string;
  onClick?: (e: MouseEvent<HTMLAnchorElement>) => void;
}

// TODO: with IconButton?
export const ContactButton = ({ href, title, target, icon: Icon, onClick, sx, className }: ContactButtonProps) => {
  const __ = useTranslate();

  return (
    <Styled.a
      sx={{ ...styles.contactButton, ...sx }}
      href={href}
      target={target || '_blank'}
      onClick={(e) => {
        if (onClick) return onClick(e);
        return null;
      }}
      className={`${title.replace('.', '-')} ${className}`}
    >
      {Icon && (
        <ThemedIcon variant='images.icon' className='icon'>
          <Icon />
        </ThemedIcon>
      )}
      {__(title)}
    </Styled.a>
  );
};

export interface ContactButtonListProps extends FlexProps {
  linkedin?: string;
  xing?: string;
  email?: string | boolean;
  phone?: string;
  twitter?: string;
  calendar?: string;
}

export const ContactButtonList = ({ linkedin, xing, twitter, email, phone, calendar, ...props }: ContactButtonListProps) => (
  <Flex {...props} sx={{ ...styles.contactButtonListContainer, ...props.sx }}>
    {calendar && <CalendlyButton icon={() => <IoCalendar color='black' />} href={calendar} />}
    {linkedin && <ContactButton href={linkedin} icon={() => <FaLinkedinIn color='black' />} title='common.linkedin' />}
    {xing && <ContactButton href={xing} icon={() => <FaXing color='black' />} title='common.xing' />}
    {twitter && <ContactButton href={twitter} icon={() => <FaTwitter color='black' />} title='common.twitter' />}
    {!!email && <ContactButton href={`mailto:${email}`} icon={() => <MdEmail color='black' />} title='common.email' />}
    {phone && <ContactButton href={`tel:${phone}`} icon={() => <FaPhoneAlt color='black' />} title={phone} />}
  </Flex>
);
