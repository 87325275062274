import moment from 'moment';

export const getFormattedDate = (date?: Date, locale?: string) =>
  (date &&
    locale &&
    moment(date)
      .locale(locale)
      .format(process.env.DATE_FORMAT || 'DD MMM YYYY')) ||
  '';

export const isChristmasTime = (now = new Date()) => {
  const month = now.getMonth() + 1;
  const yearOfChristmas = now.getFullYear() - (month === 1 ? 1 : 0);
  const start = new Date(`${yearOfChristmas}-12-15`);
  const end = new Date(`${yearOfChristmas + 1}-01-06 23:59:59`);
  return moment(now).isAfter(start) && moment(now).isBefore(end);
};
