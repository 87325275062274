import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { SxProps } from 'theme-ui';
import { Article } from '@types';
import { languages, useLocale } from '@modules';

import { BlogList } from '../common';

interface BlogContainerProps extends SxProps {
  limit?: number;
}

export const BlogListContainer = ({ limit, ...props }: BlogContainerProps) => {
  const locale = useLocale();

  const data = useStaticQuery(graphql`
    query {
      allArticle {
        edges {
          node {
            slug
            subtitle
            title
            publishDate
            published
            author {
              name
              text
              title
              description
            }
            thumbnails {
              url
              alternativeText
            }
            language
          }
        }
      }
    }
  `);

  // TODO: formatImage

  const allArticles: Article[] = data.allArticle.edges.map((node: { node: Article }) => node.node);

  const ariclesForLocale: { [key: string]: Article[] } = {};
  languages.forEach((loc) => {
    ariclesForLocale[loc] = allArticles.filter((article) => article.language === loc);
  });
  const renderableArticles = limit ? ariclesForLocale[locale].slice(0, limit) : ariclesForLocale[locale];

  return <BlogList {...props} articles={renderableArticles} sx={{ ...props.sx }} />;
};
