/** @jsx jsx */
import React from 'react';
import { Box, jsx } from 'theme-ui';
import { NavigationItem } from '@config';
import { NestedNavParent, SimpleNavItem } from '../ui';

export interface NavbarProps {
  items: NavigationItem[];
}

export const desktopStyles = (length: number, idx: number) => ({
  mb: [4, 0],
  mr: [0, 0, 4, 5],
  zIndex: length - idx,
});

interface NavBarItemProps extends NavigationItem {}

export const NavBarItem = ({ items, ...item }: NavBarItemProps) => {
  if (items && items?.length > 0) return <NestedNavParent {...item} items={items} />;
  return <SimpleNavItem {...item} />;
};

export const DesktopNavbar = ({ items }: NavbarProps) => (
  <React.Fragment>
    {items.map((item, idx) => (
      <Box sx={desktopStyles(items.length, idx)} key={`${item.id}_${idx}`}>
        <NavBarItem {...item} />
      </Box>
    ))}
  </React.Fragment>
);
