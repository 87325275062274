import React from 'react';
import { Flex, SmallText } from '@themed';
import { Children, ChildrenProps } from '@types';

import LoadingPacman from './LoadingPacman';

interface ChartWizardStepProps extends ChildrenProps {
  id: string;
  label: string;
  isLoading?: boolean;
  loading?: Children;
}
export const ChartWizardStep = React.forwardRef<HTMLDivElement, ChartWizardStepProps>(
  ({ id, isLoading, label, children, loading = <LoadingPacman /> }, ref) => (
    <Flex id={id} ref={ref} variant='flex.col'>
      {isLoading ? (
        loading
      ) : (
        <React.Fragment>
          <SmallText>{label}:</SmallText>
          {children}
        </React.Fragment>
      )}
    </Flex>
  )
);
