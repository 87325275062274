import { ComparisonSubject, FsType, Institution } from '@types';

export const formatComparisonSubjectToInsitution = (comparisonSubject: ComparisonSubject): Institution => {
  if (!comparisonSubject) throw new Error('Comparison subject is not found');
  const { id, name, rank, countryRank, total, dimensions, image, country, type } = comparisonSubject;

  const defaultCountry = {
    countryCode: 'ALLE',
    flag: {
      url: 'https://media.finnoconsult.at/finno-cms-staging/unflag_a948210284.png',
      alternativeText: 'Flag of Union',
    },
  };

  return {
    id: +id,
    name,
    rank: rank || -1,
    countryRank: countryRank || -1,
    total,
    fsType: type as FsType,
    dimensions,
    logo: image,
    image,
    country: country || defaultCountry,
    language: country?.language,
  };
};
