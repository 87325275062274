import { useMemo } from 'react';
import { Country } from '@types';
import { ALL_COUNTRIES } from 'src/modules/formatting';
import { useQueryParam } from '../useQueryParam';

export function useDefaultCountry(countries: Country[] | undefined) {
  const [countryCode] = useQueryParam('countryCode');

  const country = useMemo(() => countries?.find((c) => c.countryCode === (countryCode || ALL_COUNTRIES)), [countries, countryCode]);
  return { country };
}
