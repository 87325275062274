import { Box } from 'theme-ui';
import React from 'react';
import { useColorAtIndex } from '@composite';
import PercentageContext from '../../context/PercentageContext';
import { FillProps } from './Monochrome';
import { fillColorStyles } from './ChartItem.styles';
import { positionBarStyles } from './PositionBar.styles';

export const PositionBar = React.forwardRef<HTMLDivElement, FillProps>(({ draggable = false, ...props }, ref) => {
  const percentage = React.useContext(PercentageContext);
  const [colorB] = useColorAtIndex(1);
  return (
    <Box
      title={(percentage / (draggable ? 1 : 10)).toFixed(2)}
      ref={ref}
      {...props}
      sx={{ ...positionBarStyles(draggable ? undefined : percentage), ...fillColorStyles(colorB, 'text'), ...props.sx }}
    />
  );
});
