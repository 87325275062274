import React from 'react';
import { Box, Text, SubTitle, Paragraph, SxProps } from '@themed';
import { ContentTypes, getContentTypeUrl, useLocale, useTranslate } from '@modules';
import { OptionalLink, UserAvatar } from '@core';
import { AuthorHeader as AuthorHeaderType } from '@types';
import { styles } from './AuthorHeader.styles';

interface AuthorHeaderProps extends AuthorHeaderType, SxProps {
  slug?: string;
}

export const AuthorHeader = ({ name, avatar, text, slug, ...props }: AuthorHeaderProps) => {
  const locale = useLocale();
  const __ = useTranslate();

  return (
    <Box {...props} sx={{ ...styles.wrapper, ...props.sx }}>
      <OptionalLink to={slug && getContentTypeUrl(slug, locale, ContentTypes.author)}>
        {avatar && <UserAvatar src={avatar.url} alt={avatar.alternativeText} sx={styles.avatar} />}

        <Text sx={styles.authorName}>{name}</Text>
      </OptionalLink>
      <Paragraph sx={styles.authorDescription}>{text}</Paragraph>

      <SubTitle sx={styles.subtitle}>{__('pages.blogDetails.moreArticle')}</SubTitle>
    </Box>
  );
};
