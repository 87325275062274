import { ContactSection } from '@composite';
import { getTypeFromPath } from '@config';
import { SvgIcon, ThemedIcon } from '@core';
import { SelectedFsTypeContextProvider } from '@modules';
import { useLocation } from '@reach/router';
import React from 'react';
import { useStrapiPageContent } from 'src/content/useStrapiPageContent';
import { Video } from '../digital-experience/Video';
import { Compare } from './Compare';
import { styles } from './index.styles';
import { Init } from './Init';
import { QuickWins } from './QuickWins';
import { Services } from './Services';
import { FinnoscoreTestimonials } from './Testimonials';

export const FinnoScorePage = () => {
  const { pathname } = useLocation();
  const pageContent = useStrapiPageContent(`finnoscore-${getTypeFromPath(pathname)}`);
  const servicesRef = React.useRef(null);
  const quickWins = React.useRef(null);

  return (
    <React.Fragment>
      <SelectedFsTypeContextProvider>
        <Init />
        <Video content={pageContent} sx={styles.video} />
        <Compare />
      </SelectedFsTypeContextProvider>
      <QuickWins ref={quickWins} />
      <Services ref={servicesRef} />

      <FinnoscoreTestimonials />

      <ContactSection id='finnoscore.contact' triggerRef={() => servicesRef}>
        <ThemedIcon sx={{ variant: 'images.pureCircleIcon' }}>
          <SvgIcon name='rocket' />
        </ThemedIcon>
      </ContactSection>
    </React.Fragment>
  );
};
