import React from 'react';
import { FaArrowLeft } from 'react-icons/fa';
import { navigate } from 'gatsby';
import { useLocation } from '@reach/router';
import { SxProps, ShowBackButtonContext, FloatingIconButton } from '@core';
import { useTranslate, getParentFolder } from '@modules';
import { styles } from './FloatingBackButton.styles';

export const FloatingBackButton = (props: SxProps) => {
  const __ = useTranslate();
  const { show, toggleShow, title, setTitle } = React.useContext(ShowBackButtonContext);
  const { pathname, search } = useLocation();

  if (!show) return null;

  const onClick = () => {
    navigate(getParentFolder(pathname, search));
    toggleShow(false);
    setTitle(null);
  };

  return (
    <FloatingIconButton
      icon={FaArrowLeft}
      title={title || __('common.overview')}
      mobileTitle={!!title}
      onClick={onClick}
      sx={styles.button}
      {...props}
    />
  );
};
