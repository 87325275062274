import { IoArrowRedo } from 'react-icons/io5';
import React from 'react';
import { decode } from 'he';
import { themeConfig } from '@themed';
import { Content, hasContentText } from '@types';

interface ContentWithId {
  text: string | undefined;
}

export const getTextFromContent = (c?: Content) => c && (hasContentText(c) ? c.text : '');

const useGenerateContentBarLabels = (contents?: Content[]) => {
  if (contents) {
    const items = contents.map(
      (c) =>
        hasContentText(c) &&
        c.text
          ?.match(/<h2[^>]*>(.*)<\/h2>/)?.[1]
          .replace(/<[^>]*>/g, '')
          .replace('?', '')
    );
    const decodedItems = items.filter((i) => i).map((c) => c && decode(c)) as string[];

    const contentWithId: ContentWithId[] = contents.map((c, i) => ({
      ...c,
      text: items[i]
        ? `<div id="${items[i]}" style="scrollMarginTop: ${themeConfig.sizes.headerHeight}">${getTextFromContent(c)}</div>`
        : getTextFromContent(c),
    }));

    const links = decodedItems.map((label) => ({ id: label, text: label, icon: <IoArrowRedo />, href: `#${label}` }));

    return { links, contentWithId };
  }
  return {};
};

export default useGenerateContentBarLabels;
