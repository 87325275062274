import React from 'react';
import { Grid, Image, SectionWithContent } from '@core';
import { PageMeta } from '@types';
import { ContactSection } from '../ContactSection';
import { contactIconStyles } from '../rapid';
import { MoreArticle } from '../digital-experience/MoreArticle';
import { styles } from '../digital-experience/index.styles';
import { Development } from './Development';

interface DigitalExperienceProps {
  pageContent: PageMeta | undefined;
}

export const NearshoringDevelopment = ({ pageContent }: DigitalExperienceProps) => (
  <Grid sx={styles}>
    <SectionWithContent>
      <Development content={pageContent} />
    </SectionWithContent>

    <MoreArticle />

    <ContactSection id='rapid.contact'>
      <Image src='/images/pacman-white_2x.gif' alt='pacman animation' sx={contactIconStyles} />
    </ContactSection>
  </Grid>
);
