export function cleanUp(s: string) {
  // if we need anything to clean up before
  // const r = s.replace(/<thisorthattag[\W]*>/gi, '');
  return s;
}

export function prepMobileMore(s: string) {
  const moreStartRegex = /<more[^>]*>/gi; // either opener or single tag
  const moreEndRegex = /<\/[^>]*more>/gi;
  const moreStartCount = Array.from(s.matchAll(moreStartRegex)).length;
  const moreEndCount = Array.from(s.matchAll(moreEndRegex)).length;
  const appendEnd = moreStartCount - moreEndCount > 0 ? moreStartCount - moreEndCount : 0;
  const r = s
    // replace first more
    .replace(moreStartRegex, '<span class="more">')
    // replace any potential other more or the closing tag
    .replace(moreEndRegex, '</span>')
    // add closing tag if it were not closed correctly
    .replace(/$/, new Array(appendEnd).fill('</span>').join(''));
  return r;
}

// TODO: NOTE: this is an interim fix, to use hr for spacing inside paragraph (as br cannot be customized by CSS)
// should not be used with h1 or h2

export function prepLineBreak(s: string) {
  return s.replace(/<br[\W]*>/gi, '<br /> ');
}

export function prepOldMarkDown(s: string) {
  return s.replace(/#\[(strong|abbr)(\(\W*(\w+="[^"]+")*\))?([^\]]+)\]/gi, '<$1 $3>$4</$1>');
}

export function prepLinks(s: string) {
  return s.replace(/<(a[^>]+)rel="(noopener|noreferrer|nofollow| )+"([^/]*)>/gi, '<$1$3>');
}
export const generateAlt = (s: string) => s.replace(/<(img)([^>]+)(src="[^"]*\/([^"]+)_[a-f0-9]{8,10}\.\w{3,4}")([^>]*)>/gi, '<$1$2$3 alt="$4" $5>');
export function prepEmbeddedImage(s: string) {
  const r = s.match(/([^>]+) alt="([^"]+)"/i) ? s : generateAlt(s); // if there were no alt, let's give one, by generating from src
  // TODO: for later, enable figcaptions
  // .replace(/<(img)([^>]+)(alt="([^"]+)")([^>]*)>/gi, '<$1$2$3$5/>'); // dont use figcaptions currently, but shall be syntax: <figcaption>$4</figcaption>
  return r.replace(/<(img[^>]+)([^/])+>/gi, '<$1$2/>');
}
export function prepLists(s: string) {
  if ((s.match(/<ul/) && s.match(/<\/ul/)) || (s.match(/<ol/) && s.match(/<\/ol/)) || !s.match(/<li/)) return s;

  let r = s;

  // inject ul before first occurance of li, if no ol or ul
  if (!s.match(/<ul/) && !s.match(/<ol/)) {
    r = r.replace(/<li/i, '<ul><li');
  }

  // then add closing whichever needed
  const matchOpener = r.match(/<(ul|ol)/);
  const tag = matchOpener && matchOpener[1];
  if (tag) {
    const match = new RegExp(`<${tag}`);
    const matchNot = new RegExp(`</${tag}`);
    // inject after last occurance of /li
    if (r.match(match) && !r.match(matchNot)) {
      const pos = r.lastIndexOf('</li>');
      r = `${r.substring(0, pos + 5)}</${tag}>${r.substring(pos + 5)}`;
    }
  }

  return r;
}

export function preMarkdown(s: string) {
  return prepLists(prepOldMarkDown(prepLinks(prepEmbeddedImage(prepMobileMore(prepLineBreak(cleanUp(s)))))));
}
