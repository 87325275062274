/** @jsx jsx */
import { jsx } from 'theme-ui';
import React, { useState } from 'react';
import { useLocation } from '@reach/router';
import { useCookie } from '@modules';
import { useIsDesktop } from '@themed';
import { DesktopFinnoscoreBanner } from './DesktopFinnoscoreBanner';
import { MobileFinnoscoreBanner } from './MobileFinnoscoreBanner';

/**
 * On workshop page we have to display banner initially every time.
 */
function useWorkshopBanner() {
  const { pathname } = useLocation();
  const isDesktop = useIsDesktop();
  const [open, setOpen] = useState(true);

  const renderBannerOnWorkshopPage = open && pathname.match(new RegExp(/workshops/)) && isDesktop;

  return {
    render: renderBannerOnWorkshopPage,
    close: () => setOpen(false),
  };
}

export const FinnoscoreBanner = () => {
  const cookieName = 'userClicked2023FinnoscorePrivateBanner';
  const { value: hasUserClickedBanner, setValue: saveHasUserClickedBanner } = useCookie(cookieName);
  const hideBannerWithCookie = () => saveHasUserClickedBanner('true', 365);

  const { render: renderBannerOnWorkshopPage, close: closeBannerOnWorkshopPage } = useWorkshopBanner();

  if (hasUserClickedBanner && !renderBannerOnWorkshopPage) return null;

  return (
    <React.Fragment>
      <DesktopFinnoscoreBanner onClick={renderBannerOnWorkshopPage ? closeBannerOnWorkshopPage : hideBannerWithCookie} />
      <MobileFinnoscoreBanner onClick={hideBannerWithCookie} />
    </React.Fragment>
  );
};
