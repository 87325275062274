/** @jsx jsx */
import React, { ElementType } from 'react';
import { jsx, Box, BoxProps } from 'theme-ui';
import { ChildrenProps, OneComponentDefinitionType } from '@types';
import { CompactSectionContentStyles, CompactSectionContent } from './CompactSectionContent';
import { sectionStyles } from './Section.styles';
import { CompactSectionContentChildren } from '.';

interface SimpleSectionContentProps extends ChildrenProps, BoxProps {}

export const SimpleSectionContent = React.forwardRef<HTMLDivElement, SimpleSectionContentProps>(({ children, className, ...props }, ref) => (
  <Box {...props} sx={{ ...sectionStyles.sectionContent, ...props.sx }} ref={ref} className={`${className || ''} section-content`}>
    {children}
  </Box>
));

export const PureSection = React.forwardRef<HTMLDivElement, BoxProps>(({ children, ...props }, ref) => (
  <Box as='section' {...props} sx={{ ...sectionStyles.section, ...props.sx }} {...props} ref={ref}>
    {children}
  </Box>
));

export interface SectionWithContentProps extends BoxProps {
  contentComponent?: OneComponentDefinitionType;
}

export const SectionWithContent = React.forwardRef<HTMLDivElement, SectionWithContentProps>(
  ({ children, contentComponent: Content = SimpleSectionContent, ...props }, ref) => (
    <PureSection {...props} sx={props.sx} ref={ref}>
      <Content className='content'>{children}</Content>
    </PureSection>
  )
);

// TODO: refactor under @composite?
export const CompactSectionStyles = React.forwardRef<HTMLDivElement, BoxProps>(({ children, ...props }, ref) => (
  <SectionWithContent {...props} ref={ref}>
    <CompactSectionContentStyles>{children}</CompactSectionContentStyles>
  </SectionWithContent>
));

interface CompactSectionProps extends BoxProps, CompactSectionContentChildren {
  header?: () => JSX.Element;
  footer?: () => JSX.Element;
  titleAs?: ElementType<any>;
}

export const CompactSection = React.forwardRef<HTMLDivElement, CompactSectionProps>(
  ({ header: Header, footer: Footer, children, contentBottom, contentTop, titleAs, ...props }, ref) => (
    <CompactSectionStyles {...props} ref={ref}>
      {Header && <Header />}
      <CompactSectionContent titleAs={titleAs} id={`${props.id}`} contentBottom={contentBottom} contentTop={contentTop}>
        {children}
      </CompactSectionContent>
      {Footer && <Footer />}
    </CompactSectionStyles>
  )
);
