import React from 'react';

import { ContactSection, FinnoscoreBanner } from '@composite';
import { PureSection, SvgIcon, ThemedIcon } from '@core';
import { __ } from '@modules';
import Divider from '../casestudies/Divider';
import { styles } from './index.styles';
import { PackageList } from './PackageList';
import { WorkshopExperts } from './Experts';
import Init from './Init';
import { References } from './References';
import { WorkshopTestimonals } from './WorkshopTestimonals';
import { Offers } from './Offers';

const dividerStyles = {
  minHeight: 'unset',
  height: 'max-content',
  width: '100%',
  paddingLeft: [0, 0, '5rem'],
  '& > div > *': {
    maxWidth: ['100%', '100%', '40rem'],
    fontSize: ['xs', 'xs', 's'],
    fontWeight: 'semibold',
    color: 'text',
  },
};

export const WorkshopPage = () => {
  const servicesRef = React.useRef(null);

  return (
    <React.Fragment>
      <PureSection sx={styles}>
        <FinnoscoreBanner />
        <Init />
      </PureSection>

      <Divider text={__('pages.workshops.packages.divider')} sx={dividerStyles} />

      <PackageList />
      <Offers />
      <WorkshopExperts />
      <WorkshopTestimonals />
      <References />

      <ContactSection sx={styles.contact} id='finnoscore.contact' triggerRef={() => servicesRef}>
        <ThemedIcon sx={{ variant: 'images.pureCircleIcon' }}>
          <SvgIcon name='rocket' />
        </ThemedIcon>
      </ContactSection>
    </React.Fragment>
  );
};
