/** @jsx jsx */
import { getTypeFromPath } from '@config';
import { useTranslate } from '@modules';
import { useLocation } from '@reach/router';
import { Flex, jsx, Text } from '@themed';
import { styles } from './DesktopInstitutionHeader.styles';
import { useInstitutionItemLayoutStyles } from './InstitutionItem.styles';

export function useTypeText() {
  const __ = useTranslate();
  const { pathname, search } = useLocation();
  const type = getTypeFromPath(`${pathname}${search}`);

  return {
    typeText: type === 'telco' ? __('finnoscore.list.innoscore') : __(`finnoscore.list.finnoscore`),
    type,
  };
}

export const DesktopInstitutionHeader = () => {
  const __ = useTranslate();
  const layoutStyles = useInstitutionItemLayoutStyles();
  const { typeText, type } = useTypeText();

  return (
    <Flex sx={{ ...layoutStyles.link, ...styles.header }}>
      <Text sx={{ ...styles.cell, gridArea: 'rank' }}>{__('finnoscore.list.rank')}</Text>
      <Text sx={{ ...styles.cell, gridArea: 'logo' }}>{__(`finnoscore.list.${type}`)}</Text>
      <Text sx={{ ...styles.cell, gridArea: 'country', textAlign: 'left' }}>{__('finnoscore.list.country')}</Text>
      <Text sx={{ ...styles.cell, gridArea: 'trend' }}>{typeText}</Text>
    </Flex>
  );
};
