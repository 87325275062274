import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import DateFnsUtils from '@date-io/date-fns';

interface DatePickerProps {
  name: string;
  label: string;
}

export const DatePicker = ({ name, label }: DatePickerProps) => {
  const { control } = useFormContext(); // retrieve all hook methods

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Controller
        name={name}
        control={control}
        rules={{ required: true }}
        render={({ field: { onChange, value } }) => (
          <KeyboardDatePicker
            disableToolbar
            variant='inline'
            format='MM/dd/yyyy'
            margin='normal'
            id='date-picker-inline'
            label={label}
            onChange={onChange}
            value={value}
            color='primary'
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        )}
      />
    </MuiPickersUtilsProvider>
  );
};
