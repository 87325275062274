import * as React from 'react';

const Bulb6 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width='61px' height='91px' viewBox='0 0 61 91' version='1.1' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g id='Page-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g id='Artboard' transform='translate(-762, -3)'>
        <g id='bulb-6' transform='translate(762.4612, 4.33)'>
          <line x1='30.0597848' y1='31.376' x2='30.0597848' y2='0' id='Path' stroke='#D2D2D2' strokeWidth='1.40511' strokeLinecap='round'></line>
          <path
            d='M33.9937848,33.539 L42.3827848,44.078 C42.9337848,44.771 43.6617848,45.303 44.4907848,45.616 L57.1367848,50.344 C57.8247848,50.605 58.4457848,51.017 58.9537848,51.549 C59.4617848,52.082 59.8437848,52.722 60.0717848,53.421 C60.2997848,54.121 60.3677848,54.863 60.2707848,55.593 C60.1737848,56.323 59.9137848,57.021 59.5107848,57.637 L52.0847848,68.878 C51.5917848,69.617 51.3117848,70.477 51.2767848,71.365 L50.6797848,84.819 C50.6447848,85.555 50.4447848,86.274 50.0947848,86.922 C49.7447848,87.571 49.2537848,88.132 48.6577848,88.565 C48.0607848,88.998 47.3747848,89.292 46.6507848,89.424 C45.9257848,89.556 45.1797848,89.524 44.4697848,89.329 L31.4927848,85.739 C30.6357848,85.502 29.7297848,85.502 28.8727848,85.739 L15.8397848,89.322 C15.1297848,89.517 14.3837848,89.549 13.6587848,89.417 C12.9337848,89.285 12.2477848,88.991 11.6517848,88.558 C11.0557848,88.125 10.5647848,87.564 10.2147848,86.915 C9.86478476,86.267 9.66478476,85.548 9.62978476,84.812 L9.03278476,71.358 C8.99778476,70.47 8.71778476,69.61 8.22478476,68.871 L0.798784764,57.63 C0.395784764,57.013 0.136784764,56.313 0.0417847643,55.582 C-0.0542152357,54.852 0.0157847643,54.109 0.245784764,53.409 C0.475784764,52.708 0.859784764,52.069 1.36978476,51.537 C1.88078476,51.006 2.50378476,50.596 3.19378476,50.337 L15.8397848,45.609 C16.6687848,45.296 17.3967848,44.764 17.9477848,44.071 L26.3367848,33.532 C26.7977848,32.961 27.3817848,32.5 28.0437848,32.184 C28.7067848,31.867 29.4327848,31.704 30.1667848,31.704 C30.9017848,31.705 31.6267848,31.87 32.2887848,32.188 C32.9507848,32.505 33.5337848,32.967 33.9937848,33.539 Z'
            id='Path'
            fill='#E50013'
            fillRule='nonzero'
          ></path>
          <path
            d='M41.2887848,72.463 L40.8717848,72.463 L40.8717848,69.803 C40.8717848,69.185 40.2987848,68.662 39.6217848,68.662 L38.7887848,68.662 L38.7887848,61.44 L36.2887848,61.44 L36.2887848,68.662 L33.7887848,68.662 L33.7887848,61.44 L31.2887848,61.44 L31.2887848,68.662 L28.7887848,68.662 L28.7887848,61.44 L26.2887848,61.44 L26.2887848,68.662 L23.7887848,68.662 L23.7887848,61.44 L21.2887848,61.44 L21.2887848,68.662 L20.4557848,68.662 C19.7257848,68.662 19.2057848,69.185 19.2057848,69.803 L19.2057848,72.463 L18.7887848,72.463 C18.0597848,72.463 17.5387848,72.986 17.5387848,73.604 L17.5387848,74.364 C17.5387848,74.602 17.6947848,74.744 17.9557848,74.744 L42.1217848,74.744 C42.3307848,74.744 42.5387848,74.602 42.5387848,74.364 L42.5387848,73.604 C42.5387848,72.986 41.9657848,72.463 41.2887848,72.463 Z M38.3717848,72.463 L21.7057848,72.463 L21.7057848,70.943 L38.3717848,70.943 L38.3717848,72.463 Z M42.1217848,57.163 L30.8197848,52.839 C30.5597848,52.744 30.2987848,52.744 30.0387848,52.744 C29.7257848,52.744 29.4657848,52.744 29.2057848,52.839 L17.9037848,57.163 C17.6947848,57.211 17.5387848,57.448 17.5387848,57.686 L17.5387848,58.969 C17.5387848,59.302 17.7987848,59.539 18.1637848,59.539 L19.2057848,59.539 L19.2057848,60.109 C19.2057848,60.442 19.4657848,60.679 19.8307848,60.679 L40.2467848,60.679 C40.5597848,60.679 40.8717848,60.442 40.8717848,60.109 L40.8717848,59.539 L41.9137848,59.539 C42.2257848,59.539 42.5387848,59.302 42.5387848,58.969 L42.5387848,57.686 C42.5387848,57.448 42.3307848,57.211 42.1217848,57.163 Z M20.0387848,58.399 L30.0387848,54.978 L40.0387848,58.399 L20.0387848,58.399 Z'
            id='Shape'
            fill='#FFFFFF'
            fillRule='nonzero'
          ></path>
          <path
            d='M45.6987848,84.777 L32.7017848,81.187 C31.0257848,80.728 29.2567848,80.728 27.5797848,81.187 L14.5967848,84.777 C14.5707848,84.781 14.5447848,84.779 14.5187848,84.773 C14.4937848,84.766 14.4697848,84.754 14.4497848,84.737 C14.4287848,84.721 14.4117848,84.7 14.3997848,84.677 C14.3877848,84.653 14.3807848,84.628 14.3787848,84.601 L13.7817848,71.147 C13.7097848,69.408 13.1637848,67.722 12.2017848,66.272 L4.77478476,55.031 C4.76078476,55.006 4.75378476,54.978 4.75378476,54.95 C4.75378476,54.922 4.76078476,54.894 4.77478476,54.869 C4.78078476,54.841 4.79478476,54.814 4.81478476,54.793 C4.83378476,54.772 4.85978476,54.757 4.88778476,54.75 L17.4987848,50.022 C19.1297848,49.41 20.5637848,48.369 21.6507848,47.008 L30.0387848,36.469 C30.0547848,36.444 30.0757848,36.424 30.1017848,36.41 C30.1277848,36.397 30.1577848,36.39 30.1867848,36.3916624 C30.2167848,36.391 30.2467848,36.398 30.2737848,36.411 C30.3007848,36.425 30.3237848,36.445 30.3417848,36.469 L38.7297848,47.008 C39.8167848,48.369 41.2507848,49.41 42.8817848,50.022 L55.4927848,54.75 C55.5197848,54.76 55.5427848,54.776 55.5627848,54.796 C55.5827848,54.817 55.5967848,54.841 55.6057848,54.868 C55.6150851,54.895 55.6167848,54.924 55.6137848,54.952 C55.6097848,54.98 55.5997848,55.007 55.5837848,55.031 L48.1577848,66.272 C47.1997848,67.724 46.6537848,69.409 46.5777848,71.147 L45.9447848,84.615 C45.9417848,84.664 45.9207848,84.709 45.8857848,84.743 C45.8497848,84.777 45.8037848,84.797 45.7557848,84.798 L45.6987848,84.777 Z'
            id='Path'
            stroke='#FFFFFF'
            strokeWidth='1.40511'
            strokeLinecap='round'
            strokeLinejoin='round'
          ></path>
          <path
            d='M26.3777848,14.226 C27.9097848,15.757 30.1507848,23.535 30.1507848,23.535 C30.1507848,23.535 22.3737848,21.287 20.8487848,19.762 C20.1427848,19.023 19.7537848,18.037 19.7657848,17.015 C19.7767848,15.994 20.1877848,15.017 20.9097848,14.294 C21.6317848,13.57 22.6087848,13.159 23.6297848,13.146 C24.6517848,13.133 25.6387848,13.521 26.3777848,14.226 Z'
            id='Path'
            stroke='#D2D2D2'
            strokeWidth='1.40511'
            strokeLinecap='round'
          ></path>
          <path
            d='M33.7197848,14.226 C32.1947848,15.757 29.9467848,23.535 29.9467848,23.535 C29.9467848,23.535 37.7247848,21.286 39.2557848,19.762 C39.6197848,19.398 39.9077848,18.967 40.1047848,18.492 C40.3007848,18.017 40.4027848,17.508 40.4027848,16.994 C40.4027848,16.48 40.3007848,15.971 40.1047848,15.496 C39.9077848,15.021 39.6197848,14.589 39.2557848,14.226 C38.8927848,13.862 38.4607848,13.574 37.9857848,13.377 C37.5107848,13.18 37.0017848,13.079 36.4877848,13.079 C35.9737848,13.079 35.4647848,13.18 34.9897848,13.377 C34.5147848,13.574 34.0827848,13.862 33.7197848,14.226 Z'
            id='Path'
            stroke='#D2D2D2'
            strokeWidth='1.40511'
            strokeLinecap='round'
          ></path>
          <path
            d='M38.1117848,27.863 C37.2497848,28.227 36.2967848,28.319 35.3807848,28.126 C34.4647848,27.932 33.6307848,27.463 32.9897848,26.781 L29.9477848,23.535 L28.0507848,28.833 C27.7227848,29.745 27.0617848,30.501 26.2007848,30.947 C25.3397848,31.394 24.3417848,31.499 23.4067848,31.242'
            id='Path'
            stroke='#D2D2D2'
            strokeWidth='1.40511'
            strokeLinecap='round'
          ></path>
        </g>
      </g>
    </g>
  </svg>
);
export default Bulb6;
