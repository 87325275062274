import { Box, Paragraph, SubTitle } from '@themed';
import { Children, ChildrenProps } from '@types';
import React from 'react';

export interface SectionProps extends ChildrenProps {
  id: string;
  subTitle?: string;
  icon?: Children;
  variant?: string;
  hr?: boolean;
}

function Section({ children, subTitle, id, hr = true, variant = 'lists.primaryListType' }: SectionProps) {
  return (
    <Box sx={{ width: '100%', height: '100%', my: 5 }}>
      {hr && <hr />}
      <SubTitle sx={{ mb: 5 }} id={id}>
        {subTitle}
      </SubTitle>
      <Paragraph variant={variant}>{children}</Paragraph>
    </Box>
  );
}

export default Section;
