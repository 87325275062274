import * as React from 'react';

const SvgPlus = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={65} height={65} viewBox='0 0 65 65' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g id='plus_svg__Page-1' stroke='none' strokeWidth={1} fill='none' fillRule='evenodd'>
      <g id='plus_svg__Finnoscore-Full' transform='translate(-763 -4450)' fill='#FFF' fillRule='nonzero'>
        <g id='plus_svg__Group-Copy' transform='translate(736 4423)'>
          <path
            d='M59.5 27C77.433 27 92 41.55 92 59.5 92 77.433 77.45 92 59.5 92 41.567 92 27 77.45 27 59.5 27 41.567 41.55 27 59.5 27zm0 3.824c-15.837 0-28.676 12.853-28.676 28.676 0 15.837 12.853 28.676 28.676 28.676 15.837 0 28.676-12.853 28.676-28.676 0-15.837-12.853-28.676-28.676-28.676zm0 15.325c1.014 0 1.843.789 1.908 1.786l.004.125-.001 9.528h9.529a1.912 1.912 0 01.125 3.82l-.125.004-9.529-.001v9.529a1.912 1.912 0 01-3.819.125l-.004-.125V61.41H48.06a1.912 1.912 0 01-.125-3.819l.125-.004h9.528V48.06c0-1.055.856-1.911 1.912-1.911z'
            id='plus_svg__Combined-Shape-Copy-4'
          />
        </g>
      </g>
    </g>
  </svg>
);

export default SvgPlus;
