import React from 'react';
import { ImageLoader, OptionalLink, OptionalLinkProps } from '@core';
import { Logo } from '../usePressLogos';

interface LogoMapperProps {
  logos: Logo[];
  linkComponent?: (p: OptionalLinkProps) => JSX.Element;
  showLink?: boolean;
}
interface LogoProps {
  height: number | string;
  width?: number | string | string[];
}

const logoStyles = ({ height, width = ['150px', '100px', '150px', '160px'] }: LogoProps) => ({
  width,
  height,
  maxHeight: 8,
  filter: 'grayscale(100%)',
  transition: 'all 0.2s ease-out',
  '&:hover': {
    transform: 'scale(1.05)',
  },
});

export const LogoMapper = ({ logos, linkComponent: LinkComponent = OptionalLink, ...props }: LogoMapperProps) => (
  <React.Fragment>
    {logos.map(({ name, img, link, height, width }) => (
      <LinkComponent {...props} className='logoMapper-link' to={link} target='_blank' key={link} aria-label={name}>
        <ImageLoader
          aria-label={name}
          alt={`Logo of ${name}`}
          preload='original'
          key={link}
          className='press-logo'
          src={img}
          sx={logoStyles({ height, width })}
        />
      </LinkComponent>
    ))}
  </React.Fragment>
);
