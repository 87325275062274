import { bigBubble, smallBubble } from './bubbleSize';

export const styles = {
  section: {
    pt: '5vh',
  },
  content: {
    width: 'fullSize',
    maxWidth: 'maxRapidContentWidthDesktop',
    my: [0, 0, 0, '14vh'],
    '&:last-of-type': {
      mb: [0, 0, 0, 0],
    },
    ul: {
      variant: 'lists.airyListType',
    },
  },
  contentImage: {
    ...smallBubble,
    borderRadius: 'fullSize',
    variant: 'flex.center',
  },
  contentFirstImage: {
    ...bigBubble,
    mt: [0, 0, '-40%'],
    ml: [0, 0, '-10%'],
  },
  contentLastImage: {
    ...bigBubble,
    mb: [0, 0, '-40%'],
    ml: [0, 0, '10%'],
    zIndex: 'image',
  },
};
