import { getComparisonSelectionDynamic, useAsyncAction, useLocale, useQueryParam } from '@modules';
import { FsType } from '@types';
import { useDatesParam } from './useDatesParam';

export const useDynamicSelection = (fsType: FsType) => {
  const locale = useLocale();
  const [countryCode] = useQueryParam('countryCode');
  const { date, compareDate } = useDatesParam();

  const { resource, error, isLoading } = useAsyncAction(async () =>
    getComparisonSelectionDynamic({ fsType, locale, countryCode, date, compareDate })
  );

  return {
    countries: resource?.countries,
    institutions: resource?.institutions,
    countryCalculations: resource?.countryCalculations,
    fsType,
    error,
    isLoading,
  };
};
