import React, { useEffect } from 'react';

export function useEditableDimensionState(value?: string) {
  const [editableDimensions, setEditableDimensions] = React.useState(value);

  useEffect(() => {
    setEditableDimensions(value);
  }, [value]);

  return [editableDimensions, setEditableDimensions] as const;
}
