import { SxStyleProp } from 'theme-ui';

export const styles = {
  container: {
    flexDirection: ['column', 'row'],
    position: 'relative',
  } as SxStyleProp,
  left: {
    flexBasis: ['initial', '45%'],
  },
  right: {
    flexBasis: ['initial', '52%', '48%'],
  },
  title: {
    mt: [7, 0],
    letterSpacing: ['-0.5px', 'normal'],
    whiteSpace: ['nowrap', 'normal'],
  },
  subtitle: {
    mb: [7, 3],
    color: 'background',
  },
  paragraph: {
    mb: [7, 3],
    '&, p': {
      color: 'background',
    },
  },
  button: {
    alignSelf: 'center',
    display: ['none', 'flex'],
    bottom: 0,
  },
  mobileOnly: {
    display: ['block', 'none'],
    mt: '36px',
    button: {
      display: 'block',
    },
  },
  listContainer: {
    variant: 'flex.colAlternate',
    // previously it was flex, but to handle the potential overcontent, grid seemed better solution
    display: ['flex', 'grid'],
    gap: ['30px', '20px', '20px', '10px'],
    placeItems: 'center',
    gridTemplateRows: '1fr 1fr 1fr',
    maxHeight: '90vh', // 100vh - some spacing on the top
    '&>*': {
      maxWidth: ['unset', 'unset', '73%'],
      display: 'flex',
    },
    '&>:nth-of-type(2n)': {
      flexDirection: ['row-reverse', 'row'],
      justifySelf: ['flex-start', 'flex-end'],
      figure: {
        mr: [0, 4],
      },
    },
    '.listItemTitle': {
      fontWeight: 'bold',
    },
    '.listItemText': {
      variant: [undefined, 'flex.colLeft'],
      '&>*': {
        p: 2,
      },
    },
  },
  svgIcon: {
    variant: 'images.circleIcon',
  },
};
