import { Country, CountryCalculation, Institution, WithFsType } from '@types';
import { convertCountriesToCountryCalculationListItems } from '@modules';

export interface FinnoscoreComparisonSectionData {
  countries?: Country[];
  institutions?: Institution[];
  countryCalculations?: CountryCalculation[];
}

export const useSelectionBasedOnFsType = (pageContext: FinnoscoreComparisonSectionData & WithFsType) => {
  const countries = pageContext.countries && convertCountriesToCountryCalculationListItems(pageContext.countries, pageContext.fsType);

  return {
    countries,
    institutions: pageContext.institutions,
    countryCalculations: pageContext.countryCalculations,
    fsType: pageContext.fsType,
  };
};
