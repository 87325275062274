/** @jsx jsx */
import { jsx, SxProps } from 'theme-ui';

export default (props: SxProps) => (
  <svg width='1902px' height='485px' viewBox='0 0 1902 485' version='1.1' xmlns='http://www.w3.org/2000/svg' {...props}>
    <title>shadow</title>
    <defs>
      <linearGradient x1='50%' y1='13.139546%' x2='50%' y2='100%' id='linearGradient-1'>
        <stop stopColor='#FFFFFF' offset='0%' />
        <stop stopColor='#F4F4F4' offset='26.9075407%' />
        <stop stopColor='#EEEEEE' offset='100%' />
      </linearGradient>
      <linearGradient x1='50%' y1='0%' x2='50%' y2='98.803028%' id='linearGradient-2'>
        <stop stopColor='#FFFFFF' offset='0%' />
        <stop stopColor='#ECE9E9' offset='13.1222171%' />
        <stop stopColor='#ADADAD' offset='100%' />
      </linearGradient>
      <filter x='-10.0%' y='-92.0%' width='120.0%' height='284.0%' filterUnits='objectBoundingBox' id='filter-3'>
        <feGaussianBlur stdDeviation='50' in='SourceGraphic' />
      </filter>
      <linearGradient x1='50%' y1='0%' x2='50%' y2='98.803028%' id='linearGradient-4'>
        <stop stopColor='#E4DEDE' offset='0%' />
        <stop stopColor='#6A6A6A' offset='100%' />
      </linearGradient>
      <filter x='-15.6%' y='-150.0%' width='131.2%' height='400.0%' filterUnits='objectBoundingBox' id='filter-5'>
        <feGaussianBlur stdDeviation='49' in='SourceGraphic' />
      </filter>
      <linearGradient x1='50%' y1='0%' x2='50%' y2='98.1563412%' id='linearGradient-6'>
        <stop stopColor='#FFFFFF' stopOpacity='0' offset='0%' />
        <stop stopColor='#FFFFFF' stopOpacity='0.780774523' offset='23.7880894%' />
        <stop stopColor='#FFFFFF' offset='100%' />
      </linearGradient>
      <filter x='-5.1%' y='-38.4%' width='110.2%' height='176.8%' filterUnits='objectBoundingBox' id='filter-7'>
        <feGaussianBlur stdDeviation='26' in='SourceGraphic' />
      </filter>
      <linearGradient x1='50%' y1='0%' x2='50%' y2='100%' id='linearGradient-8'>
        <stop stopColor='#FFFFFF' offset='0%' />
        <stop stopColor='#FFFFFF' stopOpacity='0.299492256' offset='93.5062862%' />
        <stop stopColor='#FFFFFF' stopOpacity='0' offset='100%' />
      </linearGradient>
      <filter x='-6.0%' y='-12.2%' width='112.0%' height='124.4%' filterUnits='objectBoundingBox' id='filter-9'>
        <feGaussianBlur stdDeviation='22' in='SourceGraphic' />
      </filter>
      <filter x='-6.0%' y='-12.2%' width='112.0%' height='124.4%' filterUnits='objectBoundingBox' id='filter-10'>
        <feGaussianBlur stdDeviation='22' in='SourceGraphic' />
      </filter>
      <linearGradient x1='50%' y1='0%' x2='50%' y2='100%' id='linearGradient-11'>
        <stop stopColor='#FFFFFF' offset='0%' />
        <stop stopColor='#FFFFFF' stopOpacity='0.299492256' offset='93.5062862%' />
        <stop stopColor='#FFFFFF' stopOpacity='0' offset='100%' />
      </linearGradient>
      <filter x='-3.3%' y='-12.5%' width='106.6%' height='125.1%' filterUnits='objectBoundingBox' id='filter-12'>
        <feGaussianBlur stdDeviation='22' in='SourceGraphic' />
      </filter>
      <linearGradient x1='50%' y1='0%' x2='50%' y2='100%' id='linearGradient-13'>
        <stop stopColor='#FFFFFF' offset='0%' />
        <stop stopColor='#FFFFFF' stopOpacity='0.299492256' offset='93.5062862%' />
        <stop stopColor='#FFFFFF' stopOpacity='0' offset='100%' />
      </linearGradient>
      <filter x='-4.4%' y='-12.4%' width='108.8%' height='124.8%' filterUnits='objectBoundingBox' id='filter-14'>
        <feGaussianBlur stdDeviation='22' in='SourceGraphic' />
      </filter>
      <linearGradient x1='-338.002166%' y1='50%' x2='99.2889191%' y2='50%' id='linearGradient-15'>
        <stop stopColor='#FFFFFF' stopOpacity='0' offset='0%' />
        <stop stopColor='#FFFFFF' offset='100%' />
      </linearGradient>
      <filter x='-57.2%' y='-28.6%' width='214.4%' height='157.2%' filterUnits='objectBoundingBox' id='filter-16'>
        <feGaussianBlur stdDeviation='50' in='SourceGraphic' />
      </filter>
      <linearGradient x1='-78.894545%' y1='50%' x2='99.2889191%' y2='50%' id='linearGradient-17'>
        <stop stopColor='#FFFFFF' stopOpacity='0' offset='0%' />
        <stop stopColor='#FFFFFF' offset='100%' />
      </linearGradient>
      <filter x='-40.7%' y='-28.6%' width='181.4%' height='157.2%' filterUnits='objectBoundingBox' id='filter-18'>
        <feGaussianBlur stdDeviation='50' in='SourceGraphic' />
      </filter>
      <linearGradient x1='48.0111682%' y1='0%' x2='48.0111682%' y2='100%' id='linearGradient-19'>
        <stop stopColor='#FFFFFF' stopOpacity='0' offset='0%' />
        <stop stopColor='#FFFFFF' offset='100%' />
      </linearGradient>
      <filter x='-7.0%' y='-214.3%' width='114.0%' height='528.6%' filterUnits='objectBoundingBox' id='filter-20'>
        <feGaussianBlur stdDeviation='50' in='SourceGraphic' />
      </filter>
    </defs>
    <g id='shadow' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g transform='translate(-394.000000, -80.000000)' id='Group'>
        <rect id='Rectangle' fill='url(#linearGradient-1)' x='380' y='72' width='1925' height='375' />
        <path
          d='M612,285.896837 L1313.71416,354 L1415.70004,354 L2111,285.896837 C2094.10221,257.441958 2061.71047,240.844316 2013.82478,236.103908 C1854.54884,220.336511 1580.30403,191 1374.0077,191 C1136.16018,191 883.557666,215.144729 708.213094,236.103908 C677.996108,239.715786 645.925076,256.313429 612,285.896837 Z'
          id='Path'
          fill='url(#linearGradient-2)'
          filter='url(#filter-3)'
        />
        <path
          d='M921,253 C1130.9122,318.333333 1284.07044,351 1380.47471,351 C1476.87899,351 1637.72075,318.333333 1863,253 C1625.83524,271.502924 1464.99347,280.754386 1380.47471,280.754386 C1295.95595,280.754386 1142.79772,271.502924 921,253 Z'
          id='Path'
          fill='url(#linearGradient-4)'
          filter='url(#filter-5)'
        />
        <path
          d='M629.334034,306.137817 L780.200673,251.303197 C921.309142,240.664681 1017.5,232.15972 1068.77324,225.788313 C1120.04649,219.416906 1211.20846,213.523117 1342.25915,208.106946 L1653.62221,225.788313 L1910.0955,251.303197 L2096.91334,318 L2112,115 L586,115 L629.334034,306.137817 Z'
          id='Path-2'
          fill='url(#linearGradient-6)'
          opacity='0.224038987'
          filter='url(#filter-7)'
        />
        <path
          d='M1719.47727,115.328929 C2017.55136,91.0103146 2197.27544,115.851108 2258.64951,189.851309 C2320.02359,263.851511 2334.51747,284.440276 2302.13115,251.617606 C2388.30558,491.97714 2441.08328,585.148764 2460.46426,531.13248 C2479.84525,477.116195 2479.84525,301.738702 2460.46426,5 L1402.51116,5 C1362.50181,33.9229172 1364.60092,56.0782194 1408.8085,71.4659066 C1453.01609,86.8535938 1556.57234,101.474601 1719.47727,115.328929 Z'
          id='Path-3'
          fill='url(#linearGradient-8)'
          filter='url(#filter-9)'
        />
        <path
          d='M497.477271,115.328929 C795.551359,91.0103146 975.27544,115.851108 1036.64951,189.851309 C1098.02359,263.851511 1112.51747,284.440276 1080.13115,251.617606 C1166.30558,491.97714 1219.08328,585.148764 1238.46426,531.13248 C1257.84525,477.116195 1257.84525,301.738702 1238.46426,5 L180.511163,5 C140.501807,33.9229172 142.60092,56.0782194 186.808503,71.4659066 C231.016086,86.8535938 334.572342,101.474601 497.477271,115.328929 Z'
          id='Path-3'
          fill='url(#linearGradient-8)'
          filter='url(#filter-10)'
          transform='translate(702.500000, 275.500000) scale(-1, 1) translate(-702.500000, -275.500000) '
        />
        <path
          d='M1402.68332,195.863502 C1684.29152,302.136586 1478.37755,526.093127 1818.12585,526 C2044.62472,525.93236 2044.62472,350.599027 1818.12585,0 L761.622929,0 C256.808497,17.5791372 3.50036149,27.6795448 1.69852137,30.3012229 C-33.5101921,81.5299143 487.617011,81.5360361 733.302531,110.621301 C833.247609,122.453212 1121.07511,89.5904173 1402.68332,195.863502 Z'
          id='Path-3'
          fill='url(#linearGradient-11)'
          filter='url(#filter-12)'
          transform='translate(994.000000, 263.000000) scale(-1, -1) translate(-994.000000, -263.000000) '
        />
        <path
          d='M2189.21984,203.347512 C2345.52832,269.623607 2257.58474,571.000714 2459.19491,530.22264 C2593.6017,503.037258 2593.6017,327.569804 2459.19491,3.82027851 L1401.95513,3.82027851 C1179.69009,0.46837603 1067.65601,0.104276934 1065.85292,2.72798122 C1030.61965,53.9962659 1332.5867,100.229067 1373.61498,114.527076 C1439.88165,137.620447 2032.91135,137.071417 2189.21984,203.347512 Z'
          id='Path-3'
          fill='url(#linearGradient-13)'
          filter='url(#filter-14)'
          transform='translate(1811.500000, 267.500000) scale(1, -1) translate(-1811.500000, -267.500000) '
        />
        <rect id='Rectangle' stroke='#979797' fill='url(#linearGradient-15)' filter='url(#filter-16)' x='2210' y='6' width='263' height='526' />
        <rect
          id='Rectangle'
          stroke='#979797'
          fill='url(#linearGradient-17)'
          filter='url(#filter-18)'
          transform='translate(338.000000, 270.000000) scale(-1, 1) translate(-338.000000, -270.000000) '
          x='153'
          y='7'
          width='370'
          height='526'
        />
        <rect
          id='Rectangle'
          fill='url(#linearGradient-19)'
          filter='url(#filter-20)'
          transform='translate(1368.000000, 561.000000) scale(-1, 1) translate(-1368.000000, -561.000000) '
          x='296'
          y='526'
          width='2144'
          height='70'
        />
      </g>
    </g>
  </svg>
);
