import { RGBColor } from 'react-color';
import { SxStyleProp } from 'theme-ui';

export const monochromeStyles = {
  container: (percent: number) =>
    ({
      width: `${percent}%`,
      height: '100%',
      borderRadius: '12px',
      position: 'relative',
    }) as SxStyleProp,

  button: (color: RGBColor) =>
    ({
      position: 'absolute',
      left: 0,
      top: 0,
      ml: -4,
      transform: 'translateY(-50%)',
      height: '100%',
      width: '17px',
      borderRadius: '12px',
      backgroundColor: 'white',
      visibility: 'hidden',
      border: color ? `2px solid rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})` : '2px solid #00bd59',
      '&:hover': {
        boxShadow: '0px 0px 0px 8px rgb(0 0 0 / 16%)',
      },
      '&:active': {
        boxShadow: '0px 0px 0px 8px rgb(0 0 0 / 16%)',
      },
      '&:active:after': {
        background: 'black',
        borderRadius: '5px',
        bottom: '26px',
        color: '#fff',
        content: 'attr(title)',
        left: '20%',
        padding: '5px',
        position: 'absolute',
        zIndex: '98',
      },
    }) as SxStyleProp,
};
