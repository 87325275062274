import React from 'react';
import { Box } from 'theme-ui';
import { SectionWithContent } from '@core';
import { useTranslate } from '@modules';
import { SubTitle } from '@themed';
import { BlogListContainer } from '../blog/BlogListContainer';
import { styles } from './MoreArticle.styles';

export const MoreArticle = () => {
  const __ = useTranslate();
  return (
    <React.Fragment>
      <Box sx={styles.wrapper}>
        <Box sx={styles.subtitleWrapper}>
          <SubTitle sx={styles.subtitle}>{__('common.moreArticle')}</SubTitle>
        </Box>
      </Box>
      <SectionWithContent>
        <BlogListContainer limit={3} sx={styles.blogContainer} />
      </SectionWithContent>
    </React.Fragment>
  );
};
