import { isAllCountries, pluralizeFsType, useLocale } from '@modules';
import { useLocation } from '@reach/router';
import { FsType } from '@types';
import { useSelectedCountry } from '..';
import { useDatesParam } from './useDatesParam';

export const useComparisonLink = (a: string, b: string, fsType: FsType) => {
  const locale = useLocale();
  const dates = useDatesParam();
  const country = useSelectedCountry();
  const location = useLocation();
  const pluralStr = pluralizeFsType(fsType);

  const basePath = `/${locale}/${pluralStr}`;
  const path = dates.link() ? `${basePath}/compare-preview` : `${basePath}/compare`;

  const searchParams = new URLSearchParams(location.search);

  searchParams.set('a', a);
  searchParams.set('b', b);

  if (!isAllCountries(country?.countryCode)) {
    searchParams.set('countryCode', country.countryCode);
  }

  if (dates.link()) {
    searchParams.set('dates', dates.link());
  }

  return `${path}?${searchParams.toString()}`;
};

export const useEmbeddedComparisonLink = (a: string, b: string, fsType: FsType) => {
  const locale = useLocale();
  const dates = useDatesParam();
  const country = useSelectedCountry();
  const location = useLocation();
  const pluralStr = pluralizeFsType(fsType);

  const basePath = `/${locale}/embed/${pluralStr}`;
  const path = dates.link() ? `${basePath}/compare-preview` : `${basePath}/compare`;

  const searchParams = new URLSearchParams(location.search);

  searchParams.set('a', a);
  searchParams.set('b', b);

  if (!isAllCountries(country?.countryCode)) {
    searchParams.set('countryCode', country.countryCode);
  }

  if (dates.link()) {
    searchParams.set('dates', dates.link());
  }

  return `${path}?${searchParams.toString()}`;
};
