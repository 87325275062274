import { RGBColor } from 'react-color';
import { SxStyleProp } from 'theme-ui';

export const doubleChartStyles = {
  chartBox: {
    height: '100%',
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'max-content max-content 1fr',
    placeItems: 'center',
    gap: 0,
    // needed to remove arrows next to the inputs
    input: {
      textAlign: 'center',
    },
    'input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    'input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
  chartItem: {
    height: ['40%', '50%'],
    width: '100%',
  },
  percentageSetter: (color?: RGBColor, defaultThemeColor = 'black') =>
    ({
      width: '65px',
      color: color ? `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})` : defaultThemeColor, // needed for touch device representation
      border: 'none',
      '&:focus': {
        outline: 'none',
      },
      fontWeight: 'bold',
      textAlign: 'right',
    }) as SxStyleProp,
};
