/** @jsx jsx */
import { jsx, Flex, Box, Button, BoxProps } from 'theme-ui';
import React from 'react';
import { IconType } from 'react-icons';
import { themeConfig } from '@themed';
import { styles } from './FloatingIconButton.styles';

interface FloatingIconButtonStylesProps extends BoxProps {
  icon: IconType;
  title: string;
  mobileTitle?: boolean;
  onClick: () => void;
}

export const FloatingIconButton = React.forwardRef<HTMLDivElement, FloatingIconButtonStylesProps>(
  ({ icon: IconComponent, title, mobileTitle, onClick, ...props }, ref) => (
    <Box {...props} sx={{ ...styles.content, ...props.sx }} ref={ref}>
      <Button sx={styles.menuButton} onClick={onClick}>
        <Flex sx={styles.flex}>
          <Flex sx={styles.icon}>
            <IconComponent size={16} color={themeConfig.colors.primary} />
          </Flex>
          <Box sx={styles.title(mobileTitle)}>{title}</Box>
        </Flex>
      </Button>
    </Box>
  )
);
