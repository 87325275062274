/** @jsx jsx */
import { jsx, SxStyleProp } from 'theme-ui';
import React from 'react';
import { useAllLanguages, useLocale, changeLocale } from '@modules';
import { Locale } from '@types';
import { SlugContext } from '../layout/SlugContext';
import { RadioGroup } from './RadioGroup';

const styles: SxStyleProp = {
  container: {
    mt: 7,
    variant: 'styles.toggle',
  },
};

interface MobileLanguageSelectorProps {
  onChange?: (lang?: string) => void;
}

export const MobileLanguageSelector = ({ onChange }: MobileLanguageSelectorProps) => {
  const currentLocale = useLocale();
  const allLocales = useAllLanguages();

  const slugs = React.useContext(SlugContext);

  const onLanguageChange = (locale: Locale) => {
    if (onChange) onChange(locale);
    changeLocale(locale, slugs[locale]);
  };

  return (
    <RadioGroup sx={styles.container} onChange={(locale) => onLanguageChange(locale as Locale)} defaultValue={currentLocale} items={allLocales} />
  );
};
