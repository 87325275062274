import * as React from 'react';

const SvgClose = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={14} height={14} viewBox='0 0 14 14' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g id='close_svg__Page-1' stroke='none' strokeWidth={1} fill='none' fillRule='evenodd'>
      <g id='close_svg__Artboard' transform='translate(-263 -181)' fill='#FFF'>
        <g id='close_svg__close' transform='translate(263 181)'>
          <path id='close_svg__Rectangle' transform='rotate(45 7 7)' d='M6-1h2v16H6z' />
          <path id='close_svg__Rectangle' transform='rotate(-45 7 7)' d='M6-1h2v16H6z' />
        </g>
      </g>
    </g>
  </svg>
);

export default SvgClose;
