import React from 'react';
// NOTE: mixing cleanup with matchMedia leads to various undefined issues, after routing one page to an other.
// However with internal routing & smooth-scrollbar we have to enable that again, as it had messed things up without it.

import { ScrollTrigger } from 'gsap/all';
import { useIsLegacy, isTouchDevice } from '@modules';
import { useIsMobile } from '@themed';

// NOTE: never cleanupAllScrolls, as it would clean up the menu toggle as well
export function cleanupAllScrolls() {
  ScrollTrigger.getAll().forEach((st) => st.kill());
}

export const cleanupScrolls = () => cleanupAllScrolls();

export function cleanupTimeline(ref: gsap.core.Timeline | gsap.core.Tween) {
  if (ref) {
    ref.pause();
    ref.invalidate();
    ref.kill();
  }
}
export function cleanupTimelines(refs: (gsap.core.Timeline | gsap.core.Tween)[]) {
  refs.forEach((ref) => cleanupTimeline(ref));
}

interface PinValue {
  pin?: boolean | string | Element;
  pinReparent?: boolean;
  anticipatePin?: number;
  // pinSpacing?: boolean | string;
  pinType?: 'fixed' | 'transform';
}

export function pin(enable = true): PinValue {
  return enable
    ? {
        pin: enable,
        pinReparent: !isTouchDevice(),
        anticipatePin: 1,
        pinType: isTouchDevice() ? 'fixed' : 'transform',
      }
    : { pin: enable };
}

interface ScrubValue {
  scrub?: number | boolean;
}

export function scrub(enable = true): ScrubValue {
  return enable
    ? {
        scrub: isTouchDevice() ? 0.2 : true,
      }
    : { scrub: !!enable };
}

type AnimationFunction<T> = (p: T & { isMobile: boolean }) => void;
export function withAnimation<T>(animationFunction: AnimationFunction<T>, props: T) {
  const legacy = useIsLegacy();
  const isMobile = useIsMobile();

  // Has to be useEffect, useLayoutEffect crashes, since matchMedia is not available
  React.useEffect(() => {
    if (!legacy)
      animationFunction({ ...props, isMobile }); // we should only if not legacy
    else cleanupAllScrolls();
  }, [legacy]);
}

export function snap(threshold = 2 / 3, duration = 0.7) {
  return {
    snap: {
      snapTo: (value: number) => {
        // console.log('finnoscore: ', value);
        if (value < threshold) return value;
        return 1;
      },
      duration,
    },
  };
}
