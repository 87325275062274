/** @jsx jsx */
import { getScore, useTranslate } from '@modules';
import { Box, Grid, jsx, SxStyleProp, Text } from '@themed';
import { Comparison, DimensionKey, Dimensions } from '@types';
import { DoubleChartItem } from '../chart-generator/shared/components/base';
import { useTypeText } from '../finnoscore-list/DesktopInstitutionHeader';
import { headerStyles, rowStyles, styles } from './Charts.styles';

interface HeaderProps {
  sx?: SxStyleProp;
}

export const Header = ({ sx, ...props }: HeaderProps) => {
  const __ = useTranslate();
  const dimensionsText = __('common.dimensions');
  const { typeText } = useTypeText();

  return (
    <Grid sx={{ ...headerStyles.box, ...sx }} {...props}>
      <Text sx={headerStyles.text}>{dimensionsText}</Text>
      <Text sx={headerStyles.text}>{typeText}</Text>
    </Grid>
  );
};

interface RowData {
  dimension: keyof Dimensions;
  scoreA: string;
  scoreB: string;
}

const Row = ({ dimension, scoreA, scoreB }: RowData) => {
  const getPercentageFromString = (str: string) => (!Number.isNaN(+str) ? +str * 10 : 0);
  const percentageA = getPercentageFromString(scoreA);
  const percentageB = getPercentageFromString(scoreB);
  return (
    <Grid data-testid='finnoscoreRow' sx={rowStyles.row}>
      <Text sx={rowStyles.dimensionName}>{dimension}</Text>
      <Text sx={{ ...rowStyles.score, ...rowStyles.scoreA }}>{scoreA}</Text>
      <Text sx={{ ...rowStyles.score, ...rowStyles.scoreB }}>{scoreB}</Text>
      <DoubleChartItem
        sx={rowStyles.chartItem}
        percentages={{
          a: percentageB,
          b: percentageA,
        }}
      />
    </Grid>
  );
};

interface ChartsProps {
  comparison: Comparison;
}

export default ({ comparison }: ChartsProps) => {
  const getDimensions = () => {
    const aDimensions = comparison.a.dimensions;
    const bDimensions = comparison.b.dimensions;

    return aDimensions
      .map((dimensionA) => {
        const matchingDimensionB = bDimensions.find((dimensionB) => dimensionB.key === dimensionA.key);

        return {
          name: dimensionA.key || 'n/a',
          scoreA: getScore(dimensionA.value),
          scoreB: getScore(matchingDimensionB?.value || 'n/a'),
        };
      })
      .filter((dimension) => dimension.name !== ('n/a' as DimensionKey) && dimension.scoreB !== 'n/a');
  };

  return (
    <Box>
      <Header />
      <Box sx={styles.dataBox}>
        {getDimensions().map((dimension) => (
          <Row key={dimension.name} dimension={dimension.name as keyof Dimensions} scoreA={dimension.scoreA} scoreB={dimension.scoreB} />
        ))}
      </Box>
    </Box>
  );
};
