import React from 'react';
import { Box, Text } from 'theme-ui';
import { Link, Tag } from '@core';
import { ContentTypes, getContentTypeUrl, useLocale, useTranslate } from '@modules';
import { Tag as TagType } from '@types';
import { styles } from './PostFooter.styles';

interface TagListProps {
  tags: TagType[];
}

export const TagList = ({ tags }: TagListProps) => {
  const locale = useLocale();
  const __ = useTranslate();

  return (
    <React.Fragment>
      <Text sx={styles.title}>{__('pages.blogDetails.tags')}</Text>
      <Box>
        {tags.map((tag, index: number) => (
          <Link key={`tag_${index}`} to={getContentTypeUrl(tag.slug, locale, ContentTypes.tag)}>
            <Tag title={tag.name} />
          </Link>
        ))}
      </Box>
    </React.Fragment>
  );
};
