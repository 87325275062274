export const postContentStyles = {
  format: {
    variant: 'styles.blogTextMaxWidth',
    h2: {
      variant: 'styles.h2',
      mt: [5, 5, 8],
      mb: 4,
    },
    figure: {
      mx: [-4, -4, 0],
    },
    img: {
      width: '100%',
    },
    p: {
      mb: [4, 5],
    },
    '#table-container': {
      overflowX: ['auto', 'inherit'],
      my: 4,
    },
    table: {
      borderCollapse: 'collapse',
      thead: {
        tr: {
          th: {
            border: '1px solid #00A84E',
            fontSize: ['xxxs', 'xs'],
            padding: 3,
          },
        },
      },
      tbody: {
        tr: {
          td: {
            border: '1px solid #00A84E',
            padding: 3,
            textAlign: 'center',
            'p, a': {
              fontSize: ['xxxs', 'xxs'],
            },
          },
        },
      },
      p: {
        mb: 0,
      },
    },
    'p + ul': {
      mt: [-4, -5],
      pt: '6px',
    },
  },
  imageContainer: {
    variant: 'styles.blogTextMaxWidth',
  },
  image: {
    variant: 'flex.colCenter',
  },
  images: {},
  video: {
    my: 5,
  },
};
export const videoStyles = {
  variant: 'styles.blogTextMaxWidth',
  my: 5,
};
