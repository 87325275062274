/** @jsx jsx */
import React from 'react';
import { Link } from 'gatsby';
import { jsx, Title, Box, Flex, BoxProps, Paragraph } from '@themed';
import { CTAWithOptionalLink, List, CompactSectionContentStyles, GenericListItemProps, SvgIcon } from '@core';
import { useAllIntlTree, useTranslate } from '@modules';
import { TriggerElementProps } from '@types';
import { useAnimationLists } from './animateOursupport';
import { styles } from './OurSupport.styles';

interface ListItemProps2 extends BoxProps {}

const SupportIconItem = ({ text, icon, title, link }: GenericListItemProps) => (
  <Box className='listItem'>
    {icon && <SvgIcon as='figure' name={icon.url} alt={icon.alternativeText} sx={styles.svgIcon} />}
    <Flex variant='flex.colLeft' className='listItemText'>
      <Link aria-label={title} to={link || '/'}>
        <Title variant='styles.p' className='listItemTitle'>
          {title}
        </Title>
      </Link>
      <Paragraph variant='styles.p'>{text}</Paragraph>
    </Flex>
  </Box>
);

const SupportIconList = React.forwardRef<HTMLDivElement, ListItemProps2>((props, ref) => <Flex {...props} ref={ref} sx={styles.listContainer} />);

export const Oursupport = ({ triggerRef }: TriggerElementProps) => {
  const titleRef = React.useRef(null);
  const listRef = React.useRef(null);
  const messages = useAllIntlTree();
  const __ = useTranslate();

  useAnimationLists({
    triggerRef,
    title: () => titleRef,
    list: () => listRef,
  });

  const button = (
    <CTAWithOptionalLink to={__('pages.landing.oursupport.buttonLink', true)} sx={styles.button} buttonVariant='ctaInvert'>
      {__('pages.landing.oursupport.button')}
    </CTAWithOptionalLink>
  );

  return (
    <CompactSectionContentStyles sx={styles.container}>
      <Box sx={styles.left}>
        <Title data-cy='oursupport-title' ref={titleRef} sx={styles.title}>
          {__('pages.landing.oursupport.title')}
        </Title>
        {__('pages.landing.oursupport.subtitle', true) && (
          <Title variant='h1' data-cy='oursupport-subtitle' sx={styles.subtitle}>
            {__('pages.landing.oursupport.subtitle')}
          </Title>
        )}
        {__('pages.landing.oursupport.paragraph', true) && (
          <Paragraph data-cy='oursupport-paragraph' sx={styles.paragraph}>
            {__('pages.landing.oursupport.paragraph')}
          </Paragraph>
        )}
      </Box>
      <Box sx={styles.right} data-cy='oursupport-list'>
        {/* TODO: make sure it fits into 100vh! */}
        <List ref={listRef} itemComponent={SupportIconItem} component={SupportIconList} items={messages.pages.landing.oursupport.list} />
      </Box>
      <Box sx={styles.mobileOnly}>{button}</Box>
    </CompactSectionContentStyles>
  );
};
