/** @jsx jsx */
import React from 'react';
import { ImArrowUpRight2, ImArrowDownRight2, ImArrowRight2 } from 'react-icons/im';
import { jsx, Flex, Text, BoxProps, FlexProps } from '@themed';
import { Trend } from '@types';
import { trendStyles } from './Trend.styles';

interface TrendProps extends FlexProps {
  trend?: Trend;
}

const TrendWrapper = React.forwardRef<HTMLDivElement, FlexProps>(({ children, sx, ...props }, ref) => (
  <Flex variant='flex.colCenter' {...props} ref={ref} sx={{ ...trendStyles.box, ...sx }}>
    {children}
  </Flex>
));

export const TrendComponent = React.forwardRef<HTMLDivElement, TrendProps>(({ trend, ...props }, ref) => {
  if (!trend) return null;

  const content = (() => {
    switch (trend) {
      case Trend.Ascending:
        return <ImArrowUpRight2 style={trendStyles.icon as React.CSSProperties} />;
      case Trend.Descending:
        return (
          <ImArrowDownRight2
            style={{
              ...(trendStyles.icon as React.CSSProperties),
              ...(trendStyles.decliningIcon as React.CSSProperties),
            }}
          />
        );
      case Trend.New:
        return null;
      // Disable NewIcon until further confirmation
      // return <NewIcon />;
      default:
        return (
          <ImArrowRight2
            style={{
              ...(trendStyles.icon as React.CSSProperties),
              ...(trendStyles.stagnantIcon as React.CSSProperties),
            }}
          />
        );
    }
  })();
  return content ? (
    <TrendWrapper {...props} ref={ref}>
      {content}
    </TrendWrapper>
  ) : null;
});

const trendWithScoreStyles = {
  finnoscore: {
    variant: 'styles.fsText',
    color: 'primary',
    fontWeight: 'bold',
    textAlign: 'right',
    width: 'fullSize',
  },
};

interface ScoreComponentProps extends BoxProps {
  score: number;
}

export const ScoreComponent = React.forwardRef<HTMLDivElement, ScoreComponentProps>(({ score, sx, ...props }, ref) => (
  <Text {...props} ref={ref} sx={{ ...trendWithScoreStyles.finnoscore, ...sx }}>
    {score.toFixed(2)}
  </Text>
));
