import React from 'react';
import { Button, ButtonProps, Image as ThemedImage } from 'theme-ui';
import { ImageProps } from '@types';
import { MarkDownContent } from '../editable/EditableMarkDownContent';

import { prepMobileMore } from './preMarkDown';

export { Button } from 'theme-ui';

export const CTAButton = React.forwardRef<HTMLButtonElement, ButtonProps>(({ children, ...props }, ref) => (
  <Button data-cta='button' variant='cta' {...props} ref={ref}>
    <MarkDownContent prep={prepMobileMore}>{children}</MarkDownContent>
  </Button>
));

export const InfoButton = React.forwardRef<HTMLButtonElement, ButtonProps>(({ children, ...props }, ref) => (
  <Button data-cta='info' variant='info' {...props} ref={ref}>
    <MarkDownContent prep={prepMobileMore}>{children}</MarkDownContent>
  </Button>
));

interface ImageButtonProps extends ButtonProps, ImageProps {
  onLoad?: () => void;
}

export const ImageButton = React.forwardRef<HTMLButtonElement, ImageButtonProps>(({ children, src, ...props }, ref) => (
  <Button data-cta='image' variant='pure' {...props} ref={ref}>
    <ThemedImage src={src} />
    {children}
  </Button>
));
