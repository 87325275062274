import { CountryCalculationFromApi, CountryCalculation, Translation } from '@types';
import { translateDimensionsForLocale } from '../commonFormatting';
import { languages } from '../../i18n';
import { getTranslatedCountries } from './formatCountries';

export const formatCountryCalculations = (countryCalculations: CountryCalculationFromApi[], translations: Translation[]): CountryCalculation[] =>
  languages.reduce((accum, language) => {
    const translatedCountryCalculations = countryCalculations.map((curr) => {
      const translatedCountries = getTranslatedCountries(curr.country, translations);
      const country = translatedCountries.find((c) => c.language === language) || translatedCountries[0];
      const dimensions = translateDimensionsForLocale(curr.dimensions, translations, language);

      return {
        ...curr,
        dimensions,
        country,
        language: country.language,
      } as CountryCalculation;
    });

    return [...accum, ...translatedCountryCalculations];
  }, [] as CountryCalculation[]);
