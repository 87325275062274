import { useLocale } from '@modules';
import { FS_TYPES, FsType, Locale } from '@types';
import { useRecoilState } from 'recoil';
import { authState } from '../store/AuthState';

export const linkProps = {
  // Based on GSAP with four built in animation
  // https://transitionlink.tylerbarnes.ca/docs/anilink/
  // paintDrip: true,
  // duration: 0.5,
  // hex: theme.colors.background,
  activeClassName: 'active',
};

export const servicesRegex = /\/((rapid)|(workshops)|(services?)|(nearshoring))/i;
export const caseStudyRegex = /\/((case-stud(y|ies))|(services?))/i;
export const blogRegex = /(\/(blog|author|tag)(\/|$))|(^https:\/\/blog\.finnoconsult\.at\/?$)/;

export interface NavigationItem {
  id: string;
  link: string;
  partiallyActive?: boolean;
  forMobile?: boolean;
  includePath?: RegExp;
  excludePath?: RegExp;
  match?: RegExp;
  items?: NavigationItem[];
  withClientRoute?: boolean;
}

const getServiceSubmenus = (prefix: string): NavigationItem[] => [
  {
    id: 'bank-projects',
    link: `/${prefix}/rapid`,
    match: /\/(rapid)/i,
    partiallyActive: true,
  },
  {
    id: 'finnoscore-workshop',
    link: `/${prefix}/workshops`,
    match: /\/(workshops)/i,
    // partiallyActive: true,
  },
  {
    id: 'nearshoring-development',
    link: `/${prefix}/nearshoring`,
    match: /\/(nearshoring)/i,
    // partiallyActive: true,
  },
];

const isGerman = (locale: Locale) => locale === 'de';
const isEnglish = (locale: Locale) => locale === 'en';

const getBaseFinnoscoreBarItems = (prefix: string, locale: Locale) => [
  {
    id: 'finnoscore-banks-international',
    link: `/${prefix}/finnoscore`,
    match: /\/banks(?!-middleeast)/i,
  },
  ...(isEnglish(locale)
    ? [
        {
          id: 'finnoscore-bankMiddleEast',
          link: `/${prefix}/finnoscore/banks-middleeast`,
          match: /\/(finnoscore\/banks-middleeast)/i,
        },
      ]
    : []),
  {
    id: 'finnoscore-insurances',
    link: `/${prefix}/finnoscore/insurance`,
    match: /\/(finnoscore\/insurances)/i,
  },
  {
    id: 'finnoscore-privatebanks',
    link: `/${prefix}/finnoscore/privatebanks`,
    match: /\/(finnoscore\/privatebanks)/i,
  },
];

const getFinnoscoreSubmenus = (prefix: string, locale: Locale, pageIdsFromCMS: string[] = []): NavigationItem[] =>
  isGerman(locale)
    ? [
        ...getBaseFinnoscoreBarItems(prefix, locale),
        ...(pageIdsFromCMS.includes('digital-experience')
          ? [
              {
                id: 'finnoscore-digital-experience',
                link: `/${prefix}/finnoscore/digital-insurance-experience-studie`,
                match: /\/(finnoscore\/digital-insurance)/i,
              },
            ]
          : []),
        ...(pageIdsFromCMS.includes('quiz')
          ? [
              {
                id: 'finnoscore-quiz',
                link: `/${prefix}/finnoscore/quiz`,
                match: /\/(quiz)/i,
                // partiallyActive: true,
              },
            ]
          : []),
      ]
    : getBaseFinnoscoreBarItems(prefix, locale);

type FinnoscorePathType = 'bank|bank-middleeast|insurance|privatebank|telco' | 'bank' | 'banks-middleeast' | 'insurance' | 'privatebank' | 'telco';

const langRegexPrefix = '^(/(en|de|fr))?/';
const isFinnoscorePath = (
  pathname: string,
  type: FinnoscorePathType = 'bank|bank-middleeast|insurance|privatebank|telco',
  mainPage = '((finnoscore/?\\??)|)'
) => !!pathname.match(new RegExp(`${langRegexPrefix}${mainPage}(${type})`, 'i'));

export const isFinnoscoreInsurancePath = (pathname: string) => isFinnoscorePath(pathname, 'insurance');
export const isFinnoscorePrivateBankPath = (pathname: string) => isFinnoscorePath(pathname, 'privatebank');
export const isFinnoscoreBankMiddleEastPath = (pathname: string) => isFinnoscorePath(pathname, 'banks-middleeast');
export const isFinnoscoreBankPath = (pathname: string) => isFinnoscorePath(pathname, 'bank');

export const isFinnoscoreToolPath = (pathname: string) => isFinnoscorePath(pathname, 'bank|bank-middleeast|insurance|privatebank|telco', '');
export const isFinnoChartsPath = (pathname: string) => !!pathname.match('/(charts)');

export const isEmbeddedTelcoPath = (pathname: string) => isFinnoscorePath(pathname, 'telco', '((embed/?\\??)|)');

export const getTypeFromPath = (fullPath: string): FsType => {
  if (isFinnoscorePrivateBankPath(fullPath)) {
    return FS_TYPES[3];
  }
  if (isFinnoscoreInsurancePath(fullPath)) {
    return FS_TYPES[2];
  }
  if (isFinnoscoreBankMiddleEastPath(fullPath)) {
    return FS_TYPES[1];
  }
  if (isEmbeddedTelcoPath(fullPath)) {
    return FS_TYPES[4];
  }

  return FS_TYPES[0];
};

export const getAllNavBarItems = (prefix: string, pathname: string, pageIdsFromCMS: string[] = []): NavigationItem[] => {
  const [user] = useRecoilState(authState);
  const locale = useLocale();

  if (isFinnoChartsPath(pathname)) {
    if (user.token && user.user) {
      return [
        {
          id: 'historyChart',
          link: `/${prefix}/charts/`,
          match: /\/charts\/?$/,
        },
        {
          id: 'historyRanking',
          link: `/${prefix}/charts/history-ranking-chart`,
          match: /\/history-ranking-chart/i,
        },
        {
          id: 'comparisonChart',
          link: `/${prefix}/charts/comparison-chart`,
          match: /\/comparison/i,
        },
        {
          id: 'internationalChart',
          link: `/${prefix}/charts/international-chart`,
          match: /\/international/i,
        },
        {
          id: 'specificChart',
          link: `/${prefix}/charts/specific-chart`,
          match: /\/specific/i,
        },
        {
          id: 'finnoscoreUploader',
          link: `/${prefix}/charts/finnoscore-uploader`,
          match: /\/finnoscore-uploader/i,
        },
        {
          id: 'chartGuide',
          link: `/${prefix}/charts/guide`,
          match: /\/guide/i,
        },
      ];
    }
    return [
      {
        id: 'login',
        link: `/${prefix}/charts/login`,
        match: /\/charts\/login?$/,
      },
      {
        id: 'chartGuide',
        link: `/${prefix}/charts/guide`,
        match: /\/guide/i,
      },
    ];
  }
  if (isFinnoscoreToolPath(pathname)) {
    return [
      {
        id: 'finnoscore-banks',
        link: `/${prefix}/banks`,
        forMobile: false,
        match: /\/(banks.*)/i,
        partiallyActive: true,
        items: [
          {
            id: 'finnoscore-banks-international',
            link: `/${prefix}/banks`,
            match: /\/banks(?!-middleeast)/i,
          },
          ...(isEnglish(locale)
            ? [
                {
                  id: 'finnoscore-bankMiddleEast',
                  link: `/${prefix}/banks-middleeast`,
                  match: /\/(banks-middleeast)/i,
                },
              ]
            : []),
        ],
      },
      // {
      //   id: 'finnoscore-bankMiddleEast',
      //   link: `/${prefix}/banks-middleeast`,
      //   match: /\/(banks-middleeast)/i,
      // },
      {
        id: 'finnoscore-privatebanks',
        link: `/${prefix}/privatebanks`,
        match: /\/(privatebanks)/i,
      },
      {
        id: 'finnoscore-insurances',
        link: `/${prefix}/insurances`,
        match: /\/(insurance)/i,
      },
      {
        id: 'finnoscore-about',
        link: `/${prefix}/about-us`,
      },
      {
        id: 'contact',
        link: `/${prefix}/contact`,
      },
    ];
  }

  return [
    {
      id: 'home',
      link: `/${prefix}/`,
      forMobile: true,
    },
    {
      id: 'finnoscore',
      link: `/${prefix}/finnoscore`,
      forMobile: false,
      match: /^(\/(en)|(de)|(fr)|)\/(finnoscore|banks)/i,
      partiallyActive: true,
      items: getFinnoscoreSubmenus(prefix, locale, pageIdsFromCMS),
    },
    ...getFinnoscoreSubmenus(prefix, locale, pageIdsFromCMS).map((item) => ({ ...item, forMobile: true }) as NavigationItem),
    {
      id: 'projects',
      link: `/${prefix}/case-studies`,
      match: caseStudyRegex,
    },
    {
      id: 'casestudies',
      link: `/${prefix}/rapid`,
      forMobile: false,
      match: servicesRegex,
      partiallyActive: true,
      items: getServiceSubmenus(prefix),
    },
    ...getServiceSubmenus(prefix).map((item) => ({ ...item, forMobile: true }) as NavigationItem),
    {
      id: 'aboutus',
      link: `/${prefix}/about-us`,
    },
    {
      id: 'blog',
      link: `/${prefix}/blog`,
      match: blogRegex,
      partiallyActive: true,
    },
    {
      id: 'contact',
      link: `/${prefix}/contact`,
    },
  ];
};

export const platformFilterItem = (condition: boolean, value?: boolean) =>
  (condition && value) || (!condition && value === false) || value === undefined;
