import { NavigationItem } from '@config';
import { DesktopNavbar, useIsDesktop } from '@core';
import { Box } from '@themed';
import { ChildrenProps } from '@types';
import React from 'react';
import { SxProps } from 'theme-ui';
import { getNestedNavItemsStyles } from './NestedNavItems.styles';

interface NestedNavItemsProps extends ChildrenProps, SxProps {
  items?: NavigationItem[];
  isOpen: boolean;
  childrenGroupProps: { [key: string]: any };
  childrenProps: { [key: string]: any };
}

export const NestedNavItems = ({ items, children, childrenGroupProps, childrenProps, isOpen }: NestedNavItemsProps) => {
  const isDesktop = useIsDesktop();

  if (!isDesktop) return null;

  const styles = getNestedNavItemsStyles(isOpen);

  return items ? (
    <Box {...childrenGroupProps} sx={{ ...childrenGroupProps.sx, ...styles }}>
      {children}
      <DesktopNavbar items={items.map((item) => ({ ...item, ...childrenProps }))} />
    </Box>
  ) : null;
};

interface NestedNavItemsHeaderProps extends ChildrenProps {
  isOpen: boolean;
}

export const NestedNavItemsHeader = ({ isOpen, children }: NestedNavItemsHeaderProps) => {
  const parentStyles = {
    variant: 'links.nav',
    cursor: 'default',
    ...(isOpen
      ? {
          borderBottomColor: 'grey.3',
          '&:hover': { borderBottomColor: 'grey.3' },
        }
      : {}),
  };
  return (
    <Box as='span' sx={parentStyles}>
      {children}
    </Box>
  );
};
