import React from 'react';
import { Box, Checkbox, Grid, Input, Label, Radio, SxStyleProp } from 'theme-ui';
import { useIntl, useLocale, useTranslate } from '@modules';
import { ChildrenProps } from '@types';
import { CTAButton, Paragraph, Link } from '../core/ui';
import { BasicProtectionAgainsBots } from './NewsLetterSignup';
import { styles } from './NewsLetterSignup.styles';
import { DIGITAL_STUDY_SOURCE } from './digital-experience/usePDFForm';

const answerFieldStyles = {
  display: 'flex',
  flexDirection: 'column',
} as SxStyleProp;

const paragraphStyles = {
  color: 'background',
};
interface AnswerFormFieldProps {
  label?: string | false;
  setter?: (s: string) => void;
  answer?: string;
  defaultValue?: string;
}
const AnswerFormField = ({ label, answer, defaultValue = 'DEFAULT', setter }: AnswerFormFieldProps) => (
  <Label sx={answerFieldStyles}>
    <Paragraph sx={paragraphStyles}>{label}</Paragraph>
    <Input
      sx={styles.emailInput}
      type='text'
      name='ANSWER'
      value={answer}
      defaultValue={defaultValue}
      required
      onChange={(e) => setter?.(e.target.value)}
    />
  </Label>
);

const NameFormField = ({ defaultValue = 'DEFAULT' }: { defaultValue?: string }) => {
  const __ = useTranslate();
  return (
    <>
      <Label sx={styles.labelInput}>
        {__('common.firstName')}
        <Input sx={styles.emailInput} type='text' name='LASTNAME' defaultValue={defaultValue} required />
      </Label>
      <Label sx={styles.labelInput}>
        {__('common.lastName')}
        <Input sx={styles.emailInput} type='text' name='FIRSTNAME' defaultValue={defaultValue} required />
      </Label>
    </>
  );
};

const LanguageFormField = () => {
  const __ = useTranslate();
  const intl = useIntl();
  const language = intl.locale;

  return (
    <Box className='select-languages'>
      <Label>
        <Radio variant='forms.radioInverted' name='LANGUAGE' value='Deutsch' defaultChecked={language === 'de'} />
        {__('languages.de')}
      </Label>
      <Label>
        <Radio variant='forms.radioInverted' name='LANGUAGE' value='English' defaultChecked={language === 'en'} />
        {__('languages.en')}
      </Label>
    </Box>
  );
};

const DataPrivacyFormField = ({ id }: { id: string }) => {
  const __ = useTranslate();
  const locale = useLocale();
  const pathname = __(`${id}.checkbox.linkHref`);
  const linkText = __(`${id}.checkbox.linkText`);
  const checkboxText = __(`${id}.checkbox.text`);

  return (
    <Label>
      <Grid sx={styles.checkboxWithLabel}>
        <Checkbox variant='forms.radioInverted' name='DATA_PRIVACY' value='allowPersonalInfo' id='data-privacy-checkbox' required />

        <Paragraph className='checkbox-paragraph'>
          {checkboxText}
          <Link to={`/${locale}${pathname}`}> {linkText}</Link>
        </Paragraph>
      </Grid>
    </Label>
  );
};

const EmailFormField = () => {
  const __ = useTranslate();

  return (
    <Label sx={styles.labelInput}>
      {__('common.email')}
      <Input sx={styles.emailInput} type='email' name='EMAIL' required />
    </Label>
  );
};

export const DefaultNewsLetterContent = ({ id, onClick }: NewsLetterContentProps) => {
  const __ = useTranslate();

  return (
    <React.Fragment>
      <EmailFormField />
      <LanguageFormField />

      <Input type='hidden' name='LASTNAME' />
      <Input type='hidden' name='FIRSTNAME' />
      <Input type='hidden' name='LANGUAGE' value='Deutsch' />
      <Input type='hidden' name='SOURCE' value='blog' />

      <BasicProtectionAgainsBots />

      <CTAButton variant='buttons.ctaInvert' sx={styles.submit} type='submit' name='subscribe' id='mc-embedded-subscribe' onClick={() => onClick?.()}>
        {__(`${id}.button`)}
      </CTAButton>
    </React.Fragment>
  );
};

interface SharedLetterContentProps extends ChildrenProps {
  id: string;
  onClick?: () => void;
}

const SharedNewsLetterContent = ({ id, children, onClick }: SharedLetterContentProps) => {
  const __ = useTranslate();
  return (
    <React.Fragment>
      <NameFormField defaultValue='' />

      <EmailFormField />

      <DataPrivacyFormField id={id} />

      {children}

      <BasicProtectionAgainsBots />

      <CTAButton variant='buttons.ctaInvert' sx={styles.submit} type='submit' name='subscribe' id='mc-embedded-subscribe' onClick={() => onClick?.()}>
        {__(`${id}.button`)}
      </CTAButton>
    </React.Fragment>
  );
};

interface QuizLetterContentProps extends NewsLetterContentProps {
  question?: string | false;
}

export const QuizLetterContent = ({ id, onClick, question }: QuizLetterContentProps) => {
  const [answer, setAnswer] = React.useState('');
  const [submitted, setSubmitted] = React.useState(false);

  const handleAnswerSubmit = () => answer && setSubmitted(true);

  if (!submitted) {
    return (
      <React.Fragment>
        <AnswerFormField setter={setAnswer} label={question} defaultValue='' />
        <CTAButton onClick={handleAnswerSubmit}>Submit</CTAButton>
      </React.Fragment>
    );
  }

  return (
    <SharedNewsLetterContent id={id} onClick={onClick}>
      <Input type='hidden' name='ANSWER' value={answer} />
      <Input type='hidden' name='LANGUAGE' value='Deutsch' />
      <Input type='hidden' name='SOURCE' value='quiz2023' />
    </SharedNewsLetterContent>
  );
};

interface NewsLetterContentProps extends SharedLetterContentProps {}

export const StudyNewsLetterContent = ({ id, onClick }: NewsLetterContentProps) => (
  <SharedNewsLetterContent id={id} onClick={onClick}>
    <Input type='hidden' name='ANSWER' />
    <Input type='hidden' name='LANGUAGE' value='Deutsch' />
    <Input type='hidden' name='SOURCE' value={DIGITAL_STUDY_SOURCE} />
  </SharedNewsLetterContent>
);
