import { Children } from '@types';
import React from 'react';
import { BoxProps } from 'theme-ui';
import PercentageContext from '../../context/PercentageContext';
import { Bar } from './Bar';
import { Monochrome } from './Monochrome';
import { PositionBar } from './PositionBar';

interface DoubleChartItemProps extends BoxProps {
  percentages: {
    a: number;
    b: number;
  };
  fillComponents?: {
    a: Children;
    b: Children;
  };
}

export const DoubleChartItem = React.forwardRef<HTMLDivElement, DoubleChartItemProps>(
  (
    {
      percentages,
      fillComponents = {
        a: <Monochrome />,
        b: <PositionBar />,
      },
      ...props
    },
    ref
  ) => (
    <Bar ref={ref as unknown as any} {...props}>
      <PercentageContext.Provider value={percentages.a}>{fillComponents.a}</PercentageContext.Provider>
      <PercentageContext.Provider value={percentages.b}>{fillComponents.b}</PercentageContext.Provider>
    </Bar>
  )
);
