import React from 'react';
import { Box, BoxProps } from 'theme-ui';
import { getElement } from '@modules';
import { ImageLoader } from './ImageLoader';
import { useAnimationParallax } from './useAnimationParallax';
import { styles } from './ParallaxImage.styles';

interface Props extends BoxProps {
  resize?: boolean;
  src: string;
  alt?: string;
  triggerRefDeprecated?: () => React.RefObject<HTMLDivElement>;
}

export const ParallaxImage = ({ src, alt, triggerRefDeprecated, sx, resize, ...props }: Props) => {
  const containerRef = React.useRef(null);
  const imageRef = React.useRef(null);
  const [imageHeight, setImageHeight] = React.useState(0);

  useAnimationParallax({
    triggerRef: triggerRefDeprecated || (() => containerRef),
    image: () => imageRef,
    init: !!imageHeight && imageHeight > 100,
  });

  return (
    <Box
      sx={{
        ...styles.box,
        ...sx,
      }}
      ref={containerRef}
      {...props}
      data-animation='parallax-image'
    >
      <Box ref={imageRef} sx={styles.imagePlace}>
        <ImageLoader
          objectFit='cover'
          sx={styles.image}
          src={src}
          alt={alt}
          onLoad={() => setImageHeight(getElement(() => imageRef)?.getBoundingClientRect()?.height || 0)}
          className={resize ? 'parallax parallax-resize' : ''}
        />
      </Box>
    </Box>
  );
};
