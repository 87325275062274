/** @jsx jsx */
import { SegmentedControl } from '@core';
import { ALL_COUNTRIES, Option, SelectedCountryContext, useInstitutionOptionList, usePathnameSearchOptionList, useQueryParam } from '@modules';
import { useLocation } from '@reach/router';
import { BoxProps, jsx } from '@themed';
import { Institution } from '@types';
import { navigate } from 'gatsby';
import { useContext } from 'react';

export function useFsSelectorOptions(institutions?: Institution[]) {
  const { country } = useContext(SelectedCountryContext);
  const topInstitution =
    country.countryCode === ALL_COUNTRIES ? institutions?.[0] : institutions?.find((i) => i.country.countryCode === country.countryCode);

  return useInstitutionOptionList(topInstitution);
}

interface FsTabSelectorProps extends BoxProps {
  options: Option[];
}

export function FsTabSelector({ options, ...props }: FsTabSelectorProps) {
  return <SegmentedControl {...props} items={options} onChange={({ value }) => navigate(`${value}`)} />;
}

export const FSCompareTabSelector = ({ ...props }: FsTabSelectorProps) => {
  const [countryCode] = useQueryParam('countryCode');
  const { pathname, search } = useLocation();
  const chartTypes = usePathnameSearchOptionList(pathname, `${search}${search ? '&' : '?'}countryCode=${countryCode}`, `${pathname}${search}`);

  return <SegmentedControl {...props} defaultIndex={1} items={chartTypes} onChange={({ value }) => navigate(`${value}`)} />;
};
