export const markDownStyles = {
  a: {
    variant: 'styles.a',
  },
  br: {
    display: ['none', 'block'], // hide linebreaks for mobile
  },
  code: {
    variant: 'styles.highlighted',
    fontFamily: 'inherit',
    fontSize: 'inherit',
  },
  hr: {
    border: 'none',
    bg: 'transparent',
    color: 'transparent',
    height: 0,
    mt: 5,
    '&.low': {
      mt: 2,
    },
  },
  output: {
    bg: 'grey.4',
    borderRadius: 5,
    // border: 'none',
    fontStyle: 'normal',
    px: 5,
    py: 3,
    overflow: 'hidden',
    display: 'inline-block',
  },
  mark: {
    color: 'primary',
    background: 'none',
  },
  // blockquote: {
  // },
  // cite: {
  // },
  sup: {
    // interim fix with simply recoloring
    // color: 'primary',
    fontWeight: 'bold',
    fontSize: 'inherit',
    verticalAlign: 'unset',

    // replace letters with NN icon
    color: 'transparent',
    textIndent: '-100%',
    overflow: 'hidden',
    textDecoration: 'none',
    // background: 'url("./static/nn.svg")', // dunno how to refer to images in the FS
    background:
      'url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAAeCAYAAABwmH1PAAAABGdBTUEAALGPC/xhBQAAADhlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAAqACAAQAAAABAAAAPKADAAQAAAABAAAAHgAAAAATFrcCAAADN0lEQVRYCb2YS0gVURjH/9+MegkrXYQQZQRRQRFBSFu7QosWbSIizVoE2ksrWhhJ4CNLWoRRInRpkaQFFthT2viobFNtXEQvImhR7Xqg5dVxTt9VJC/Od7wzc+7MRu/5fefO78935sydIfSXK3gdRAmU3TrkheaN9Vf0AWrHvPHUANE4bHsDSm9+9uSzgxF5WLPnC/x3oHKPGHb6S1XrgmEDn3zOxAw9wgV+XV0AuJfnnDb9X8InFC+7iP7K8nRg+JMPj3CBf49dgFLLRX3LqsXX0SKQ2yHWmAA+PIIHflaxlcMe1vj2orTrCZxkJ9cVaurCIZ8ewQIPNubAUQm+doX59Aex3JMYqjzFNfFwiTSzA3gIwpqTTKOPJ6CwWawiasHUVAFc97xYYwT498jxfd7hA6uQdJo08z4gL9aO5PgLrolp6sKhgB7+Ayeddl6m+aKtbdcg+beB+SaxxgQI6OFvSQ9U7OKwO2VfusO3KQcEvnazeITwyLzDwweXcOeuiDEIoyC7mfP28fVNYl1YENIj8w5PjLew6wqNbzOUU89hizU14VFIj8w6PLi/BO5UjWhLGAGtb4P7flKsMQEMeCzcYdVjc9hrmnuuC8uqQrzRMZFJ/A5DHgsHHrpfy2G3iCLAVWzrfqXhZpAhD33gwX0rodxzsjF9gVV4VuaGiEEPTWDrAf9SauNNaLGobdExxDtGRW4EmPWQAvfyD/7UBrRbdCb0IN79SORmgHEPj12axngTquPu8lsM4SD6Cco7IVBDw9nxmN9hUk0cdi9vVGtFc0V1iN/4LnITIEse6YEJb2DTPZCq1zg/R1nXdQ0Pj7Lokb6kFR2Fg0tsvEiwTsJCNb+Y837xJ0zyPZxFj/8dJnTCVvyOSvNwYFEr4rff+Q7gZ0KWPWY6TPSDbz8NcNWQxu0tita1anh4FIHHTIdJneEXyFUcerWnNTFJLeWNjROe3NRgBB7cYXrJy/gpP8eOyN5Wgu+5wzI3QaLxsJBrHYGL1HNunqc20TcszT/tyUwORuRhYdJdw97bRXei4yhJ/BK5KRCRB1/Dqk12poe8lO/K3CSJxiOH76mPRW071iIy0yAij388woWUhe1rpAAAAABJRU5ErkJggg==")',
    backgroundPosition: 'center 64%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'auto 43%',
    '&.invert': {
      background:
        'url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACMAAAATCAYAAAAao7T0AAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAI6ADAAQAAAABAAAAEwAAAAAZnfshAAACj0lEQVRIDZWVXYhNURiG9zbIP4OikCMlkSK3lCsuXChulJQ7I+TCXyY/hzLiRun4S35TolwoF2pMjbmYNCWlSHFDEfJXGiQ6nneadXrPmjNn7/3Vd9b7ft/7rb3Wd9ZeO61WqyuSJGnFg3Wmafo7kDCiK4GXBD44PkT7K8TQKF8KnLGP/EfjAxCdnqfnuvUlJHpxtzmuECbZgj91EfgBPsW18EqkWef5gNHciHSv4bNHBEHGuJP8UtOoG1fwDRbLBXnoKoSbI/FR+PbMxVA8C+GxqLgDvg9vieJNKXONRnA+Et2Cr8FbMxeD6Aw+EQ/2HKDOLA+BAuN+tAtN/x18H9+k2Ej9DGfsZC259ZavgtWlSxbLBZlrPsL2SFyGHwmxYTtD8VhElSAcHC8z6pxMiuJ56DlEY0z4GKy3akGINevMYUSlIGT8hHfity2WC7KxjQhXm/gv+AReN1fDzlC8COFuKxYUnxfFMilzTUZ0OhKK69x5p5JGi0kRXcBH4cG6uLxuBlJw1Js302regMvGazBezFsyOrQra4ok0W28zXheqJ3rVW6LCnawsZ9RbIDGZ6ZM9FQk7KD4VRTLQ9WRA7hv+C5z6VVuaC68h0Lfi+mmfAk+aTwvfIHwD77MCn6AdxkfAkNn+sncweNz0cZONGkR0110HL8YFR1krndRrI6GzqhYF5IOb7CrFD8KpMB4Da3uoglW8wR81nhDqMU8w/WNWWyKz+C9xvPCLwh7cL+1/8G3sjGNTU1/k652TeC2h2JNXNT01/RGRRXmUmcyTZ1RB8aZspvi68aLwC2I51qBzsgh401hyg1ZQuGLec9i9DUdYminEZxhiToteV1uUy3/lbk+GK9BtOMhvvBv/wEmHeQwfGEbhgAAAABJRU5ErkJggg==")',
      backgroundPosition: 'center 64%',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'auto 43%',
    },
  },
  ul: {
    variant: 'lists.primaryListType',
  },
  ol: {
    variant: 'lists.orderedListType',
  },
};
