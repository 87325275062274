/** @jsx jsx */
import { ListItem, SectionWithContent } from '@core';
import { useComparison } from '@modules';
import { Flex, Grid, jsx } from '@themed';
import { ChildrenProps, CountryCalculation, Institution } from '@types';
import React from 'react';
import { DefaultErrorMessage } from '../finnoscore-data/DefaultErrorMessage';
import { PacmanLoader } from '../finnoscore-data/PacmanLoader';
import Charts from './Charts';
import { ComparisonDropDownContextProvider } from './ComparisonDropDownContext';
import { ComparisonDropDownControl } from './ComparisonDropDownControl';
import { styles } from './FinnoscoreComparisonSelector.styles';

export interface FinnoscoreComparisonSectionData {
  countries?: ListItem[];
  institutions?: Institution[];
  countryCalculations?: CountryCalculation[];
}

export interface FinnosoreComparisonSectionMeta {
  error?: Error | null;
  isLoading?: boolean;
}

export const FinnoscoreComparisonChart = ({ countries, institutions, countryCalculations }: FinnoscoreComparisonSectionData) => {
  const { comparison, setA, setB, error } = useComparison(institutions, countryCalculations);

  return (
    <React.Fragment>
      <ComparisonDropDownContextProvider>
        <ComparisonDropDownControl countries={countries} institutions={institutions} comparison={comparison} setA={setA} setB={setB} />
      </ComparisonDropDownContextProvider>

      <Flex id='content' data-cy='compare-content' variant='flex.colLeft' sx={styles.chart}>
        {comparison && !error && <Charts comparison={comparison} />}
        {error && <DefaultErrorMessage sx={styles.error} error={error} />}
      </Flex>
    </React.Fragment>
  );
};

const ContentSection = ({ children: sectionChildren }: ChildrenProps) => <Flex sx={styles.page}>{sectionChildren}</Flex>;

type FinnosoreComparisonSectionProps = FinnosoreComparisonSectionMeta &
  ChildrenProps & {
    withSection?: boolean;
  };

export const FinnoscoreComparisonSection = ({ error, isLoading, children, withSection = true }: FinnosoreComparisonSectionProps) => {
  const content = (
    <Grid sx={styles.content}>
      {isLoading && <PacmanLoader id='loader' />}
      {error && <DefaultErrorMessage id='loader' sx={styles.error} error={error} />}
      {children}
    </Grid>
  );

  if (!withSection) {
    return content;
  }

  return (
    <SectionWithContent sx={styles.section} contentComponent={ContentSection}>
      {content}
    </SectionWithContent>
  );
};
