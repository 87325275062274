/** @jsx jsx */
import { jsx, Button } from 'theme-ui';
import React from 'react';
import { gsap } from 'gsap';
import { RiPlayCircleLine } from 'react-icons/ri';
import { ScrollToPlugin } from 'gsap/all';
import { ThemedIcon, useSmoothScrollbar } from '@core';
import { themeConfig } from '@themed';
import { finniConfig } from '@config';
import { TriggerElementProps } from '@types';
import { useAnimationFinniRevealCaseTeasers } from './animateFinniRevealCaseTeasers';

const styles = {
  container: {
    boxShadow: 'none !important',
    minWidth: themeConfig.images.circleIcon.width, // TODO: this should be retrieved from themeconfig via themedProp
    minHeight: themeConfig.images.circleIcon.width,
    ...finniConfig.container,
    '&:hover': {
      transform: 'none !important', // Note: it would interfere with animation
    },
  },
  finni: {
    position: 'relative',
    variant: 'images.circleIcon',
    boxShadow: 'none !important',
    bg: 'primary',
  },
};

interface FinniRevealCaseTeasersProps extends TriggerElementProps {}

export const FinniRevealCaseTeasers = ({ triggerRef }: FinniRevealCaseTeasersProps) => {
  const finniRef = React.useRef(null);
  useAnimationFinniRevealCaseTeasers({ triggerRef, finni: () => finniRef });
  const scrollBar = useSmoothScrollbar();

  const scrollAPage = () => {
    if (window && scrollBar) {
      scrollBar.scrollTo(undefined, window.innerHeight, 1500);
    } else if (window) gsap.to(window, { duration: 0.5, ease: 'power4', scrollTo: 'main>section:nth-of-type(2)' });
  };

  React.useEffect(() => {
    gsap.registerPlugin(ScrollToPlugin);
  }, []);

  return (
    <Button onClick={() => scrollAPage()} sx={styles.container} data-print='no' aria-label='play'>
      <ThemedIcon sx={styles.finni} ref={finniRef}>
        <RiPlayCircleLine color='white' />
      </ThemedIcon>
    </Button>
  );
};
