import React from 'react';
import { Box, SxProps } from '@themed';
import { SectionWithContent, useShowBackButtonOnMount } from '@core';
import { ChildrenProps, Children, ArticleContent } from '@types';

import { PostHeader } from './PostHeader';
import { PostContentWithContentBar } from './PostContent';
import { postStyles } from './Post.styles';

interface PostProps extends ArticleContent, ChildrenProps, SxProps {
  footer?: Children;
}

export const Post = ({ title, subtitle, covers, author, content, publishDate, children, footer, ...props }: PostProps) => {
  useShowBackButtonOnMount();

  return (
    <React.Fragment>
      <SectionWithContent {...props}>
        <Box sx={postStyles.pageWrapper}>
          <PostHeader title={title} subtitle={subtitle} covers={covers} author={author} publishDate={publishDate} />

          <hr />

          <PostContentWithContentBar content={content} />

          <hr />

          {children}
        </Box>
      </SectionWithContent>
      {footer}
    </React.Fragment>
  );
};
