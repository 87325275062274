import { RGBColor } from 'react-color';
import { SxStyleProp } from 'theme-ui';

export const headerStyles = {
  box: {
    pl: '20px',
    pr: '10px',
    m: 0,
    height: '50px',
  } as SxStyleProp,
  scoreArea: {
    height: '100%',
    pr: '10px',
    borderRightColor: 'mutedUi',
    borderRightStyle: 'solid',
    borderRightWidth: '1px',
  } as SxStyleProp,
  score: (winner: boolean, color?: RGBColor): SxStyleProp => ({
    fontSize: '1.5rem !important',
    // eslint-disable-next-line no-nested-ternary
    color: color ? `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})` : winner ? 'primary' : 'text',
  }),
  titleArea: {
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    ml: '10px',
  } as SxStyleProp,
  image: {
    width: '30px',
    height: '30px',
    mr: '10px',
    '*': {
      m: '0 !important',
    },
  } as SxStyleProp,
  title: (wording?: string) =>
    ({
      fontSize: 'xxsImportant',
      fontWeight: '500',
      letterSpacing: `${wording}`.length > 30 ? '-0.5px' : 'inherit',
      pl: 4,
      m: 0,
      p: 0,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: '100%',
      textAlign: 'left',
    }) as SxStyleProp,
};
